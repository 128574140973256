const tabTypes = {
  HORIZONTAL: {
    LG: 'horizontal___lg',
    SM: 'horizontal___sm',
  },
  VERTICAL: {
    LG: 'vertical___lg',
    SM: 'vertical___sm',
  },
};

export default tabTypes;
