import React, { useState, useContext } from 'react';
import cn from 'classnames';
import styles from '../styles.module.scss';

import { Icon, Text } from '../../elements';
import { UserContext, TimesheetContext } from '../../../contexts';
import {
  getPrevOrAfterDate,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';

import { getHours } from '../../../utils/timesheets';

import TimesheetCarousel from '../TimesheetCarousel';

const DailyView = () => {
  const { user } = useContext(UserContext);
  const { startDate, timesheets } = useContext(TimesheetContext);
  const [isActive, setIsActive] = useState(true);

  let overallTotalHours = 0;

  return (
    <>
      <div className={styles.TimesheetGrid_keyWrapper}>
        {!isActive ? (
          <>
            <button
              type="button"
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_clickable
              )}
              onClick={() => setIsActive(true)}
            >
              <Icon
                icon="expand_more"
                className={styles.Navbar_navUser_dropdown_link_icon}
              />
            </button>

            {[...Array(1)].forEach((_, i) => {
              const timesheetDate = getPrevOrAfterDate(startDate, i, 'add');
              const timesheetsForDay = timesheets.filter(
                (timesheet) =>
                  timesheet.userId === user.id &&
                  timesheet.date === timesheetDate
              );

              let totalHours = 0;
              timesheetsForDay.forEach(
                ({
                  date,
                  startTimeDetails = {
                    startTime: undefined,
                    actualTime: undefined,
                  },
                  endTimeDetails = {
                    endTime: undefined,
                    actualTime: undefined,
                  },
                  isLunchBreak,
                  lunchBreakDuration,
                  leaveType,
                  publicHolidayMultiplier,
                }) => {
                  const startTimeTimestamp = convertDateTimeToTimestamp(
                    date,
                    convertTimeTo24HourFormat(startTimeDetails.startTime)
                  );
                  const endTimeTimestamp = convertDateTimeToTimestamp(
                    date,
                    convertTimeTo24HourFormat(endTimeDetails.endTime)
                  );

                  totalHours += Number(
                    getHours(
                      startTimeTimestamp,
                      endTimeTimestamp,
                      isLunchBreak,
                      lunchBreakDuration,
                      // leaveType,
                      // publicHolidayMultiplier
                    )
                  );
                }
              );

              overallTotalHours += totalHours;
            })}

            <div
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_boldText
              )}
            >
              {overallTotalHours > 0
                ? user?.hoursAndMinutesFormat
                  ? hoursWithDecimalToHoursAndMinutes(
                      overallTotalHours.toFixed(2)
                    )
                  : overallTotalHours.toFixed(2)
                : 0}
            </div>
          </>
        ) : (
          <>
            <button
              type="button"
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_clickable,
                styles.TimesheetGrid_clickable___active
              )}
              onClick={() => setIsActive(false)}
            >
              <Icon
                icon="expand_less"
                className={styles.Navbar_navUser_dropdown_link_icon}
              />
            </button>

            {[...Array(1)].map((_, i) => {
              const timesheetDate = getPrevOrAfterDate(startDate, i, 'add');
              const timesheetsForDay = timesheets.filter(
                (timesheet) =>
                  timesheet.userId === user.id &&
                  timesheet.date === timesheetDate
              );
              const totalTimesheetsForDay = timesheetsForDay.length;

              let totalHours = 0;
              timesheetsForDay.forEach(
                ({
                  date,
                  startTimeDetails = {
                    startTime: undefined,
                    actualTime: undefined,
                  },
                  endTimeDetails = {
                    endTime: undefined,
                    actualTime: undefined,
                  },
                  isLunchBreak,
                  lunchBreakDuration,
                  leaveType,
                  publicHolidayMultiplier,
                }) => {
                  const startTimeTimestamp = convertDateTimeToTimestamp(
                    date,
                    convertTimeTo24HourFormat(startTimeDetails.startTime)
                  );
                  const endTimeTimestamp = convertDateTimeToTimestamp(
                    date,
                    convertTimeTo24HourFormat(endTimeDetails.endTime)
                  );

                  totalHours += Number(
                    getHours(
                      startTimeTimestamp,
                      endTimeTimestamp,
                      isLunchBreak,
                      lunchBreakDuration,
                      // leaveType,
                      // publicHolidayMultiplier
                    )
                  );
                }
              );

              overallTotalHours += totalHours;

              return (
                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_column___active
                  )}
                  key={i}
                >
                  {!totalTimesheetsForDay ? (
                    <Text className={styles.TimesheetGrid_centerText}>--</Text>
                  ) : (
                    <TimesheetCarousel
                      totalHours={totalHours}
                      timesheetsForDay={timesheetsForDay}
                      totalTimesheets={totalTimesheetsForDay}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default DailyView;
