import { useState } from 'react';
import { useAlert } from 'react-alert';

import { ProjectsService } from '../services';

const useUpdateProject = () => {
  const alert = useAlert();
  const [isUpdating, setIsUpdating] = useState(false);

  const updateProject = async (newProject) => {
    setIsUpdating(true);

    const { data: updateProjectResponse } = await ProjectsService.update(
      newProject
    );

    setIsUpdating(false);

    let message = null;

    if (updateProjectResponse.message === 'project_updated') {
      alert.success('Project updated!');
      message = 'project_updated';
    } else {
      alert.error('Oops, something went wrong.');
      message = 'project_not_updated';
    }

    return { message };
  };

  return { isUpdating, updateProject };
};

export default useUpdateProject;
