import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { Card, Shine, Separator } from '../../elements';
import { timesheetTypes } from '../../../globals';

const Preloader = ({ type }) => (
  <Card className={cn(styles.Preloader, styles[`Preloader___${type}`])}>
    <div
      className={cn(
        styles.Preloader_header,
        styles[`Preloader_header___${type}`]
      )}
    >
      <Shine className={styles.Preloader_header_shine} />
      <Shine className={styles.Preloader_header_shine} />

      {type !== timesheetTypes.DAILY && (
        <>
          <Shine className={styles.Preloader_header_shine} />
          <Shine className={styles.Preloader_header_shine} />
          <Shine className={styles.Preloader_header_shine} />
          <Shine className={styles.Preloader_header_shine} />
          <Shine className={styles.Preloader_header_shine} />
          <Shine className={styles.Preloader_header_shine} />

          {(type === timesheetTypes.FORTNIGHTLY ||
            type === timesheetTypes.MONTHLY) && (
            <Shine className={styles.Preloader_header_shine} />
          )}
        </>
      )}
    </div>

    <Separator className={styles.Preloader_separator} />

    <div
      className={cn(styles.Preloader_body, styles[`Preloader_body___${type}`])}
    >
      <Shine className={styles.Preloader_body_shine} />
      <Shine className={styles.Preloader_body_shine} />

      {type !== timesheetTypes.DAILY && (
        <>
          <Shine className={styles.Preloader_body_shine} />
          <Shine className={styles.Preloader_body_shine} />
          <Shine className={styles.Preloader_body_shine} />
          <Shine className={styles.Preloader_body_shine} />
          <Shine className={styles.Preloader_body_shine} />
          <Shine className={styles.Preloader_body_shine} />

          {(type === timesheetTypes.FORTNIGHTLY ||
            type === timesheetTypes.MONTHLY) && (
            <Shine className={styles.Preloader_body_shine} />
          )}
        </>
      )}
    </div>
  </Card>
);

Preloader.propTypes = {
  type: PropTypes.oneOf(Object.values(timesheetTypes)),
};

export default Preloader;
