import { useState, useEffect } from 'react';

import { UsersService } from '../services';

const useGetUsersCount = ({ startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersCount, setUsersCount] = useState({
    employer: 0,
    employee: 0,
    contractor: 0,
  });

  useEffect(() => {
    const getUsersCount = async () => {
      const { data: getUsersCountResponse } = await UsersService.getUsersCount({
        startDate,
        endDate,
      });

      setUsersCount(getUsersCountResponse.count);

      setIsLoading(false);
    };

    if (startDate && endDate) {
      getUsersCount();
    } else {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, usersCount };
};

export default useGetUsersCount;
