import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/subscriptions`;

const SubscriptionsService = {
  createCustomer: (body) => axios.post(`${BASE_URL}/createCustomer`, body),
  createSubscription: (body) =>
    axios.post(`${BASE_URL}/createSubscription`, body),
  retrievePaymentMethod: (body) =>
    axios.post(`${BASE_URL}/retrievePaymentMethod`, body),
  retrieveSubscription: (body) =>
    axios.post(`${BASE_URL}/retrieveSubscription`, body),
  updateSubscription: (body) =>
    axios.post(`${BASE_URL}/updateSubscription`, body),
  updatePaymentMethod: (body) =>
    axios.post(`${BASE_URL}/updatePaymentMethod`, body),
  cancelSubscription: (body) =>
    axios.post(`${BASE_URL}/cancelSubscription`, body),
};

export default SubscriptionsService;
