import { isStaging, isLocal } from '../utils/destinations';

const subscriptionPlans = [
  {
    id:
      isStaging || isLocal
        ? 'price_1JSJnmDmmWh32CUjBBoQA7g3'
        : 'price_1JTGGnDmmWh32CUj9s2pshMK',
    name: 'Small',
    timeInterval: 'Yearly',
    minAllowedEmployees: 1,
    maxAllowedEmployees: 10,
    description: 'For companies with 10 or fewer employees',
    price: 8,
  },
  {
    id:
      isStaging || isLocal
        ? 'price_1JSJnmDmmWh32CUj1UDe9vz1'
        : 'price_1JTGGnDmmWh32CUjY2P5V0c2',
    name: 'Small',
    timeInterval: 'Monthly',
    minAllowedEmployees: 1,
    maxAllowedEmployees: 10,
    description: 'For companies with 10 or fewer employees',
    price: 9,
  },
  {
    id:
      isStaging || isLocal
        ? 'price_1JSJmeDmmWh32CUjKuKbBXyl'
        : 'price_1JTGGPDmmWh32CUjjkzOjxZ1',
    name: 'Medium',
    timeInterval: 'Yearly',
    minAllowedEmployees: 11,
    maxAllowedEmployees: 25,
    description: 'For companies with 25 or fewer employees',
    price: 6,
  },
  {
    id:
      isStaging || isLocal
        ? 'price_1JSJmeDmmWh32CUjLtZYZDog'
        : 'price_1JTGGPDmmWh32CUjSfAfxuyZ',
    name: 'Medium',
    timeInterval: 'Monthly',
    minAllowedEmployees: 11,
    maxAllowedEmployees: 25,
    description: 'For companies with 25 or fewer employees',
    price: 7,
  },
  {
    id:
      isStaging || isLocal
        ? 'price_1JSJkSDmmWh32CUj5kULMvIL'
        : 'price_1JTGFHDmmWh32CUjkbFZmUJF',
    name: 'Large',
    timeInterval: 'Yearly',
    minAllowedEmployees: 26,
    maxAllowedEmployees: 100,
    description: 'For companies with more than 25 employees',
    price: 4,
  },
  {
    id:
      isStaging || isLocal
        ? 'price_1JSJkSDmmWh32CUjsF0iFVhk'
        : 'price_1JTGFHDmmWh32CUjEIpeEUYv',
    name: 'Large',
    timeInterval: 'Monthly',
    minAllowedEmployees: 26,
    maxAllowedEmployees: 100,
    description: 'For companies with more than 25 employees',
    price: 5,
  },
];

export default subscriptionPlans;
