import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserContext } from '../contexts';
import { userTypes } from '../globals';

/* eslint-disable react/jsx-props-no-spreading */
const NoAuthRoute = ({ ...rest }) => {
  const { user } = useContext(UserContext);
  let userId = null;
  let userType = null;

  if (user?.id) {
    userId = user.id;
  }

  if (user?.userType) {
    userType = user.userType;
  }

  // Redirect to specific pages if userId exists
  if (userId) {
    if (userType === userTypes.EMPLOYER) {
      if (user?.isNewlySignedUp) {
        return (
          <Route
            name="Employer - Employees"
            render={() => <Redirect to="/employer/employees" />}
          />
        );
      }

      return (
        <Route
          name="Employer"
          render={() => <Redirect to="/employer/dashboard" />}
        />
      );
    }
    if (userType === userTypes.EMPLOYEE) {
      return (
        <Route
          name="Employee"
          render={() => <Redirect to="/employee/dashboard" />}
        />
      );
    }
    if (userType === userTypes.CONTRACTOR) {
      return (
        <Route
          name="Contractor"
          render={() => <Redirect to="/contractor/dashboard" />}
        />
      );
    }
    if (userType === userTypes.ADMIN) {
      return (
        <Route name="Admin" render={() => <Redirect to="/admin/dashboard" />} />
      );
    }
  }

  // the page can be accessed by the user
  return <Route {...rest} />;
};

export default NoAuthRoute;
