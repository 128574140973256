import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import dateFormat from 'dateformat';
import styles from './styles.module.scss';

import {
  Button,
  ControlledInput,
  ControlledSelect,
  DatePicker,
  Text,
  Spinner,
  HoursPicker,
} from '../../../../components/elements';
import {
  buttonKinds,
  colorClasses,
  colorNames,
  inputTypes,
  selectTypes,
  textTypes,
  spinnerSizes,
} from '../../../../globals';
import { UserContext } from '../../../../contexts';
import { UsersService } from '../../../../services';
import { isValidDecimal } from '../../../../utils/string';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { checkIfTimeIsValid, convertTimeTo12HourFormat } from '../../../../utils/datetime';

const validate = (values) => {
  const errors = {};

  if (!values.timesheetStartDate) {
    errors.timesheetStartDate = 'This field is required.';
  }

  if (!values.timesheetStartWeek) {
    errors.timesheetStartWeek = 'This field is required.';
  }

  if (!values.isLunchBreakByDefault) {
    errors.isLunchBreakByDefault = 'This field is required.';
  }
  
  if ( !values.lunchBreakDuration) {
    errors.lunchBreakDuration = 'This field is required.';
  }
  if(values.lunchBreakDuration){
    if(!checkIfTimeIsValid(convertTimeTo12HourFormat(values.lunchBreakDuration))){
        errors.lunchBreakDuration = 'Invalid time format.';
      }
  }
  if (!values.hoursAndMinutesFormat) {
    errors.hoursAndMinutesFormat = 'This field is required.';
  }

  if (!values.hourlyWage) {
    errors.hourlyWage = 'This field is required.';
  }

  if (values.hourlyWage && !isValidDecimal(values.hourlyWage)) {
    errors.hourlyWage = 'Only numbers/decimals up to 2 places are allowed.';
  }

  return errors;
};

const Timesheets = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [isUpdating, setIsUpdating] = useState(false);
  
  return (
    <div className={styles.Timesheets}>
      <Text type={textTypes.HEADING.SM} className={styles.Timesheets_title}>
        Timesheets
      </Text>

      <Formik
        initialValues={{
          timesheetStartDate: new Date(user.timesheetStartDate),
          timesheetStartWeek: {
            label: user.timesheetStartWeek,
            value: user.timesheetStartWeek,
          },
          isLunchBreakByDefault: {
            label: user.isLunchBreakByDefault ? 'Yes' : 'No',
            value: user.isLunchBreakByDefault,
          },
          lunchBreakDuration: user.lunchBreakDuration,
          hoursAndMinutesFormat: {
            label: user.hoursAndMinutesFormat ? 'Yes' : 'No',
            value: user.hoursAndMinutesFormat,
          },
          hourlyWage: user?.hourlyWage ? user?.hourlyWage : '0',
        }}
        onSubmit={async (values, { setErrors }) => {
    
          const currentFormValues = {
            timesheetStartDate: dateFormat(
              values.timesheetStartDate,
              'yyyy-mm-dd'
            ),
            timesheetStartWeek: values.timesheetStartWeek.value,
            isLunchBreakByDefault: values.isLunchBreakByDefault.value,
            lunchBreakDuration: values.lunchBreakDuration,
            hoursAndMinutesFormat: values.hoursAndMinutesFormat.value
              ? values.hoursAndMinutesFormat.value
              : false,
            weeklyReportEmailAddress: values.weeklyReportEmailAddress
              ? values.weeklyReportEmailAddress
              : null,
            hourlyWage: values.hourlyWage ? Number(values.hourlyWage) : null,
          };

          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          setIsUpdating(true);

          // Update the user
          const { data: updateResponse } =
            await UsersService.updateTimesheetSettings({
              userId: user.id,
              userType: user.userType,
              isTravelChargeByDefault: false,
              ...currentFormValues,
            });

          if (updateResponse.message === 'timesheet_settings_updated') {
            // Show an alert
            alert.success('Timesheet Settings updated!');
            // Update user state
            loginUpdate({ ...user, ...currentFormValues });
          } else {
            alert.error('Oops, something went wrong.');
          }

          setIsUpdating(false);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <DatePicker
              type={inputTypes.SLIM}
              label="Timesheet Start Date* (For Weekly and Fortnightly Views)"
              className={styles.Timesheets_input}
              dateFormat="dd/MM/yyyy"
              selected={values.timesheetStartDate}
              name="timesheetStartDate"
              icon="today"
              onChange={(date) => {
                setFieldValue('timesheetStartDate', date);
              }}
              disableFutureDates
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Timesheet Start Week* (For Monthly View)"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Sunday',
                  value: 'Sunday',
                },
                {
                  label: 'Monday',
                  value: 'Monday',
                },
                {
                  label: 'Tuesday',
                  value: 'Tuesday',
                },
                {
                  label: 'Wednesday',
                  value: 'Wednesday',
                },
                {
                  label: 'Thursday',
                  value: 'Thursday',
                },
                {
                  label: 'Friday',
                  value: 'Friday',
                },
                {
                  label: 'Saturday',
                  value: 'Saturday',
                },
              ]}
              name="timesheetStartWeek"
              value={values.timesheetStartWeek}
              error={errors.timesheetStartWeek}
              onChange={(val) => {
                setFieldValue('timesheetStartWeek', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />


            <ControlledSelect
              type={selectTypes.SLIM}
              label="Lunch Break By Default*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="isLunchBreakByDefault"
              value={values.isLunchBreakByDefault}
              error={errors.isLunchBreakByDefault}
              onChange={(val) => {
                setFieldValue('isLunchBreakByDefault', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            {
              <div className={styles.CreateTimesheet_withMargin}>
                <Text
                  // type={textTypes.BODY.MD}
                  // type={selectTypes.SLIM}
                >
                  Lunch Break Duration
                </Text>
                {
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <HoursPicker
                      ampm={false}
                      name='lunchBreakDuration'
                      className={styles.Timesheets_input}
                      format={'HH:mm'}
                      time={dayjs(values.lunchBreakDuration,'hh:mm')}
                      minTime={dayjs().set('hour', 0).set('minutes', 0)}
                      maxTime={dayjs().set('hour', 10).set('minutes', 0)}
                      onChange={(time) => {
                        setFieldValue('lunchBreakDuration',time.format('HH:mm'))
                      }}
                      error={errors.lunchBreakDuration}
                      clearable
                    />
                  </LocalizationProvider>
                }
              </div>
            }

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Display Hours in Hours and Minutes Format*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="hoursAndMinutesFormat"
              value={values.hoursAndMinutesFormat}
              error={errors.hoursAndMinutesFormat}
              onChange={(val) => {
                setFieldValue('hoursAndMinutesFormat', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <Text
              className={styles.Timesheets_label}
              type={textTypes.BODY.MD}
              colorClass={colorClasses.NEUTRAL['700']}
            >
              Hourly Wage
            </Text>

            <ControlledInput
              type={inputTypes.SLIM}
              name="hourlyWage"
              value={values.hourlyWage}
              error={errors.hourlyWage}
              onChange={(e) => setFieldValue('hourlyWage', e.target.value)}
            />

            <div className={styles.Timesheets_buttonGroup}>
              <Button
                kind={buttonKinds.SUBMIT}
                disabled={isUpdating}
                onClick={() => { }}
              >
                <span className={styles.Timesheets_buttonGroup_buttonText}>
                  Update
                  {isUpdating && (
                    <Spinner
                      size={spinnerSizes.XS}
                      colorName={colorNames.WHITE}
                      className={styles.Timesheets_buttonGroup_spinner}
                    />
                  )}
                </span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Timesheets;
