import { useState } from 'react';

import { ReviewsService } from '../services';

const useUploadReviewPhoto = () => {
  const [isUploading, setIsUploading] = useState(false);

  const uploadReviewPhoto = async (base64Photo) => {
    setIsUploading(true);

    let responseCode;
    let fileName;

    try {
      const response = await ReviewsService.uploadPhoto({
        body: { base64Photo },
      });
      responseCode = response.status;
      fileName = response.data.fileName;
    } catch (error) {
      responseCode = error.response.status;
    }

    setIsUploading(false);

    return { responseCode, fileName };
  };

  return { isUploading, uploadReviewPhoto };
};

export default useUploadReviewPhoto;
