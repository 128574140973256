import { useState, useEffect } from 'react';

import { SubscriptionsService } from '../services';

const useRetrievePaymentMethod = ({ customerId, isSubscribed }) => {
  const [isRetrieving, setIsRetrieving] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    const retrivePaymentMethod = async () => {
      const { data: retrievePaymentMethodResponse } =
        await SubscriptionsService.retrievePaymentMethod({
          customerId,
        });

      if (retrievePaymentMethodResponse.paymentMethod) {
        setPaymentMethod(retrievePaymentMethodResponse.paymentMethod);
      }

      setIsRetrieving(false);
    };

    if (isSubscribed) {
      retrivePaymentMethod();
    }
  }, []);

  return { isRetrieving, paymentMethod };
};

export default useRetrievePaymentMethod;
