import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/reviews`;

const ReviewsService = {
  get: ({ params: { vehicleId } }) =>
    axios.get(`${BASE_URL}/`, {
      params: {
        vehicleId,
      },
    }),
  retrieve: ({ vehicleId, reviewId }) =>
    axios.get(`${BASE_URL}/${reviewId}`, {
      params: {
        vehicleId,
      },
    }),
  create: ({ body }) => axios.post(`${BASE_URL}/`, body),
  sendReport: (body) => axios.post(`${BASE_URL}/sendReport`, body),
  downloadReport: (body) =>
    axios.post(`${BASE_URL}/downloadReport`, body, {
      responseType: 'blob',
    }),
  uploadPhoto: ({ body }) => axios.post(`${BASE_URL}/uploadPhoto`, body),
};

export default ReviewsService;
