import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import EasyEdit from 'react-easy-edit';
import { useAlert } from 'react-alert';
import styles from '../styles.module.scss';

import { Icon, InputMask, Text } from '../../elements';
import { checkIf24TimeIsValid, checkIfTimeIsValid, convertTimeTo12HourFormat } from "../../../utils/datetime";

const EditableTime = ({ initialValue, is24Hour = false, placeholder, onSave }) => {
  const alert = useAlert();
  const [inputtedTime, setInputtedTime] = useState(initialValue);
  const [currentTime, setCurrentTime] = useState(initialValue);
  const startsWithZero = (time) => {
    if (time && time[0] === '0') return true;
    return false;
  };

  return (
    <EasyEdit
      type="text"
      saveButtonLabel={<Icon icon="check" />}
      cancelButtonLabel={<Icon icon="close" />}
      saveButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_save
      )}
      cancelButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_cancel
      )}
      
      editComponent={
        <InputMask
          mask={
            is24Hour ? [
              /[0-2]/,
              /[0-9]/,
              ':',
              /[0-5]/,
              /[0-9]/
            ] :
              [
                /[0-1]/,
                startsWithZero(inputtedTime) ? /[1-9]/ : /[0-2]/,
                ':',
                /[0-5]/,
                /[0-9]/,
                ' ',
                /[a,p]/,
                'm',
              ]
          }
          maskPlaceholder={is24Hour ? "hh:mm" : "hh:mm xm"}
          alwaysShowMask
          name="editableTime"
          inputClassName={styles.Editable_input}
          onChange={(e) => setInputtedTime(e.target.value)}
          withValue={false}
          withStyles={false}
        />
      }
      displayComponent={
        <Text className={styles.Editable_displayValue}>{currentTime}</Text>
      }
      placeholder={placeholder}
      value={currentTime}
      onSave={async () => {
        if(placeholder === 'empty' && is24Hour){
          if(checkIf24TimeIsValid(inputtedTime)){
          const isSuccessfulSave = await onSave(inputtedTime);
          if (isSuccessfulSave) {
            setCurrentTime(inputtedTime);
          }
          }
          else{
            alert.error('Oops, value should be a valid time.');
            setInputtedTime(currentTime);
          }
        }
        else if(is24Hour){
          if(checkIf24TimeIsValid(inputtedTime)){
            let finalTime = convertTimeTo12HourFormat(inputtedTime);
            const isSuccessfulSave = await onSave(finalTime);
            if (isSuccessfulSave) {
              setCurrentTime(finalTime);
            }
          }
          else{
            alert.error('Oops, value should be a valid time.');
            setInputtedTime(currentTime);
          }
        } 
        else{
          if (checkIfTimeIsValid(inputtedTime)) {
            const isSuccessfulSave = await onSave(inputtedTime);
            if (isSuccessfulSave) {
              setCurrentTime(inputtedTime);
            }
          } else {
            alert.error('Oops, value should be a valid time.');
            setInputtedTime(currentTime);
          }
        }

      }}
      cssClassPrefix={!currentTime ? 'placeholder' : ''}
      onCancel={() => setInputtedTime(currentTime)}
    />
  );
};

EditableTime.defaultProps = {
  initialValue: null,
  placeholder: 'Empty',
};

EditableTime.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default EditableTime;
