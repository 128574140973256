import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import styles from '../styles.module.scss';

import { ControlledSelect, Spinner, Modal } from '../../../elements';
import {
  buttonTypes,
  colorNames,
  modalPositions,
  modalSizes,
  spinnerSizes,
} from '../../../../globals';
import { XeroService } from '../../../../services';

const validate = (values) => {
  const errors = {};

  if (!values.tenant) {
    errors.tenant = 'This field is required.';
  }

  return errors;
};

const SelectTenantModal = ({
  isOpen,
  handleClose,
  tokenSet,
  handleSuccess,
}) => {
  const cookies = new Cookies();
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tenants, setTenants] = useState(null);

  useEffect(async () => {
    try {
      const { data: getTenantsResponse } = await XeroService.getTenants({
        tokenSet,
      });

      if (getTenantsResponse.tenants) {
        setTenants(
          getTenantsResponse.tenants?.map(({ tenantName, tenantId }) => ({
            label: tenantName,
            value: tenantId,
          }))
        );
      }

      if (getTenantsResponse.tokenSet) {
        // The tokens have been refreshed, so we update the
        // xeroTokenSet in the cookies
        cookies.set('xeroTokenSet', getTenantsResponse.tokenSet, {
          path: '/',
        });
      }
    } catch {
      // Both access token and refresh token is invalid already.
      // We remove the xeroTokenSet in the cookies
      cookies.remove('xeroTokenSet', {
        path: '/',
      });
    }
  }, []);

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Select a Tenant"
      className={styles.ForgotPasswordModals}
      hasCloseButton={false}
      actions={[
        {
          text: 'Proceed',
          type: buttonTypes.PRIMARY.VIOLET,
          disabled: isSubmitting === true || !tenants,
          onClick: () => {
            formRef.current.handleSubmit();
          },
        },
      ]}
    >
      {!tenants ? (
        <Spinner colorName={colorNames.VIOLET} size={spinnerSizes.LG} />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            tenant: tenants.length > 0 ? tenants[0] : null
        }}
          onSubmit={async (values, { setErrors }) => {
            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            setIsSubmitting(true);

            handleSuccess({
              tenantId: values.tenant.value,
            });

            setIsSubmitting(false);
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (

            <form onSubmit={handleSubmit}>

              <ControlledSelect
                options={tenants}
                name="tenant"
                placeholder="Tenant*"
                value={values.tenant}
                error={errors.tenant}
                onChange={(val) => {
                  setFieldValue('tenant', {
                    label: val.label,
                    value: val.value,
                  });
                }}
              />
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

SelectTenantModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tokenSet: PropTypes.object.isRequired,
  // doesn't accept any parameter. This will just
  // proceed to the next modal
  handleSuccess: PropTypes.func.isRequired,
};

export default SelectTenantModal;
