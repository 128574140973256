import React, { useContext } from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  ControlledTextArea,
  Spinner,
} from '../../../components/elements';
import { isValidEmail, isValidPhoneNumber } from '../../../utils/string';
import { buttonKinds, colorNames, spinnerSizes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useCreateClient } from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'This field is required.';
  }

  if (values.emailAddress && !isValidEmail(values.emailAddress)) {
    errors.emailAddress = 'This must be a valid email address.';
  }

  if (values.phoneNumber) {
    if (!isValidPhoneNumber(values.phoneNumber)) {
      errors.phoneNumber = 'Only numbers are allowed.';
    } else if (values.phoneNumber.length > 15) {
      errors.phoneNumber = 'The maximum length of this field is 15 characters.';
    }
  }

  return errors;
};

const CreateClient = () => {
  const { user } = useContext(UserContext);
  const { isCreating: isClientCreating, createClient } = useCreateClient();
  const userTypeInsensitive = user.userType.toLowerCase();

  return (
    <div className={styles.CreateClient}>
      <Breadcrumbs
        pageTitle="Create Client"
        pages={[
          {
            name: 'Dashboard',
            link: `/${userTypeInsensitive}/dashboard`,
          },
          {
            name: 'Clients',
            link: `/${userTypeInsensitive}/clients`,
          },
        ]}
      />

      <Card className={styles.CreateClient_card}>
        <Formik
          initialValues={{
            name: '',
            emailAddress: '',
            phoneNumber: '',
            address: '',
            notes: '',
            isImportant: false,
          }}
          onSubmit={async (values, { setErrors, setFieldValue }) => {
            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            // Create client object
            const client = {
              userId: user.id,
              name: values.name,
              emailAddress: values.emailAddress
                ? values.emailAddress
                : undefined,
              phoneNumber: values.phoneNumber ? values.phoneNumber : undefined,
              address: values.address ? values.address : undefined,
              notes: values.notes ? values.notes : undefined,
              isImportant: values.isImportant ? values.isImportant : false,
            };

            const { message } = await createClient(client);

            if (message === 'client_created') {
              // Reset the form to its initial state
              setFieldValue('name', '');
              setFieldValue('emailAddress', '');
              setFieldValue('phoneNumber', '');
              setFieldValue('address', '');
              setFieldValue('notes', '');
              setFieldValue('isImportant', false);
            }
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <ControlledInput
                name="name"
                placeholder="Name*"
                icon="person"
                value={values.name}
                error={errors.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />

              <ControlledInput
                className={styles.CreateClient_withMargin}
                name="emailAddress"
                placeholder="Email Address"
                icon="mail"
                value={values.emailAddress}
                error={errors.emailAddress}
                onChange={(e) => setFieldValue('emailAddress', e.target.value)}
              />

              <ControlledInput
                className={styles.CreateClient_withMargin}
                name="phoneNumber"
                placeholder="Phone Number"
                icon="phone"
                value={values.phoneNumber}
                error={errors.phoneNumber}
                onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
              />

              <ControlledInput
                className={styles.CreateClient_withMargin}
                name="address"
                placeholder="Address"
                icon="home"
                value={values.address}
                error={errors.address}
                onChange={(e) => setFieldValue('address', e.target.value)}
              />

              <ControlledTextArea
                className={styles.CreateClient_withMargin}
                name="notes"
                placeholder="Notes"
                icon="description"
                value={values.notes}
                error={errors.notes}
                onChange={(e) => setFieldValue('notes', e.target.value)}
              />

              <div style={{marginTop: '8%'}}>
                <input type="checkbox" 
                name='isImportant' 
                value={values.isImportant}
                checked={ values.isImportant }
                onChange={ (e) => { setFieldValue('isImportant', e.target.checked) } } 
                /> Important
              </div>

              <div className={styles.CreateClient_buttonGroup}>
                <Button
                  className={styles.CreateClient_buttonGroup_button}
                  kind={buttonKinds.SUBMIT}
                  icon="add"
                  disabled={isClientCreating}
                  onClick={() => {}}
                >
                  <span className={styles.CreateClient_buttonGroup_buttonText}>
                    Create Client
                    {isClientCreating && (
                      <Spinner
                        size={spinnerSizes.XS}
                        colorName={colorNames.WHITE}
                        className={styles.CreateClient_buttonGroup_spinner}
                      />
                    )}
                  </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default CreateClient;
