import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import dateFormat from 'dateformat';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  ControlledTextArea,
  ControlledSelect,
  DatePicker,
  Spinner,
} from '../../../components/elements';
import { isValidDecimal } from '../../../utils/string';
import { buttonKinds, colorNames, spinnerSizes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useProject, useUpdateProject } from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'This field is required.';
  }

  if (values.expectedHours && !isValidDecimal(values.expectedHours)) {
    errors.expectedHours = 'Only numbers/decimals up to 2 places are allowed.';
  }

  return errors;
};

const UpdateProject = () => {
  const { user } = useContext(UserContext);
  const { clientId, projectId } = useParams();
  const { isLoading: isProjectLoading, project } = useProject({
    clientId,
    projectId,
  });
  const { isUpdating: isProjectUpdating, updateProject } = useUpdateProject();
  const userTypeInsensitive = user.userType.toLowerCase();


  return (
    <div className={styles.UpdateProject}>
      <Breadcrumbs
        pageTitle="Update Project"
        pages={[
          {
            name: 'Dashboard',
            link: `/${userTypeInsensitive}/dashboard`,
          },
          {
            name: 'Clients',
            link: `/${userTypeInsensitive}/clients`,
          },
          {
            name: 'View Client',
            link: `/${userTypeInsensitive}/clients/view/${clientId}`,
          },
        ]}
      />

      {isProjectLoading ? (
        <Preloader />
      ) : (
        <Card className={styles.UpdateProject_card}>
          <Formik
            initialValues={project}
            onSubmit={async (values, { setErrors }) => {
              const errors = validate(values);
              if (!isEmpty(errors)) {
                setErrors(errors);
                return;
              }

              // Create new project object
              const newProject = {
                clientId,
                projectId,
                status: values.status.value,
                name: values.name,
                startDate: values.startDate
                  ? dateFormat(values.startDate, 'yyyy-mm-dd')
                  : undefined,
                projectedFinishDate: values.projectedFinishDate
                  ? dateFormat(values.projectedFinishDate, 'yyyy-mm-dd')
                  : undefined,
                address: values.address ? values.address : undefined,
                expectedHours: values.expectedHours
                  ? values.expectedHours
                  : undefined,
                notes: values.notes ? values.notes : undefined,
                isImportant: values.isImportant ? values.isImportant : false,
                isFinshed: values.isFinshed ? values.isFinshed : false,
              };

              updateProject(newProject);
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <ControlledSelect
                  options={[
                    {
                      label: 'Ongoing',
                      value: 'Ongoing',
                    },
                    {
                      label: 'Finished',
                      value: 'Finished',
                    },
                  ]}
                  name="status"
                  placeholder="Status*"
                  value={values?.status}
                  error={errors.status}
                  onChange={(val) => {
                    setFieldValue('status', {
                      label: val.label,
                      value: val.value,
                    });
                  }}
                />

                <ControlledInput
                  className={styles.UpdateProject_withMargin}
                  name="name"
                  placeholder="Name*"
                  icon="person"
                  value={values?.name}
                  error={errors.name}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />

                <DatePicker
                  className={styles.UpdateProject_withMargin}
                  dateFormat="dd/MM/yyyy"
                  selected={values?.startDate}
                  placeholder="Start Date"
                  name="startDate"
                  icon="today"
                  onChange={(startDate) => {
                    setFieldValue('startDate', startDate);
                  }}
                />

                <DatePicker
                  className={styles.UpdateProject_withMargin}
                  dateFormat="dd/MM/yyyy"
                  selected={values?.projectedFinishDate}
                  placeholder="Projected Finish Date"
                  name="projectedFinishDate"
                  icon="today"
                  onChange={(projectedFinishDate) => {
                    setFieldValue('projectedFinishDate', projectedFinishDate);
                  }}
                />

                <ControlledInput
                  className={styles.UpdateProject_withMargin}
                  name="address"
                  placeholder="Address"
                  icon="home"
                  value={values?.address}
                  error={errors.address}
                  onChange={(e) => setFieldValue('address', e.target.value)}
                />

                <ControlledInput
                  className={styles.UpdateProject_withMargin}
                  name="expectedHours"
                  placeholder="Expected Hours"
                  icon="schedule"
                  value={values?.expectedHours}
                  error={errors.expectedHours}
                  onChange={(e) =>
                    setFieldValue('expectedHours', e.target.value)
                  }
                />

                <ControlledTextArea
                  className={styles.UpdateProject_withMargin}
                  name="notes"
                  placeholder="Notes"
                  icon="description"
                  value={values?.notes}
                  error={errors.notes}
                  onChange={(e) => setFieldValue('notes', e.target.value)}
                />

                <div style={{marginTop: '8%'}}>
                  <input type="checkbox"
                         name='isImportant'
                         value={values.isImportant}
                         checked={ values.isImportant }
                         onChange={ (e) => { setFieldValue('isImportant', e.target.checked) } }
                  /> Important
                </div>

                {/* <div style={{marginTop: '8%'}}>
                  <input type="checkbox"
                         name='isFinshed'
                         value={values.isFinshed}
                         checked={ values.isFinshed }
                         onChange={ (e) => { setFieldValue('isFinshed', e.target.checked);   } }
                  /> Mark as Finished
                </div> */}

                <div className={styles.UpdateProject_buttonGroup}>
                  <Button
                    className={styles.UpdateProject_buttonGroup_button}
                    kind={buttonKinds.SUBMIT}
                    icon="add"
                    disabled={isProjectUpdating}
                    onClick={() => {}}
                  >
                    <span
                      className={styles.UpdateProject_buttonGroup_buttonText}
                    >
                      Update Project
                      {isProjectUpdating && (
                        <Spinner
                          size={spinnerSizes.XS}
                          colorName={colorNames.WHITE}
                          className={styles.UpdateProject_buttonGroup_spinner}
                        />
                      )}
                    </span>
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      )}
    </div>
  );
};

export default UpdateProject;
