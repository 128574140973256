import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from '../styles.module.scss';

import { Modal, ControlledInput } from '../../../elements';
import {
  modalSizes,
  modalPositions,
  buttonTypes,
  inputTypes,
  inputKinds,
} from '../../../../globals';
import { UsersService } from '../../../../services';

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'This field is required.';
  } else if (values.password.length > 30) {
    errors.password = 'The maximum length of this field is 30 characters.';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'This field is required.';
  } else if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'This must match with your new password.';
  }

  return errors;
};

const ChangePasswordModal = ({
  isOpen,
  handleClose,
  userId,
  confirmationCode,
  handleSuccess,
}) => {
  const formRef = useRef(null);
  const [isChangingPassword, toggleIsChangingPassword] = useState(false);

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="New Beginnings"
      className={styles.ForgotPasswordModals}
      hasCloseButton={false}
      actions={[
        {
          text: 'Proceed',
          type: buttonTypes.PRIMARY.VIOLET,
          disabled: isChangingPassword,
          onClick: () => {
            formRef.current.handleSubmit();
          },
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          toggleIsChangingPassword(true);

          const { data: changePasswordResponse } =
            await UsersService.changePassword({
              userId,
              password: values.password,
              confirmationCodeId: confirmationCode.id,
            });

          toggleIsChangingPassword(false);

          if (changePasswordResponse.message === 'password_changed') {
            handleSuccess();
          }
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ControlledInput
              type={inputTypes.SLIM}
              label="For security purposes, please enter your new password below"
              kind={inputKinds.PASSWORD}
              className={styles.ForgotPasswordModals_withMarginBottom}
              placeholder="New Password"
              name="password"
              value={values.password}
              error={errors.password}
              onChange={(e) => setFieldValue('password', e.target.value)}
            />
            <ControlledInput
              type={inputTypes.SLIM}
              kind={inputKinds.PASSWORD}
              placeholder="Confirm Password"
              name="confirmPassword"
              value={values.confirmPassword}
              error={errors.confirmPassword}
              onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  confirmationCode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    forEmailAddress: PropTypes.string.isRequired,
  }).isRequired,
  // function that doesn't accept any parameter. This just
  // redirects to the forgot password success modal
  handleSuccess: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
