import {
  convertSecondsToHours,
  convertTimeTo24HourFormat,
  convert24HourTimeToTimestamp,
  getDifferenceBetweenDates,
  getPrevOrAfterDate,
  getDayIdFromDate,
  getDayIdFromDayName,
  convertTimestampToDate,
} from './datetime';
import { leaveTypes, timesheetTypes } from '../globals';
import moment from 'moment';

export const getHours = (
  startTimeTimestamp,
  endTimeTimestamp,
  isLunchBreak = false,
  lunchBreakDuration = "00:00",
  isTravelCharge = false,
  leaveType = leaveTypes.NONE,
  publicHolidayMultiplier = 1
) => {
  let seconds = 0;
  if (
    leaveType === leaveTypes.NONE ||
    leaveType === leaveTypes.PUBLIC_HOLIDAY ||
    leaveType === leaveTypes.SICK 
  ) {
    if (!startTimeTimestamp || !endTimeTimestamp) {
      if (leaveType === leaveTypes.NONE) {
        return 0;
      }

      if (
        leaveType === leaveTypes.PUBLIC_HOLIDAY ||
        leaveType === leaveTypes.SICK
      ) {
        seconds = 28800; // Return 8 hours
      }
    } else {
      // Compute for startTimeTimestamp and endTimeTimestamp difference
      if (startTimeTimestamp > endTimeTimestamp) {
        // Minus 24 hours since startTimeTimestamp overlaps endTimeTimestamp already
        seconds = 86400 - (startTimeTimestamp - endTimeTimestamp);
      } else {
        seconds = endTimeTimestamp - startTimeTimestamp;
      }

      // Apply multiplier
      if (leaveType === leaveTypes.PUBLIC_HOLIDAY) {
        seconds *= publicHolidayMultiplier;
      }
      // Subtract 30 minutes if isLunchBreak is true
      // AND computed seconds above ^ is > 1800s (30 minutes)

        if (isLunchBreak && seconds > 1800) {
          if (lunchBreakDuration !== "00:00") {
            let tempSecond = moment(lunchBreakDuration, "HH:mm");
            const seconds2 = tempSecond.seconds() + tempSecond.minutes() * 60 + tempSecond.hours() * 3600;
            let tempSecond2 = seconds - seconds2;
            if (tempSecond2 >= 0) {
              seconds -= seconds2; 
            } 
          } else {
            seconds -= 1800;
          }
        }
      
      if (isTravelCharge) {
        seconds = seconds + 3600;
      }
    }
  } else if (leaveType === leaveTypes.WITHOUT_PAY) {

    seconds = 0;
  } else {
    seconds = 28800; // Return 8 hours
  }
  
  // Convert seconds to hours
  return convertSecondsToHours(seconds);
};

export const getDateEquivalence = (viewType, date, dateType) => {
  // viewType can be anything in timesheetTypes
  // date can be 'startDate' or 'endDate'
  // dateType can be 'start' or 'end'
  if (!viewType || !date || !dateType) return undefined;

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let duration = 0;
  if (viewType === timesheetTypes.DAILY) {
    duration = 1;
  } else if (viewType === timesheetTypes.WEEKLY) {
    duration = 7;
  } else if (viewType === timesheetTypes.FORTNIGHTLY) {
    duration = 14;
  } else if (viewType === timesheetTypes.MONTHLY) {
    duration = 28;
  }

  let calculation =
    Math.floor(getDifferenceBetweenDates(date, currentDate) / duration) *
    duration;

  if (dateType === 'end') {
    calculation += duration - 1;
  }

  return getPrevOrAfterDate(date, calculation, 'add');
};

export const generateDayIds = (viewType, startDate, timesheetStartWeek) => {
  const dayIds = [];
  let startingDayId = 0;
  if (
    viewType === timesheetTypes.WEEKLY ||
    viewType === timesheetTypes.FORTNIGHTLY
  ) {
    startingDayId = getDayIdFromDate(startDate);
  } else if (viewType === timesheetTypes.MONTHLY) {
    startingDayId = getDayIdFromDayName(timesheetStartWeek);
  }

  for (let i = 0; i < 7; i++) {
    dayIds.push((startingDayId + i) % 7);
  }

  return dayIds;
};

// Checks whether a specific employee is working currently or not
export const checkStatus = (timesheets, employeeId) => {
  const currentDate = convertTimestampToDate(Math.floor(Date.now() / 1000));

  // Filtered by:
  // 1. Specific employee
  // 2. Current date
  // 3. Leave type to which it is applicable (NONE or PUBLIC_HOLIDAY)
  const foundTimesheets = timesheets.filter(
    (timesheet) =>
      timesheet.userId === employeeId &&
      timesheet.date === currentDate &&
      (timesheet.leaveType === leaveTypes.NONE ||
        timesheet.leaveType === leaveTypes.PUBLIC_HOLIDAY)
  );

  let isActive = false;
  if (foundTimesheets) {
    for (let i = 0; i < foundTimesheets.length; i++) {
      if (
        foundTimesheets[i]?.startTimeDetails &&
        !foundTimesheets[i]?.endTimeDetails
      ) {
        isActive = true;
        break;
      }
    }
  }

  return isActive ? 'Active' : 'Inactive';
};

// startTime, endTime, and timeToCheck should be in 24-hour format
export const isStartTimeOverlapping = (startTime, endTime, timeToCheck) => {
  const start = +convert24HourTimeToTimestamp(startTime);
  const end = +convert24HourTimeToTimestamp(endTime);
  const check = +convert24HourTimeToTimestamp(timeToCheck);

  return check >= start && check < end;
};

// times consists of startTime and endTime
// is will only consist of an endTime prop if necessary
export const isTimesheetTimesValid = (timesheets, times) => {
  if (!times?.startTime) return false;

  let isValid = true;
  timesheets.forEach((t) => {
    const startTimeBasis = convertTimeTo24HourFormat(
      t?.startTimeDetails?.startTime
    );
    const endTimeBasis = convertTimeTo24HourFormat(t?.endTimeDetails?.endTime);
    const startTimeToCheck = convertTimeTo24HourFormat(times?.startTime);

    if (startTimeBasis && endTimeBasis) {
      // Check if startTime in times is valid
      if (
        isStartTimeOverlapping(startTimeBasis, endTimeBasis, startTimeToCheck)
      ) {
        isValid = false;
      }

      // Check if times has an endTime
      if (times?.endTime) {
        // Since it has, then we need to do a reverse checking.
        // We check if the start times of the previous timesheets
        // overlap the times.startTime and times.endTime
        const endTimeToCheck = convertTimeTo24HourFormat(times?.endTime);

        if (
          isStartTimeOverlapping(
            startTimeToCheck,
            endTimeToCheck,
            startTimeBasis
          )
        ) {
          isValid = false;
        }
      }
    }
  });

  return isValid;
};

export const convertDateTimeToTimestamp = (date, time) => {
  if (!date || !time) return undefined;

  const dateObject = new Date(date);
  const timeArray = time.split(':'); // Contains hours -> [0] and minutes -> [1]
  dateObject.setHours(Number(timeArray[0]), Number(timeArray[1]), 0, 0);

  return dateObject.getTime() / 1000;
}

// 8:00am - 9:00am
// Start time invalid: 8:00am - 8:59am

// Suppose start time: 7:00am VALID
// Suppose end time: 10:00am INVALID
// Suppose end time: 8:30am INVALID
// Suppose end time: 8:00am VALID

// Therefore,
// Check for start time:
// 1. Overlaps an existing timesheet >= startTime && < endTime
// Check for end time:
// 1. Start times of previous timesheets overlap the current start time and end time (use the same formula for checking startTime)
