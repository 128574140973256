import { isStaging, isLocal } from '../../../../utils/destinations';

const newSubscriptionPlan = [
    {
        id:
            isStaging || isLocal
                ? 'price_1MvaskDmmWh32CUjGLbLNgvT'
                : 'price_1MwdxVDmmWh32CUjTFaxwDNP',
        name: 'Small',
        timeInterval: 'Yearly',
        minAllowedEmployees: 1,
        maxAllowedEmployees: 10,
        description: 'For companies with 10 or fewer employees',
        price: 15,
    },
    {
        id:
            isStaging || isLocal
                ? 'price_1M7FA5DmmWh32CUjq0ZQSfUy'
                : 'price_1MBagKDmmWh32CUjEaX9CbBz',
        name: 'Small',
        timeInterval: 'Monthly',
        minAllowedEmployees: 1,
        maxAllowedEmployees: 10,
        description: 'For companies with 10 or fewer employees',
        price: 16,
    },
    {
        id:
            isStaging || isLocal
                ? 'price_1Mveg1DmmWh32CUjtn4I4q7W'
                : 'price_1Mwe9GDmmWh32CUjzePoa5Tf',
        name: 'Medium',
        timeInterval: 'Yearly',
        minAllowedEmployees: 11,
        maxAllowedEmployees: 25,
        description: 'For companies with 25 or fewer employees',
        price: 13,
    },
    {
        id:
            isStaging || isLocal
                ? 'price_1M7FBQDmmWh32CUj0Xmgpapa'
                : 'price_1MBaglDmmWh32CUjBs6AMOZK',
        name: 'Medium',
        timeInterval: 'Monthly',
        minAllowedEmployees: 11,
        maxAllowedEmployees: 25,
        description: 'For companies with 25 or fewer employees',
        price: 14,
    },
    {
        id:
            isStaging || isLocal
                ? 'price_1MvegxDmmWh32CUjRY0Mmnlt'
                : 'price_1MweAbDmmWh32CUjl5Vq192Q',
        name: 'Large',
        timeInterval: 'Yearly',
        minAllowedEmployees: 26,
        maxAllowedEmployees: 100,
        description: 'For companies with more than 25 employees',
        price: 11,
    },
    {
        id:
            isStaging || isLocal
                ? 'price_1M7FFGDmmWh32CUjFgZQfRuK'
                : 'price_1MBahCDmmWh32CUjdiH8fWNv',
        name: 'Large',
        timeInterval: 'Monthly',
        minAllowedEmployees: 26,
        maxAllowedEmployees: 100,
        description: 'For companies with more than 25 employees',
        price: 12,
    },
];

export default newSubscriptionPlan;