import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

import { Icon } from '../../../components/elements';

const SidebarLink = ({ to, icon, label }) => (
  <NavLink
    to={to}
    className={styles.SidebarLink}
    activeClassName={styles.SidebarLink___active}
  >
    <Icon icon={icon} className={styles.SidebarLink_icon} />
    {label}
  </NavLink>
);

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SidebarLink;
