const colorNames = {
  BLACK: 'black',
  VIOLET: 'violet',
  RED: 'red',
  BLUE: 'blue',
  GREEN: 'green',
  YELLOW: 'yellow',
  WHITE: 'white',
  GRAY: 'gray',
};

export default colorNames;
