import { useState } from 'react';
import { useAlert } from 'react-alert';
import FileDownload from 'js-file-download';
import dateFormat from 'dateformat';

import { TimesheetsService } from '../services';

const useGenerateClientTimesheetReport = () => {
  const alert = useAlert();
  const [isGenerating, setIsGenerating] = useState(false);

  const generateClientReport = async ({
    from,
    to,
    selectedClients,
    selectedProjects,
    employees,
    timesheets,
    reportType,
    hoursAndMinutesFormat,
    emailAddress,
  }) => {
    setIsGenerating(true);

    const fileName = `TimeSheets by Client/Project__${dateFormat(
      new Date(from),
      'mmm d'
    )} - ${dateFormat(new Date(to), 'mmm d, yyyy')}`;

    const filteredTimesheets = [];
    const filteredClient = [];
    employees.map((e) => {

      const getEmployee = timesheets.filter((val) => val.userId === e.id);
      filteredTimesheets.push(getEmployee);
    })
    const finalEmployeeTimesheet = filteredTimesheets.flat();
    selectedClients.map((e) => {
      const filteredProject = [];

      const getClient = finalEmployeeTimesheet.filter((val) => val.clientId === e.value);
      const getProjects = getClient.map((pro) => {
        const getProjectName = pro.projectName;
        filteredProject.push(getProjectName);
        employees.map((emp) => {
          if(emp.id === pro.userId) {
            pro.employeeName = emp.fullName
          }
        })
        return pro;
      })
      let finalProjects = [];
      if(selectedProjects.length > 0){
        selectedProjects.map((selectPro) => {
            const selectedProjectName = selectPro.label;
            finalProjects.push(selectedProjectName);
            finalProjects.sort();
        })
      } else {
         finalProjects = filteredProject.filter((item,
          index) => filteredProject.indexOf(item) === index).sort();
      }

      filteredClient.push({ name: e.label, timesheets: getClient, projects: finalProjects });
    });
 
    try{
      const { data: generateClientReportResponse } =
      await TimesheetsService.generateClientReport({
        fileName,
        employees,
        timesheets: filteredClient,
        type: reportType,
        hoursAndMinutesFormat,
        emailAddress,
      });

    // Download the file if reportType is 'Download'
    if (reportType === 'Download') {
      FileDownload(generateClientReportResponse, `${fileName}.xlsx`);
      alert.success('Timesheet report downloaded!');
    }

    // // Send an alert if reportType is 'Send'
    // if (reportType === 'Send') {
    //   if (generateClientReportResponse.message === 'report_sent') {
    //     alert.success('Timesheet report sent!');
    //   } else {
    //     alert.error('Oops, something went wrong.');
    //   }
    // }

    setIsGenerating(false);

    return { message: 'generate_report_successful' };
    }catch(e){
      setIsGenerating(false);
      alert.error("You haven't timesheet between those dates.");
      return { message: 'generate_report_successful' };
    }
    
  };

  return { isGenerating, generateClientReport };
};

export default useGenerateClientTimesheetReport;
