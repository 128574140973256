export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidPhoneNumber = (phoneNumber) => {
  const re = /^\d+$/;
  return re.test(phoneNumber);
};

export const isValidDecimal = (decimal) => {
  const re = /^(\d{1,12}|\d{0,12}\.\d{1,2})$/;
  return re.test(decimal);
};

export const isBase64 = (str) => {
  if (!str) return false;

  const regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  return regex.test(str);
};
