export const validateDetails = (values) => {
  const subErrors = {};

  if (!values.name) {
    subErrors.name = 'This field is required.';
  } else if (values.name.length > 20) {
    subErrors.name = 'The maximum length of this field is 20 characters.';
  }

  if (!values.description) {
    subErrors.description = 'This field is required.';
  } else if (values.description.length > 100) {
    subErrors.description =
      'The maximum length of this field is 100 characters.';
  }

  if (!values.color) {
    subErrors.color = 'This field is required.';
  }

  if (!values.employees.length) {
    subErrors.employees = 'This field is required.';
  }

  return subErrors;
};

export const validateSchedules = (values) => {
  const subErrors = {};

  if (values.schedules.length === 0) {
    subErrors.schedulesOverall = 'This field is required.';
  } else {
    const scheduleErrors = values.schedules
      .filter((schedule) => !schedule.startTime || !schedule.endTime)
      .map((schedule) => {
        const startTimeError = !schedule.startTime
          ? 'This field is required.'
          : '';

        let endTimeError = null;
        if (!schedule.endTime) {
          endTimeError = 'This field is required.';
        }

        return {
          day: schedule.day,
          startTime: startTimeError,
          endTime: endTimeError,
        };
      });

    if (scheduleErrors.length > 0) {
      subErrors.schedules = scheduleErrors;
    }
  }

  return subErrors;
};
