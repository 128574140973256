import { useState } from 'react';
import { useAlert } from 'react-alert';

import { ClientsService } from '../services';

const useUpdateClient = () => {
  const alert = useAlert();
  const [isUpdating, setIsUpdating] = useState(false);

  const updateClient = async (newClient) => {
    setIsUpdating(true);

    const { data: updateClientResponse } = await ClientsService.update(
      newClient
    );

    setIsUpdating(false);

    let message = null;

    if (updateClientResponse.message === 'client_updated') {
      alert.success('Client updated!');
      message = 'client_updated';
    } else {
      alert.error('Oops, something went wrong.');
      message = 'client_not_updated';
    }

    return { message };
  };

  return { isUpdating, updateClient };
};

export default useUpdateClient;
