import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/xero`;

const XeroService = {
  authorize: () => axios.post(`${BASE_URL}/authorize`),
  generateTokenSet: (body) => axios.post(`${BASE_URL}/generateTokenSet`, body),
  getTenants: (body) => axios.post(`${BASE_URL}/getTenants`, body),
  getEarningRates: (body) => axios.post(`${BASE_URL}/getEarningRates`, body),
  getEmployees: (body) => axios.post(`${BASE_URL}/getEmployees`, body),
  transferTimesheets: (body) =>
    axios.post(`${BASE_URL}/transferTimesheets`, body),
};

export default XeroService;
