import { useState, useContext, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { UserContext } from '../contexts';
import { UsersService } from '../services';

const useEmployees = ({
  adminId,
  employerId,
  includeNoLongerEmployees = false,
  startDate,
}) => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);

  const updateEmailAddress = async (employeeId, emailAddress) => {
    let isEmailAddressUnique = true;

    // Check if email address is unique
    const { data: userExistsResponse } = await UsersService.userExists(
      emailAddress
    );

    if (userExistsResponse.message === 'user_exists') {
      alert.error(`The email address you've inputted has been used already.`);
      isEmailAddressUnique = false;
    }

    // Since it is unique, we update the email address
    if (isEmailAddressUnique) {
      const { data: updateEmailAddressResponse } =
        await UsersService.updateEmailAddress({
          employeeId,
          emailAddress,
        });

      if (
        updateEmailAddressResponse.message === 'employee_email_address_updated'
      ) {
        setEmployees((prevEmployees) =>
          prevEmployees.map((prevEmployee) =>
            prevEmployee.employeeId === employeeId
              ? { ...prevEmployee, emailAddress }
              : prevEmployee
          )
        );

        alert.success(`Employee 'Email Address' updated.`);
      }
    }

    return isEmailAddressUnique;
  };

  const updateIsAdmin = async (employeeId, isAdmin) => {
    const { data: updateIsAdminResponse } = await UsersService.updateIsAdmin({
      employerId,
      employeeId,
      isAdmin,
    });

    if (updateIsAdminResponse.message === 'employee_is_admin_updated') {
      setEmployees((prevEmployees) =>
        prevEmployees.map((prevEmployee) =>
          prevEmployee.employeeId === employeeId
            ? { ...prevEmployee, isAdmin }
            : prevEmployee
        )
      );

      alert.success(`Employee 'Admin' updated.`);
    }
  };

  const updateIsOvertime = async (employeeId, isOvertime) => {
    const { data: updateIsOvertimeResponse } =
      await UsersService.updateIsOvertime({
        employerId,
        employeeId,
        isOvertime,
      });

    if (updateIsOvertimeResponse.message === 'employee_is_overtime_updated') {
      setEmployees((prevEmployees) =>
        prevEmployees.map((prevEmployee) =>
          prevEmployee.employeeId === employeeId
            ? { ...prevEmployee, isOvertime }
            : prevEmployee
        )
      );

      alert.success(`Employee 'Overtime' updated.`);
    }
  };

  const updateExpectedHoursPerWeek = async (
    employeeId,
    expectedHoursPerWeek
  ) => {
    const { data: updateExpectedHoursPerWeekResponse } =
      await UsersService.updateExpectedHoursPerWeek({
        employerId,
        employeeId,
        expectedHoursPerWeek,
      });

    if (
      updateExpectedHoursPerWeekResponse.message ===
      'employee_expected_hours_per_week_updated'
    ) {
      setEmployees((prevEmployees) =>
        prevEmployees.map((prevEmployee) =>
          prevEmployee.employeeId === employeeId
            ? {
                ...prevEmployee,
                expectedHoursPerWeek,
              }
            : prevEmployee
        )
      );

      alert.success(`Employee 'Expected Hours per Week' updated.`);
    }
  };

  const updatePublicHolidayMultiplier = async (
    employeeId,
    publicHolidayMultiplier
  ) => {
    const { data: updatePublicHolidayMultiplierResponse } =
      await UsersService.updatePublicHolidayMultiplier({
        employerId,
        employeeId,
        publicHolidayMultiplier,
      });

    if (
      updatePublicHolidayMultiplierResponse.message ===
      'employee_public_holiday_multiplier_updated'
    ) {
      setEmployees((prevEmployees) =>
        prevEmployees.map((prevEmployee) =>
          prevEmployee.employeeId === employeeId
            ? {
                ...prevEmployee,
                publicHolidayMultiplier,
              }
            : prevEmployee
        )
      );

      alert.success(`Employee 'Public Holiday Multiplier' updated.`);
    }
  };

  const updateWage = async (employeeId, wage) => {
    const { data: updateWageResponse } = await UsersService.updateWage({
      employerId,
      employeeId,
      wage,
    });

    if (updateWageResponse.message === 'employee_wage_updated') {
      setEmployees((prevEmployees) =>
        prevEmployees.map((prevEmployee) =>
          prevEmployee.employeeId === employeeId
            ? {
                ...prevEmployee,
                wage,
              }
            : prevEmployee
        )
      );

      alert.success(`Employee 'Wage' updated.`);
    }
  };

  const removeEmployee = async (employeeId) => {
    const { data: removeEmployeeResponse } = await UsersService.removeEmployee({
      employerId,
      employeeId,
    });

    if (removeEmployeeResponse.message === 'employee_removed') {
      let finalEmployeesInState = [];
      employees.forEach((employee) => {
        if (employee.id === employeeId) {
          finalEmployeesInState.push({ ...employee, isStillAnEmployee: false });
        } else {
          finalEmployeesInState.push({ ...employee });
        }
      });

      if (!includeNoLongerEmployees) {
        finalEmployeesInState = finalEmployeesInState.filter(
          (e) => e.isStillAnEmployee === true
        );
      }

      setEmployees(finalEmployeesInState);

      const finalEmployeesInCookies = [];
      user.employees.forEach((employee) => {
        if (employee.id === employeeId) {
          finalEmployeesInCookies.push({
            ...employee,
            isStillAnEmployee: false,
          });
        } else {
          finalEmployeesInCookies.push({ ...employee });
        }
      });

      // Update user data in cookies
      loginUpdate({
        ...user,
        employees: finalEmployeesInCookies,
      });

      alert.success('Employee removed!');
    } else {
      alert.error('Oops, something went wrong.');
    }
  };

  useEffect(() => {
    const getEmployees = async () => {
      const params = {};

      if (adminId) {
        params.adminId = adminId;
      } else if (employerId) {
        params.employerId = employerId;
      }
      params.startDate = startDate;
      setIsLoading(true);
      const { data: getEmployeesResponse } = await UsersService.getEmployees(
        params
      );

      if (getEmployeesResponse.employees) {
        let finalEmployees = getEmployeesResponse.employees;

        if (!includeNoLongerEmployees) {
          finalEmployees = finalEmployees.filter(
            (e) => e.isStillAnEmployee === true
          );
        }

        setEmployees(finalEmployees);
      }

      setIsLoading(false);
    };

    getEmployees();
  }, [startDate]);

  return {
    isLoading,
    employees,
    updateEmailAddress,
    updateIsAdmin,
    updateIsOvertime,
    updateExpectedHoursPerWeek,
    updatePublicHolidayMultiplier,
    updateWage,
    removeEmployee,
  };
};

export default useEmployees;
