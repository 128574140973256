import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import { TimesheetContext } from '../../../contexts';
import { leaveTypes } from '../../../globals';
import {
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
} from '../../../utils/datetime';
import { getHours } from '../../../utils/timesheets';
import { timesheetsToXeroSteps } from './constants';

import SelectTimesheetEmployeeModal from './SelectTimesheetEmployeeModal';
import SelectTenantModal from './SelectTenantModal';
import SelectEarningRatesModal from './SelectEarningRatesModal';
import SelectXeroEmployeeModal from './SelectXeroEmployeeModal';
import TimesheetsToXeroSuccessModal from './TimesheetsToXeroSuccessModal';

const TimesheetsToXeroModals = ({ isOpen, handleClose }) => {
  const cookies = new Cookies();
  const tokenSet = cookies.get('xeroTokenSet');

  const {
    startDate,
    endDate,
    employees: timesheetEmployees,
    timesheets: retrievedTimesheets,
  } = useContext(TimesheetContext);
  const [step, setStep] = useState(
    timesheetsToXeroSteps.SELECT_TIMESHEET_EMPLOYEE
  );
  const [employee, setEmployee] = useState(null);
  const [timesheets, setTimesheets] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [earningRates, setEarningRates] = useState(null);

  const filterTimesheetsByEmployee = (employeeId) =>
    retrievedTimesheets.filter((timesheet) => timesheet.userId === employeeId);
  const findEmployee = (employeeId) =>
    timesheetEmployees.find((emp) => emp.id === employeeId);

  const getNecessaryEarningRates = (timesheetsToFilter) => {
    const necessaryEarningRates = {
      publicHoliday: false,
      normal: true,
      overtime: false,
    };

    timesheetsToFilter.forEach(({ leaveType }) => {
      if (leaveType === leaveTypes.PUBLIC_HOLIDAY) {
        necessaryEarningRates.publicHoliday = true;
      }
    });

    if (employee.employerDetails.isOvertime) {
      necessaryEarningRates.overtime = true;
    }

    return necessaryEarningRates;
  };

  const formatTimesheets = (timesheetsToFilter) => {
    const formattedTimesheets = [];
    let hoursRemainingBeforeOvertime =
      employee.employerDetails.expectedHoursPerWeek;

    timesheetsToFilter.forEach(
      ({
        date,
        startTimeDetails = {
          startTime: undefined,
          actualTime: undefined,
        },
        endTimeDetails = {
          endTime: undefined,
          actualTime: undefined,
        },
        isLunchBreak,
        lunchBreakDuration,
        isTravelCharge,
        leaveType,
        publicHolidayMultiplier,
      }) => {
        const startTimeTimestamp = convertDateTimeToTimestamp(
          date,
          convertTimeTo24HourFormat(startTimeDetails.startTime)
        );
        const endTimeTimestamp = convertDateTimeToTimestamp(
          date,
          convertTimeTo24HourFormat(endTimeDetails.endTime)
        );

        let hours = Number(
          getHours(
            startTimeTimestamp,
            endTimeTimestamp,
            isLunchBreak,
              lunchBreakDuration,
            isTravelCharge,
            leaveType,
            publicHolidayMultiplier
          )
        );

        let earningsRateID = null;

        if (leaveType === leaveTypes.PUBLIC_HOLIDAY) {
          earningsRateID = earningRates.publicHoliday;
        } else if (leaveType === leaveTypes.NONE) {
          earningsRateID = earningRates.normal;
        }

        // Check if we need to update earningsRateID to overtime
        if (
          employee.employerDetails.isOvertime &&
          hoursRemainingBeforeOvertime !== 0
        ) {
          const difference = hoursRemainingBeforeOvertime - hours;

          // We check if hoursRemainingBeforeOvertime is not yet < 0.
          // If it is < 0, and there are still hours left, then it means
          // those remaining hours will have an earningsRateId of overtime already.
          // Otherwise, the earningsRateId will depend on the leaveType.
          if (difference > 0) {
            hoursRemainingBeforeOvertime -= hours;
          } else {
            // If it goes through here, it means that subtracting the
            // hoursRemainingBeforeOvertime by the current timesheet hours
            // will result in to negative (and not exactly 0). For example:
            // hoursRemainingBeforeOvertime = 6.67
            // hours = 33
            // 6.67 - 33 = -26.33
            // So, we need to create a separate timesheet entry for the 6.67
            // with earningsRateID depending on the leaveType, and 33 with
            // an earningsRateID of overtime.
            formattedTimesheets.push({
              date,
              earningsRateID,
              numberOfUnits: hoursRemainingBeforeOvertime,
            });

            hours = Math.abs(difference);

            // Then we set hoursRemainingBeforeOvertime to 0
            hoursRemainingBeforeOvertime = 0;
          }
        }

        // This is a continuation of the checking if we need to
        // update earningsRateID to overtime
        if (
          employee.employerDetails.isOvertime &&
          hoursRemainingBeforeOvertime === 0
        ) {
          earningsRateID = earningRates.overtime;
        }

        if (hours !== 0) {
          formattedTimesheets.push({
            date,
            earningsRateID,
            numberOfUnits: hours,
          });
        }
      }
    );

    return formattedTimesheets;
  };

  return (
    <>
      {step === timesheetsToXeroSteps.SELECT_TIMESHEET_EMPLOYEE && (
        <SelectTimesheetEmployeeModal
          isOpen={isOpen}
          handleClose={handleClose}
          timesheetEmployees={timesheetEmployees}
          handleSuccess={({ employeeId: retrievedEmployeeId }) => {
            setEmployee(findEmployee(retrievedEmployeeId));
            setTimesheets(filterTimesheetsByEmployee(retrievedEmployeeId));
            setStep(timesheetsToXeroSteps.SELECT_TENANT);
          }}
        />
      )}

      {step === timesheetsToXeroSteps.SELECT_TENANT && (
        <SelectTenantModal
          isOpen={isOpen}
          handleClose={handleClose}
          tokenSet={tokenSet}
          handleSuccess={({ tenantId: retrievedTenantId }) => {
            setTenantId(retrievedTenantId);
            setStep(timesheetsToXeroSteps.SELECT_EARNING_RATES);
          }}
        />
      )}

      {step === timesheetsToXeroSteps.SELECT_EARNING_RATES && (
        <SelectEarningRatesModal
          isOpen={isOpen}
          handleClose={handleClose}
          tokenSet={tokenSet}
          tenantId={tenantId}
          necessaryEarningRates={getNecessaryEarningRates(timesheets)}
          handleSuccess={({ earningRates: retrievedEarningRates }) => {
            setEarningRates(retrievedEarningRates);
            setStep(timesheetsToXeroSteps.SELECT_XERO_EMPLOYEE);
          }}
        />
      )}

      {step === timesheetsToXeroSteps.SELECT_XERO_EMPLOYEE && (
        <SelectXeroEmployeeModal
          isOpen={isOpen}
          handleClose={handleClose}
          tokenSet={tokenSet}
          tenantId={tenantId}
          startDate={startDate}
          endDate={endDate}
          timesheets={formatTimesheets(
            timesheets.filter(
              (t) =>
                t.leaveType !== leaveTypes.WITHOUT_PAY &&
                t.leaveType !== leaveTypes.ANNUAL &&
                t.leaveType !== leaveTypes.SICK
            )
          )}
          handleSuccess={() => {
            setStep(timesheetsToXeroSteps.TIMESHEETS_TO_XERO_SUCCESS);
          }}
        />
      )}

      {step === timesheetsToXeroSteps.TIMESHEETS_TO_XERO_SUCCESS && (
        <TimesheetsToXeroSuccessModal
          isOpen={isOpen}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

TimesheetsToXeroModals.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TimesheetsToXeroModals;
