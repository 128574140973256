import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Switch, Redirect, Route } from 'react-router-dom';
import styles from './styles.module.scss';

import {
  Dashboard,
  Timesheets,
  CreateTimesheet,
  Rosters,
  ViewProject,
  Settings,
  NoEmployer,
} from '../../screens/employee';
import { Button, Icon } from '../../components/elements';
import { ContactUsModal } from '../../components/modals';
import { Clients, CreateProject, UpdateProject, ViewClient } from "../../screens/common";
import { useWindowSize } from '../../hooks';
import { UserContext } from '../../contexts';

import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

/* eslint-disable react/jsx-props-no-spreading */
const EmployeeContainer = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const haveEmployer = user.employerDetails !== null;

  const { windowSize } = useWindowSize();

  let showSidebarByDefault = !haveEmployer;
  if (windowSize.width <= 991) {
    showSidebarByDefault = true;
  }

  const [isSidebarToggled, toggleSidebar] = useState(showSidebarByDefault);
  const [isContactUsShown, setIsContactUsShown] = useState(false);

  const handleToggleSidebar = (isToggled) => {
    toggleSidebar(isToggled);
  };

  useEffect(() => {
    toggleSidebar(!haveEmployer);
  }, [user]);

  useEffect(() => {
    handleToggleSidebar(true);
  }, [location]);

  return (
    <>
      <Navbar
        isSidebarToggled={isSidebarToggled}
        handleToggleSidebar={handleToggleSidebar}
        isSubscribed={haveEmployer}
      />

      <Sidebar isToggled={isSidebarToggled} />

      <div className={styles.EmployeeContainer}>
        <React.Suspense fallback={<div>Loading...</div>}>
          {!haveEmployer ? (
            <Switch>
              <Route
                path="/employee/no-employer"
                name="NoEmployer"
                exact
                render={(props) => <NoEmployer {...props} />}
              />

              <Redirect from="*" to="/employee/no-employer" />
            </Switch>
          ) : (
            <Switch>
              <Route
                path="/employee/dashboard"
                name="Dashboard"
                exact
                render={(props) => <Dashboard {...props} />}
              />

              <Route
                path="/employee/timesheets"
                name="Timesheets"
                exact
                render={(props) => <Timesheets {...props} />}
              />

              <Route
                path="/employee/timesheets/create"
                name="Create Timesheet"
                exact
                render={(props) => (
                  <CreateTimesheet
                    {...props}
                    pageTitle="Create Timesheet"
                    pages={[
                      {
                        name: 'Dashboard',
                        link: '/employer/dashboard',
                      },
                      {
                        name: 'Timesheets',
                        link: '/employee/timesheets',
                      },
                    ]}
                  />
                )}
              />

              <Route
                path="/employee/rosters"
                name="Rosters"
                exact
                render={(props) => <Rosters {...props} />}
              />

              <Route
                path="/employee/clients"
                name="Clients"
                exact
                render={(props) => <Clients {...props} />}
              />

              <Route
                path="/employee/clients/view/:clientId"
                name="View Client"
                exact
                render={(props) => <ViewClient {...props} />}
              />

              <Route
                path="/employee/clients/view/:clientId/projects/view/:projectId"
                name="View Project"
                exact
                render={(props) => <ViewProject {...props} />}
              />

              <Route
                path="/employee/clients/view/:clientId/projects/create"
                name="Create Project"
                exact
                render={(props) => <CreateProject {...props} />}
              />

              <Route
                    path="/employee/clients/view/:clientId/projects/update/:projectId"
                    name="Update Project"
                    exact
                    render={(props) => <UpdateProject {...props} />}
                  />

              <Route
                path="/employee/clients/view/:clientId/projects/view/:projectId/create"
                name="Create Project Timesheet"
                exact
                render={(props) => (
                  <CreateTimesheet
                    {...props}
                    pageTitle="Create Project Timesheet"
                    pages={[
                      {
                        name: 'Dashboard',
                        link: '/employee/dashboard',
                      },
                      {
                        name: 'Clients',
                        link: '/employee/clients',
                      },
                      {
                        name: 'View Client',
                        // eslint-disable-next-line react/prop-types
                        link: `/employee/clients/view/${props.match.params.clientId}`,
                      },
                      {
                        name: 'View Project',
                        // eslint-disable-next-line react/prop-types
                        link: `/employee/clients/view/${props.match.params.clientId}/projects/view/${props.match.params.projectId}`,
                      },
                    ]}
                  />
                )}
              />

              <Route
                path="/employee/settings/:activeTab"
                name="Settings"
                exact
                render={(props) => <Settings {...props} />}
              />

              <Redirect from="*" to="/employee/dashboard" />
            </Switch>
          )}
        </React.Suspense>
      </div>

      <Button
        className={styles.helpButton}
        onClick={() => setIsContactUsShown(true)}
      >
        <Icon icon="help" className={styles.helpButton_icon} />
      </Button>

      <ContactUsModal
        isOpen={isContactUsShown}
        handleClose={() => setIsContactUsShown(false)}
        title="Contact Us"
      />
    </>
  );
};

export default EmployeeContainer;
