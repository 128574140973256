import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/confirmation-codes`;

const ConfirmationCodesService = {
  create: (body) => axios.post(`${BASE_URL}/create`, body),
  use: (body) => axios.post(`${BASE_URL}/use`, body),
  resend: (body) => axios.post(`${BASE_URL}/resend`, body),
};

export default ConfirmationCodesService;
