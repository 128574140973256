import React, { useState, useContext } from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import {
  Breadcrumbs,
  EditableEmail,
  EditableNumber,
  EditableSelect,
  SweetAlert,
} from '../../../components';
import {
  ButtonLink,
  Icon,
  IconButton,
  Modal,
  Text,
} from '../../../components/elements';
import { buttonTypes, colorClasses, textTypes } from '../../../globals';
import { useEmployees, useWindowSize } from '../../../hooks';
import { UserContext } from '../../../contexts';
import Youtube from '../../../static/images/SocialMedia/youtube.png';
import NoResults from '../../../static/images/Misc/no-results.webp';

const Employees = () => {
  const { user, loginUpdate } = useContext(UserContext);
  const numberOfStillAnEmployee = user?.employees.filter(
    (e) => e.isStillAnEmployee === true
  ).length;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const {
    isLoading: isEmployeesLoading,
    employees,
    updateEmailAddress,
    updateIsAdmin,
    updateIsOvertime,
    updateExpectedHoursPerWeek,
    updatePublicHolidayMultiplier,
    updateWage,
    removeEmployee,
  } = useEmployees({
    employerId: user.id,
  });

  const { windowSize } = useWindowSize();

  return (
    <div className={styles.Employees}>
      <div className={styles.Employees_firstRow}>
        <Breadcrumbs
          pageTitle="Employees"
          pages={[
            {
              name: 'Dashboard',
              link: '/employer/dashboard',
            },
          ]}
        />

        <ButtonLink
          to="/employer/employees/add"
          type={buttonTypes.PRIMARY.GREEN}
          icon="add"
        >
          Add Employee
        </ButtonLink>
      </div>

      {isEmployeesLoading ? (
        <Preloader />
      ) : (
        <>
          {numberOfStillAnEmployee > 0 ? (
            <div className={styles.Employees_grid}>
              {/* Header of EmployeesGrid starts here */}
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Name
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Employee Code
                <a
                  className={styles.Employees_grid_helpIcon}
                  data-tip
                  data-for="employeeCodeTooltip"
                >
                  <Icon icon="help" />
                </a>
                <ReactTooltip
                  className={styles.Employees_grid_helpTooltip}
                  id="employeeCodeTooltip"
                  type="info"
                  effect="solid"
                >
                  <span>
                    This is the employee’s identifier, use this when adding this
                    employee to your account if you delete them.
                  </span>
                </ReactTooltip>
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Email Address
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Admin
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Overtime
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Expected Hours per Week
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Public Holiday Multiplier
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header
                )}
              >
                Wage
              </div>
              <div
                className={cn(
                  styles.Employees_grid_column,
                  styles.Employees_grid_header,
                  styles.Employees_grid_header_action
                )}
              >
                Action
              </div>
              {/* Header of EmployeesGrid ends here */}

              {/* Body of EmployeesGrid starts here */}
              {employees.map(
                (
                  { id, fullName, employeeCode, emailAddress, employerDetails },
                  i
                ) => (
                  <div className={styles.Employees_keyWrapper} key={id}>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7} / 1 / ${
                                (i + 1) * 7 + 7
                              } / 1`,
                            }
                          : undefined
                      }
                    >
                      {fullName}
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7} / 2 / ${
                                (i + 1) * 7
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {employeeCode}
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 1} / 2 / ${
                                (i + 1) * 7 + 1
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      <EditableEmail
                        initialValue={emailAddress}
                        onSave={async (val) => {
                          const isSuccessfulSave = await updateEmailAddress(
                            id,
                            val
                          );

                          return isSuccessfulSave;
                        }}
                      />
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 2} / 2 / ${
                                (i + 1) * 7 + 2
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      <EditableSelect
                        options={[
                          {
                            label: 'Yes',
                            value: 'Yes',
                          },
                          {
                            label: 'No',
                            value: 'No',
                          },
                        ]}
                        initialValue={employerDetails.isAdmin ? 'Yes' : 'No'}
                        onSave={(val) => updateIsAdmin(id, val === 'Yes')}
                      />
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 3} / 2 / ${
                                (i + 1) * 7 + 3
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      <EditableSelect
                        options={[
                          {
                            label: 'Yes',
                            value: 'Yes',
                          },
                          {
                            label: 'No',
                            value: 'No',
                          },
                        ]}
                        initialValue={employerDetails.isOvertime ? 'Yes' : 'No'}
                        onSave={(val) => updateIsOvertime(id, val === 'Yes')}
                      />
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 4} / 2 / ${
                                (i + 1) * 7 + 4
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      <EditableNumber
                        initialValue={
                          employerDetails?.expectedHoursPerWeek
                            ? employerDetails.expectedHoursPerWeek.toString()
                            : 0
                        }
                        onSave={(val) =>
                          updateExpectedHoursPerWeek(id, Number(val))
                        }
                      />
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 5} / 2 / ${
                                (i + 1) * 7 + 5
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      <EditableNumber
                        initialValue={
                          employerDetails?.publicHolidayMultiplier
                            ? employerDetails.publicHolidayMultiplier.toString()
                            : 0
                        }
                        onSave={(val) =>
                          updatePublicHolidayMultiplier(id, Number(val))
                        }
                      />
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 6} / 2 / ${
                                (i + 1) * 7 + 6
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      <EditableNumber
                        initialValue={
                          employerDetails?.wage
                            ? employerDetails.wage.toString()
                            : 0
                        }
                        onSave={(val) => updateWage(id, Number(val))}
                        minLength={0}
                        maxLength={999}
                      />
                    </div>
                    <div
                      className={styles.Employees_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7} / 3 / ${
                                (i + 1) * 7 + 7
                              } / 3`,
                            }
                          : undefined
                      }
                    >
                      <IconButton
                        className={styles.Employees_grid_deleteButton}
                        icon="highlight_off"
                        onClick={() => setSelectedEmployeeId(id)}
                      />
                    </div>
                  </div>
                )
              )}

              <SweetAlert
                show={selectedEmployeeId !== null}
                title="Are you sure?"
                onConfirm={async () => {
                  await removeEmployee(selectedEmployeeId);
                  setSelectedEmployeeId(null);
                }}
                onCancel={() => setSelectedEmployeeId(null)}
              >
                This will remove the employee from your account, to add them
                back you will need to import them with their employee code.
              </SweetAlert>
              {/* Body of EmployeesGrid ends here */}
            </div>
          ) : (
            <div className={styles.Employees_noEmployees}>
              <img
                src={NoResults}
                alt="TimeSheet No Employees"
                className={styles.Employees_noEmployees_image}
              />

              <Text
                colorClass={colorClasses.RED['400']}
                type={textTypes.HEADING.XS}
              >
                YOU CURRENTLY DON'T HAVE EMPLOYEES.
              </Text>

              <Text
                type={textTypes.HEADING.XXS}
                className={styles.Employees_noEmployees_withMargin}
              >
                Add one by clicking the button at the upper side of the screen.
              </Text>
            </div>
          )}
        </>
      )}

      <Modal
        isOpen={user?.isNewlySignedUp}
        handleClose={() => loginUpdate({ ...user, isNewlySignedUp: false })}
        title="Thanks for signing up!"
      >
        <Text type={textTypes.BODY.LG} className={styles.Employees_welcomeText}>
          All you need to do now is register your employees.
          <br />
          <br />
          They will get an email with a link to the application, and once they
          download it, they will be free to use the software as they please.
          <br />
          <br />
          If you'd like to learn how to use our software, check out our
          tutorials here:
          <br />
          <br />
          <a
            className={styles.Employees_welcomeLink}
            href="https://www.youtube.com/channel/UC9r9rDSONSEAHPMA9Mokj6w"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Youtube}
              className={styles.Employees_welcomeImage}
              alt="Youtube Icon"
            />
          </a>
          <br />
          <br />
          Welcome to TimeSheet!
        </Text>
      </Modal>
    </div>
  );
};

export default Employees;
