import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

import { Label } from '..';

const Checkbox = ({ name, label, checked, className, onChange, disabled }) => (
  <div
    className={cn(className, styles.Checkbox, {
      [styles.Checkbox___disabled]: disabled,
    })}
  >
    <input
      data-test="checkbox"
      type="checkbox"
      className={styles.Checkbox_input}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      name={name}
      id={name}
    />
    <Label
      className={styles.Checkbox_label}
      htmlFor={name}
      disabled={disabled}
      id={`${name}-label`}
    >
      {label}
    </Label>
  </div>
);

Checkbox.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Checkbox;
