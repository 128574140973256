import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import { Button, Card, IconButton, Text } from '../../../components/elements';
import {
  buttonTypes,
  colorClasses,
  iconButtonTypes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { UsersService } from '../../../services';

const NoEmployer = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [isGenerating, setIsGenerating] = useState(false);
  const [employeeCode, setEmployeeCode] = useState(user.employeeCode);
  const [isSwitching, setIsSwitching] = useState(false);

  return (
    <div className={styles.NoEmployer}>
      <Card className={styles.NoEmployer_card}>
        <Text type={textTypes.HEADING.XS}>
          Send your employee code to an employer to start using TimeSheet
        </Text>

        <div className={styles.NoEmployer_codeWrapper}>
          <div
            className={cn(styles.NoEmployer_codeWrapper_code, {
              [styles.NoEmployer_codeWrapper_code___generating]: isGenerating,
            })}
          >
            <Text
              type={textTypes.HEADING.XXS}
              colorClass={colorClasses.NEUTRAL[0]}
            >
              {employeeCode}
            </Text>
          </div>

          <IconButton
            icon="refresh"
            type={iconButtonTypes.SOLID.LG}
            iconClassName={styles.NoEmployer_codeWrapper_refreshIcon}
            onClick={async () => {
              setIsGenerating(true);

              const { data: generateNewEmployeeCodeResponse } =
                await UsersService.generateNewEmployeeCode({
                  employeeId: user.id,
                });

              if (generateNewEmployeeCodeResponse.newEmployeeCode) {
                loginUpdate({
                  ...user,
                  employeeCode: generateNewEmployeeCodeResponse.newEmployeeCode,
                });

                setEmployeeCode(
                  generateNewEmployeeCodeResponse.newEmployeeCode
                );
              }

              setIsGenerating(false);
            }}
            disabled={isGenerating}
          />
        </div>

        <Text type={textTypes.HEADING.XS} className={styles.NoEmployer_orText}>
          OR
        </Text>

        <Button
          type={buttonTypes.PRIMARY.GREEN}
          icon="switch_account"
          className={styles.NoEmployer_switchButton}
          disabled={isSwitching}
          onClick={async () => {
            setIsSwitching(true);

            const { status: switchToContractorResponseCode, data } =
              await UsersService.switchToContractor({
                employeeId: user.id,
                isSubscribed: user?.isSubscribed,
              });

            const switchToContractorCallbacks = {
              switched: () => {
                alert.success(
                  'Switched to Contractor Successfully! Redirecting...'
                );

                const userCopy = JSON.parse(JSON.stringify(user));
                delete userCopy.employeeCode;
                delete userCopy.employerDetails;

                setTimeout(() => {
                  loginUpdate({
                    ...userCopy,
                    ...data.fieldsToSet,
                    userType: 'Contractor',
                  });
                }, 3000);
              },
              invalidFields: () => alert.error('Invalid fields.'),
              internalError: () => alert.error('Oops, something went wrong.'),
            };

            switch (switchToContractorResponseCode) {
              case 200:
                switchToContractorCallbacks.switched();
                break;
              case 400:
                switchToContractorCallbacks.invalidFields();
                break;
              case 500:
                switchToContractorCallbacks.internalError();
                break;
              default:
                break;
            }
          }}
        >
          Switch to Contractor Account
        </Button>
      </Card>
    </div>
  );
};

export default NoEmployer;
