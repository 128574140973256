import React from 'react';
import PropTypes from 'prop-types';

import { buttonTypes } from '../../../../globals';
import { Modal, Text } from '../../../elements';

const SignInOrOutFailureModal = ({ isOpen, handleClose }) => (
  <Modal
    isOpen={isOpen}
    handleClose={handleClose}
    title="Failure"
    actions={[
      {
        text: 'Finish',
        type: buttonTypes.PRIMARY.GREEN,
        onClick: handleClose,
      },
    ]}
  >
    <Text>Oops, something went wrong. Please contact an administrator.</Text>
  </Modal>
);

SignInOrOutFailureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SignInOrOutFailureModal;
