import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';
import {
  Card,
  ControlledInput,
  Button,
  Spinner,
  Text,
} from '../../../components/elements';
import styles from './styles.module.scss';
import {
  buttonKinds,
  colorNames,
  spinnerSizes,
  textTypes,
  userTypes,
} from '../../../globals';
import { isValidEmail } from '../../../utils/string';
import { UsersService } from '../../../services';
import Logo from '../../../static/images/Logo/logo.png';

const InvitationRequest = ({ userType }) => {
  const alert = useAlert();
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  if (!userType) {
    window.location.href = '/signup';
    return;
  }

  const validate = (values) => {
    const errors = {};

    if (!values.fullName) {
      errors.fullName = 'This field is required.';
    } else if (values.fullName.length > 60) {
      errors.fullName = 'The maximum length of this field is 60 characters.';
    }

    if (!values.emailAddress) {
      errors.emailAddress = 'This field is required.';
    } else if (!isValidEmail(values.emailAddress)) {
      errors.emailAddress = 'This must be a valid email address.';
    }

    if (!values.companyName) {
      errors.companyName = 'This field is required.';
    } else if (values.companyName.length > 50) {
      errors.companyName = 'The maximum length of this field is 50 characters.';
    }

    if (!values.companyEmailAddress) {
      errors.companyEmailAddress = 'This field is required.';
    } else if (!isValidEmail(values.emailAddress)) {
      errors.companyEmailAddress = 'This must be a valid email address.';
    }

    return errors;
  };

  return (
    <Card className={styles.InvitationRequest}>
      <div className={styles.InvitationRequest_header}>
        <img
          src={Logo}
          className={styles.InvitationRequest_header_logo}
          alt="TimeSheet Logo"
        />

        <div className={styles.InvitationRequest_header_headingTextWrapper}>
          <Text
            type={textTypes.SPAN.MD}
            className={
              styles.InvitationRequest_header_headingTextWrapper_headingText
            }
          >
            Send an Invitation Request to the Company
          </Text>
        </div>
      </div>

      <div className={styles.InvitationRequest_content}>
        <Formik
          initialValues={{
            fullName: '',
            emailAddress: '',
            companyName: '',
            companyEmailAddress: '',
          }}
          onSubmit={async (values, { setErrors, setFieldValue }) => {
            const currentFormValues = {
              fullName: values.fullName,
              emailAddress: values.emailAddress,
              companyName: values.companyName,
              companyEmailAddress: values.companyEmailAddress,
            };

            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            setIsSendingRequest(true);

            // Check if user exists with the inputted emailAddress
            const { data: userExistsResponse } = await UsersService.userExists(
              currentFormValues.emailAddress
            );

            if (userExistsResponse.message === 'user_exists') {
              setErrors({
                emailAddress: 'This email address has been used already.',
              });
              setIsSendingRequest(false);
              return;
            }

            // If the emailAddress is valid, we proceed with
            // the sending of invitation request
            const { status: sendRequestToCompanyResponseCode } =
              await UsersService.sendRequestToCompany(currentFormValues);

            const sendRequestToCompanyCallbacks = {
              sent: () => {
                alert.success(
                  "Invitation request sent! You will receive an email once you've been added."
                );
              },
              invalidFields: () => alert.error('Invalid fields.'),
              internalError: () => alert.error('Oops, something went wrong.'),
            };

            switch (sendRequestToCompanyResponseCode) {
              case 200:
                sendRequestToCompanyCallbacks.sent();

                // Reset the form to its initial state
                setFieldValue('fullName', '');
                setFieldValue('emailAddress', '');
                setFieldValue('companyName', '');
                setFieldValue('companyEmailAddress', '');
                break;
              case 400:
                sendRequestToCompanyCallbacks.invalidFields();
                break;
              case 500:
                sendRequestToCompanyCallbacks.internalError();
                break;
              default:
                break;
            }

            setIsSendingRequest(false);
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <ControlledInput
                placeholder="Full Name*"
                name="fullName"
                icon="contact_mail"
                value={values.fullName}
                error={errors.fullName}
                onChange={(e) => setFieldValue('fullName', e.target.value)}
              />

              <ControlledInput
                className={styles.InvitationRequest_content_withMargin}
                placeholder="Email Address*"
                name="emailAddress"
                icon="email"
                value={values.emailAddress}
                error={errors.emailAddress}
                onChange={(e) => setFieldValue('emailAddress', e.target.value)}
              />

              <ControlledInput
                className={styles.InvitationRequest_content_withMargin}
                placeholder="Company Name*"
                name="companyName"
                icon="domain"
                value={values.companyName}
                error={errors.companyName}
                onChange={(e) => setFieldValue('companyName', e.target.value)}
              />

              <ControlledInput
                className={styles.InvitationRequest_content_withMargin}
                placeholder="Company Email Address*"
                name="companyEmailAddress"
                icon="email"
                value={values.companyEmailAddress}
                error={errors.companyEmailAddress}
                onChange={(e) =>
                  setFieldValue('companyEmailAddress', e.target.value)
                }
              />

              <div className={styles.InvitationRequest_content_buttonGroup}>
                <Button
                  kind={buttonKinds.SUBMIT}
                  icon="send"
                  disabled={isSendingRequest}
                  onClick={() => {}}
                >
                  <span
                    className={
                      styles.InvitationRequest_content_buttonGroup_buttonText
                    }
                  >
                    Send Request
                    {isSendingRequest && (
                      <Spinner
                        size={spinnerSizes.XS}
                        colorName={colorNames.WHITE}
                        className={
                          styles.InvitationRequest_content_buttonGroup_spinner
                        }
                      />
                    )}
                  </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <div className={styles.InvitationRequest_footer}>
        <Text>
          Already have an account?{' '}
          <Link
            to="/login"
            className={styles.InvitationRequest_footer_signIn}
            onClick={isSendingRequest ? (e) => e.preventDefault() : () => {}}
          >
            Sign In
          </Link>
        </Text>
      </div>
    </Card>
  );
};

InvitationRequest.propTypes = {
  userType: PropTypes.oneOf([userTypes.EMPLOYEE]).isRequired,
};

export default InvitationRequest;
