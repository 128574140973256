import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  Grid,
  Spinner,
  Text,
} from '../../../components/elements';
import { useAddEmployee } from '../../../hooks';
import {
  buttonKinds,
  colorClasses,
  colorNames,
  spinnerSizes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { UsersService } from '../../../services';
import { isValidEmail } from '../../../utils/string';

const validateCreate = (values) => {
  const errors = {};

  if (!values.fullName) {
    errors.fullName = 'This field is required.';
  }

  if (!values.emailAddress) {
    errors.emailAddress = 'This field is required.';
  } else if (!isValidEmail(values.emailAddress)) {
    errors.emailAddress = 'This must be a valid email address.';
  }

  return errors;
};

const validateImport = (values) => {
  const errors = {};

  if (!values.employeeCode) {
    errors.employeeCode = 'This field is required.';
  }

  return errors;
};

const AddEmployee = () => {
  const { user } = useContext(UserContext);
  const [numberOfStillAnEmployee, setNumberOfStillAnEmployee] = useState(
    user?.employees.filter((e) => e.isStillAnEmployee === true).length
  );
  const {
    isCreating: isEmployeeCreating,
    createEmployee,
    isImporting: isEmployeeImporting,
    importEmployee,
  } = useAddEmployee();

  useEffect(() => {
    setNumberOfStillAnEmployee(
      user?.employees.filter((e) => e.isStillAnEmployee === true).length
    );
  }, [user]);

  return (
    <div className={styles.AddEmployee}>
      <Breadcrumbs
        pageTitle="Add Employee"
        pages={[
          {
            name: 'Dashboard',
            link: '/employer/dashboard',
          },
          {
            name: 'Employees',
            link: '/employer/employees',
          },
        ]}
      />

      {numberOfStillAnEmployee >= user?.subscriptionPlanQuantity ? (
        <div className={styles.AddEmployee_alert}>
          <Text
            className={styles.AddEmployee_alert_text}
            type={textTypes.HEADING.XXXS}
            colorClass={colorClasses.NEUTRAL[0]}
          >
            Oh, snap! You have added the maximum number of employees allowed for
            your account based on your subscription plan, which is{' '}
            {user?.subscriptionPlanQuantity} employee
            {user?.subscriptionPlanQuantity > 1 ? 's' : ''}. If you want to
            increase the maximum number of employees, click{' '}
            <Link
              to="/employer/subscription"
              className={styles.AddEmployee_alert_link}
            >
              here
            </Link>
            . Thank you!
          </Text>
        </div>
      ) : (
        <Grid>
          <Card className={styles.AddEmployee_card}>
            <Text type={textTypes.HEADING.XXS}>
              Create New Account for an Employee
            </Text>

            <Text className={styles.AddEmployee_card_smallNote}>
              <b>Note:</b> The employee that you will create in this section
              will have their log in details emailed to them. Their default
              password is <b>12345678</b>. They can change this in the{' '}
              <b>Forgot Password</b> section of the TimeSheet app or website.
            </Text>

            <Formik
              initialValues={{
                fullName: '',
                emailAddress: '',
              }}
              onSubmit={async (values, { setErrors, setFieldValue }) => {
                const errors = validateCreate(values);
                if (!isEmpty(errors)) {
                  setErrors(errors);
                  return;
                }

                // Check if inputted emailAddress already exist
                const { data: userExistsResponse } =
                  await UsersService.userExists(values.emailAddress);
                if (userExistsResponse.message === 'user_exists') {
                  setErrors({
                    emailAddress: 'This email address has been used already.',
                  });
                  return;
                }

                const { message } = await createEmployee(
                  values.fullName,
                  values.emailAddress
                );

                if (message === 'employee_created') {
                  // Reset the form to its initial state
                  setFieldValue('fullName', '');
                  setFieldValue('emailAddress', '');
                }
              }}
            >
              {({ errors, values, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <ControlledInput
                    className={styles.AddEmployee_withMarginBottom}
                    name="fullName"
                    placeholder="Full Name"
                    icon="contact_mail"
                    value={values.fullName}
                    error={errors.fullName}
                    onChange={(e) => setFieldValue('fullName', e.target.value)}
                  />

                  <ControlledInput
                    name="emailAddress"
                    placeholder="Email Address"
                    icon="email"
                    value={values.emailAddress}
                    error={errors.emailAddress}
                    onChange={(e) =>
                      setFieldValue('emailAddress', e.target.value)
                    }
                  />

                  <div className={styles.AddEmployee_buttonGroup}>
                    <Button
                      className={styles.AddEmployee_buttonGroup_button}
                      kind={buttonKinds.SUBMIT}
                      icon="add"
                      disabled={isEmployeeCreating}
                      onClick={() => {}}
                    >
                      <span
                        className={styles.AddEmployee_buttonGroup_buttonText}
                      >
                        Create Employee
                        {isEmployeeCreating && (
                          <Spinner
                            size={spinnerSizes.XS}
                            colorName={colorNames.WHITE}
                            className={styles.AddEmployee_buttonGroup_spinner}
                          />
                        )}
                      </span>
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Card>

          <div>
            <Card className={styles.AddEmployee_card}>
              <Text
                type={textTypes.HEADING.XXS}
                className={styles.AddEmployee_card_title}
              >
                Import Employee by their Employee Code
              </Text>

              <Formik
                initialValues={{
                  employeeCode: '',
                }}
                onSubmit={async (values, { setErrors, setFieldValue }) => {
                  const errors = validateImport(values);
                  if (!isEmpty(errors)) {
                    setErrors(errors);
                    return;
                  }

                  const { message } = await importEmployee(values.employeeCode);

                  if (message === 'employee_imported') {
                    // Reset the form to its initial state
                    setFieldValue('employeeCode', '');
                  }
                }}
              >
                {({ errors, values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <ControlledInput
                      name="employeeCode"
                      placeholder="Employee Code"
                      icon="subtitles"
                      value={values.employeeCode}
                      error={errors.employeeCode}
                      onChange={(e) =>
                        setFieldValue('employeeCode', e.target.value)
                      }
                    />

                    <div className={styles.AddEmployee_buttonGroup}>
                      <Button
                        className={styles.AddEmployee_buttonGroup_button}
                        kind={buttonKinds.SUBMIT}
                        icon="add"
                        disabled={isEmployeeImporting}
                        onClick={() => {}}
                      >
                        <span
                          className={styles.AddEmployee_buttonGroup_buttonText}
                        >
                          Import Employee
                          {isEmployeeImporting && (
                            <Spinner
                              size={spinnerSizes.XS}
                              colorName={colorNames.WHITE}
                              className={styles.AddEmployee_buttonGroup_spinner}
                            />
                          )}
                        </span>
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </Card>
          </div>
        </Grid>
      )}
    </div>
  );
};

export default AddEmployee;
