import { createContext } from 'react';

const RosterContext = createContext({
  dayIds: [],
  isEmployeesLoading: false,
  employees: [],
  isRostersLoading: false,
  rosters: [],
  isRolesLoading: false,
  getEmployeeFullName: () => {},
  setSelectedRoleId: () => {},
  setSelectedRosterId: () => {},
  setViewRosterValues: () => {},
});

export default RosterContext;
