import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import dateFormat from 'dateformat';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  ControlledTextArea,
  DatePicker,
  Spinner,
} from '../../../components/elements';
import { isValidDecimal } from '../../../utils/string';
import { buttonKinds, colorNames, spinnerSizes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useCreateProject } from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'This field is required.';
  }

  if (values.expectedHours && !isValidDecimal(values.expectedHours)) {
    errors.expectedHours = 'Only numbers/decimals up to 2 places are allowed.';
  }

  return errors;
};

const CreateProject = () => {
  const { user } = useContext(UserContext);
  const { clientId } = useParams();
  const { isCreating: isProjectCreating, createProject } = useCreateProject();
  const userTypeInsensitive = user.userType.toLowerCase();

  return (
    <div className={styles.CreateProject}>
      <Breadcrumbs
        pageTitle="Create Project"
        pages={[
          {
            name: 'Dashboard',
            link: `/${userTypeInsensitive}/dashboard`,
          },
          {
            name: 'Clients',
            link: `/${userTypeInsensitive}/clients`,
          },
          {
            name: 'View Client',
            link: `/${userTypeInsensitive}/clients/view/${clientId}`,
          },
        ]}
      />

      <Card className={styles.CreateProject_card}>
        <Formik
          initialValues={{
            name: '',
            startDate: '',
            projectedFinishDate: '',
            address: '',
            expectedHours: '',
            notes: '',
          }}
          onSubmit={async (values, { setErrors, setFieldValue }) => {
            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            // Create project object
            const project = {
              clientId,
              name: values.name,
              startDate: values.startDate
                ? dateFormat(values.startDate, 'yyyy-mm-dd')
                : undefined,
              projectedFinishDate: values.projectedFinishDate
                ? dateFormat(values.projectedFinishDate, 'yyyy-mm-dd')
                : undefined,
              address: values.address ? values.address : undefined,
              expectedHours: values.expectedHours
                ? values.expectedHours
                : undefined,
              notes: values.notes ? values.notes : undefined,
              isImportant: values.isImportant ? values.isImportant : false,
            };

            const { message } = await createProject(project);

            if (message === 'project_created') {
              // Reset the form to its initial state
              setFieldValue('name', '');
              setFieldValue('startDate', '');
              setFieldValue('projectedFinishDate', '');
              setFieldValue('address', '');
              setFieldValue('expectedHours', '');
              setFieldValue('notes', '');
              setFieldValue('isImportant', false);
            }
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <ControlledInput
                name="name"
                placeholder="Name*"
                icon="person"
                value={values.name}
                error={errors.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />

              <DatePicker
                className={styles.CreateProject_withMargin}
                dateFormat="dd/MM/yyyy"
                selected={values.startDate}
                placeholder="Start Date"
                name="startDate"
                icon="today"
                onChange={(startDate) => {
                  setFieldValue('startDate', startDate);
                }}
              />

              <DatePicker
                className={styles.CreateProject_withMargin}
                dateFormat="dd/MM/yyyy"
                selected={values.projectedFinishDate}
                placeholder="Projected Finish Date"
                name="projectedFinishDate"
                icon="today"
                onChange={(projectedFinishDate) => {
                  setFieldValue('projectedFinishDate', projectedFinishDate);
                }}
              />

              <ControlledInput
                className={styles.CreateProject_withMargin}
                name="address"
                placeholder="Address"
                icon="home"
                value={values.address}
                error={errors.address}
                onChange={(e) => setFieldValue('address', e.target.value)}
              />

              <ControlledInput
                className={styles.CreateProject_withMargin}
                name="expectedHours"
                placeholder="Expected Hours"
                icon="schedule"
                value={values.expectedHours}
                error={errors.expectedHours}
                onChange={(e) => setFieldValue('expectedHours', e.target.value)}
              />

              <ControlledTextArea
                className={styles.CreateProject_withMargin}
                name="notes"
                placeholder="Notes"
                icon="description"
                value={values.notes}
                error={errors.notes}
                onChange={(e) => setFieldValue('notes', e.target.value)}
              />

              <div style={{marginTop: '8%'}}>
                <input type="checkbox"
                       name='isImportant'
                       value={values.isImportant}
                       checked={ values.isImportant }
                       onChange={ (e) => { setFieldValue('isImportant', e.target.checked) } }
                /> Important
              </div>

              <div className={styles.CreateProject_buttonGroup}>
                <Button
                  className={styles.CreateProject_buttonGroup_button}
                  kind={buttonKinds.SUBMIT}
                  icon="add"
                  disabled={isProjectCreating}
                  onClick={() => {}}
                >
                  <span className={styles.CreateProject_buttonGroup_buttonText}>
                    Create Project
                    {isProjectCreating && (
                      <Spinner
                        size={spinnerSizes.XS}
                        colorName={colorNames.WHITE}
                        className={styles.CreateProject_buttonGroup_spinner}
                      />
                    )}
                  </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default CreateProject;
