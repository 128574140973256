import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { Text } from '../elements';
import { textTypes, colorClasses } from '../../globals';
import {
  getDayShortNameFromDayId,
  getDayNameFromDayId,
} from '../../utils/datetime';
import ScheduleCard from './ScheduleCard';

const findSchedule = (schedules, dayValue) =>
  schedules.find((schedule) => schedule.day === dayValue);

const findError = (scheduleErrors, dayValue) =>
  scheduleErrors?.find((scheduleError) => scheduleError.day === dayValue);

const Schedule = ({ dayIds, values, errors, setFieldValue, disabled }) => (
  <div className={styles.Schedule}>
    {dayIds.map((dayId) => {
      const dayObject = {
        value: dayId,
        shortName: getDayShortNameFromDayId(dayId),
        name: getDayNameFromDayId(dayId),
      };

      return (
        <ScheduleCard
          day={dayObject}
          schedule={findSchedule(values.schedules, dayObject.value)}
          error={findError(errors.schedules, dayObject.value)}
          schedules={values.schedules}
          setFieldValue={setFieldValue}
          disabled={disabled}
          key={dayId}
        />
      );
    })}

    {errors.schedulesOverall && (
      <Text
        className={styles.Schedule_error}
        type={textTypes.BODY.XS}
        colorClass={colorClasses.RED['400']}
      >
        {errors.schedulesOverall}
      </Text>
    )}
  </div>
);

Schedule.defaultProps = {
  disabled: false,
};

Schedule.propTypes = {
  dayIds: PropTypes.array.isRequired,
  values: PropTypes.shape({
    schedules: PropTypes.arrayOf(
      PropTypes.shape({
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        // day 0 - 6 (Sunday - Saturday)
        day: PropTypes.number,
      })
    ),
  }).isRequired,
  errors: PropTypes.shape({
    // an error message for the entire schedules input
    schedulesOverall: PropTypes.string,
    schedules: PropTypes.arrayOf(
      PropTypes.shape({
        // day 0 - 6 (Sunday - Saturday)
        day: PropTypes.number,
        // the error message for the start time
        startTime: PropTypes.string,
        // the error message for the end time
        endTime: PropTypes.string,
      })
    ),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Schedule;
