import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { Card, Text, RadioGroup } from '../../../../components/elements';
import { textTypes, radioTypes, userTypes } from '../../../../globals';
import Logo from '../../../../static/images/Logo/logo.png';

const AccountType = ({ userType, setUserType }) => {
  // Make sure to set it to null by default
  useEffect(() => {
    setUserType(null);
  }, []);

  return (
    <Card className={styles.AccountType}>
      <div className={styles.AccountType_header}>
        <img
          src={Logo}
          className={styles.AccountType_header_logo}
          alt="TimeSheet Logo"
        />

        <div className={styles.AccountType_header_headingTextWrapper}>
          <Text
            type={textTypes.SPAN.MD}
            className={styles.AccountType_header_headingTextWrapper_headingText}
          >
            Select Account Type to Use
          </Text>
        </div>
      </div>

      <div className={styles.AccountType_content}>
        <RadioGroup
          className={styles.AccountType_content_radioGroup}
          innerClassName={styles.AccountType_content_radioGroup_inner}
          options={[
            {
              id: 'userEmployer',
              value: userTypes.EMPLOYER,
              label: 'Employer',
              name: userTypes.EMPLOYER,
              onChange: () => setUserType(userTypes.EMPLOYER),
              icon: 'domain',
            },
            {
              id: 'userEmployee',
              value: userTypes.EMPLOYEE,
              label: 'Employee',
              name: userTypes.EMPLOYEE,
              onChange: () => setUserType(userTypes.EMPLOYEE),
              icon: 'badge',
            },
            {
              id: 'userContractor',
              value: userTypes.CONTRACTOR,
              label: 'Contractor',
              name: userTypes.CONTRACTOR,
              onChange: () => setUserType(userTypes.CONTRACTOR),
              icon: 'person',
            },
          ]}
          checked={userType}
          type={radioTypes.LARGE}
        />
      </div>
    </Card>
  );
};

AccountType.defaultProps = {
  userType: null,
};

AccountType.propTypes = {
  userType: PropTypes.oneOf([
    userTypes.EMPLOYER,
    userTypes.EMPLOYEE,
    userTypes.CONTRACTOR,
  ]),
  setUserType: PropTypes.func.isRequired,
};

export default AccountType;
