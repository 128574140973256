import React from 'react';
import styles from './styles.module.scss';

import { Card, Shine } from '../../../../../components/elements';

const Preloader = () => (
  <Card className={styles.Preloader}>
    <Shine className={styles.Preloader_shine} />
  </Card>
);

export default Preloader;
