import React, { useState, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './styles.module.scss';

import { EditableTime, EditableSelect, EditableTextarea } from '../..';
import { Grid, Icon, IconButton, Text } from '../../elements';
import { UserContext, TimesheetContext } from '../../../contexts';
import { colorClasses, textTypes, leaveTypes } from '../../../globals';
import {
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import { getHours } from '../../../utils/timesheets';

const TimesheetCarousel = ({
  totalHours,
  timesheetsForDay,
  totalTimesheets,
}) => {
  const { user } = useContext(UserContext);
  const { updateTimesheet, deleteTimesheet } = useContext(TimesheetContext);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  

  return (
    <div className={styles.TimesheetCarousel}>
      <Carousel
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={currentEntryIndex}
        width={135}
      >
        {timesheetsForDay.map(
          ({
            timesheetId,
            userId,
            date,
            startTimeDetails = {
              startTime: undefined,
              actualTime: undefined,
              location: undefined,
            },
            endTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
              location: undefined,
            },
            isLunchBreak,
            lunchBreakDuration,
            leaveType,
            publicHolidayMultiplier,
            notes,
            clientId,
            clientName,
            projectId,
            projectName,
            isBillable,
            costs,
            wage,
          }) => {
            const startTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(startTimeDetails.startTime)
            );
            const endTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(endTimeDetails.endTime)
            );
            
            const hours = Number(
              getHours(
                startTimeTimestamp,
                endTimeTimestamp,
                isLunchBreak,
                lunchBreakDuration,
                // isTravelCharge,
                // leaveType,
                // publicHolidayMultiplier
              )
            );

            const isProjectTimesheet = projectId && clientId;

            const totalCost = costs
              ? Number(costs?.reduce((a, b) => a + b.price, 0))
              : 0;
            return (
              <div className={styles.TimesheetCarousel_entry} key={timesheetId}>
                <div className={styles.TimesheetCarousel_actions}>
                  <IconButton
                    icon="delete"
                    className={styles.TimesheetCarousel_actions_action}
                    onClick={() => {
                      deleteTimesheet(timesheetId);
                      setCurrentEntryIndex(0);
                    }}
                  />
                </div>

                {!window.location.href.includes('projects') && (
                  <>
                    <div className={styles.TimesheetCarousel_informationGroup}>
                      <Text className={styles.TimesheetCarousel_boldText}>
                        CLIENT:
                      </Text>
                      <Text
                        className={cn(styles.TimesheetCarousel_projectName, {
                          [styles.TimesheetCarousel_projectName___isProjectTimesheet]:
                            isProjectTimesheet,
                        })}
                        type={textTypes.BODY.MD}
                      >
                        {isProjectTimesheet ? clientName : 'None'}
                      </Text>
                    </div>

                    <div className={styles.TimesheetCarousel_informationGroup}>
                      <Text className={styles.TimesheetCarousel_boldText}>
                        PROJECT:
                      </Text>
                      <Text
                        className={cn(styles.TimesheetCarousel_projectName, {
                          [styles.TimesheetCarousel_projectName___isProjectTimesheet]:
                            isProjectTimesheet,
                        })}
                        type={textTypes.BODY.MD}
                      >
                        {isProjectTimesheet ? projectName : 'None'}
                      </Text>
                    </div>

                    <div className={styles.TimesheetCarousel_separator} />
                  </>
                )}

                <Grid className={styles.TimesheetCarousel_informationGrid}>
                  <Text className={styles.TimesheetCarousel_boldText}>
                    TOTAL
                  </Text>
                  <Text className={styles.TimesheetCarousel_boldText}>
                    {/* {totalHours > 0
                      ? user?.hoursAndMinutesFormat
                        ? hoursWithDecimalToHoursAndMinutes(
                            totalHours.toFixed(2)
                          )
                        : totalHours.toFixed(2)
                      : 0} */}
                      { totalHours > 0 
                      ? (user?.hoursAndMinutesFormat)
                        ? hoursWithDecimalToHoursAndMinutes(
                          totalHours.toFixed(2)
                        )
                        :  totalHours.toFixed(2)
                       
                      : 0}
                  </Text>
                </Grid>

                <div className={styles.TimesheetCarousel_separator} />

                <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid
                    className={cn(
                      styles.TimesheetCarousel_informationGrid,
                      styles.TimesheetCarousel_informationEditable
                    )}
                  >
                    <Text>Start</Text>
                    {leaveType === leaveTypes.NONE ||
                    leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                    leaveType === leaveTypes.SICK ? (
                      <EditableTime
                        initialValue={startTimeDetails.startTime}
                        onSave={async (val) => {
                          const isSuccessfulSave = await updateTimesheet({
                            timesheetId,
                            userId,
                            date,
                            startTimeDetails: {
                              startTime: val,
                            },
                          });

                          return isSuccessfulSave;
                        }}
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Actual</Text>
                    {leaveType === leaveTypes.NONE ||
                    leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                    leaveType === leaveTypes.SICK ? (
                      <Text>
                        {startTimeDetails.actualTime
                          ? startTimeDetails.actualTime
                          : 'Uneditable'}
                      </Text>
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  {user?.showLocation && (
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>Location</Text>
                      {startTimeDetails?.location?.address ? (
                        <Text>{startTimeDetails?.location?.address}</Text>
                      ) : (
                        <Text>Uneditable</Text>
                      )}
                    </Grid>
                  )}
                </div>

                <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid
                    className={cn(
                      styles.TimesheetCarousel_informationGrid,
                      styles.TimesheetCarousel_informationEditable
                    )}
                  >
                    <Text>End</Text>
                    {leaveType === leaveTypes.NONE ||
                    leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                    leaveType === leaveTypes.SICK ? (
                      <EditableTime
                        initialValue={endTimeDetails.endTime}
                        onSave={async (val) => {
                          const isSuccessfulSave = await updateTimesheet({
                            timesheetId,
                            userId,
                            date,
                            endTimeDetails: {
                              endTime: val,
                            },
                          });

                          return isSuccessfulSave;
                        }}
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Actual</Text>
                    {leaveType === leaveTypes.NONE ||
                    leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                    leaveType === leaveTypes.SICK ? (
                      <Text>
                        {endTimeDetails.actualTime
                          ? endTimeDetails.actualTime
                          : 'Uneditable'}
                      </Text>
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  {user?.showLocation && (
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>Location</Text>
                      {endTimeDetails?.location?.address ? (
                        <Text>{endTimeDetails?.location?.address}</Text>
                      ) : (
                        <Text>Uneditable</Text>
                      )}
                    </Grid>
                  )}
                </div>

                {/* <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Lunch</Text>
                    {leaveType === leaveTypes.NONE ||
                    leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                    leaveType === leaveTypes.SICK ? (
                      <EditableSelect
                        options={[
                          {
                            label: 'Yes',
                            value: 'Yes',
                          },
                          {
                            label: 'No',
                            value: 'No',
                          },
                        ]}
                        initialValue={isLunchBreak ? 'Yes' : 'No'}
                        onSave={(val) =>
                          updateTimesheet({
                            timesheetId,
                            isLunchBreak: val === 'Yes',
                          })
                        }
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>
                </div> */}
                   <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Lunch</Text>
                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <EditableTime
                        initialValue={lunchBreakDuration}
                        placeholder={'empty'}
                        is24Hour={true}
                        onSave={async (val) => {
                          const isSuccessfulSave = await updateTimesheet({
                            timesheetId,
                            userId,
                            date,
                            isLunchBreak:true,
                            lunchBreakDuration:val,
                            startTimeDetails,
                            endTimeDetails,
                            
                          });
                          
                          return isSuccessfulSave;

                        }}
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>
                </div>
                {leaveType !== leaveTypes.NONE && (
                  <div className={styles.TimesheetCarousel_informationGroup}>
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>Leave</Text>
                      <Text>{leaveType}</Text>
                    </Grid>
                  </div>
                )}

                {projectId && (
                  <div className={styles.TimesheetCarousel_informationGroup}>
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>Billable</Text>
                      <EditableSelect
                        options={[
                          {
                            label: 'Yes',
                            value: 'Yes',
                          },
                          {
                            label: 'No',
                            value: 'No',
                          },
                        ]}
                        initialValue={isBillable ? 'Yes' : 'No'}
                        onSave={(val) =>
                          updateTimesheet({
                            timesheetId,
                            isBillable: val === 'Yes',
                          })
                        }
                      />
                    </Grid>
                  </div>
                )}

                <div
                  className={cn(
                    styles.TimesheetCarousel_informationGroup,
                    styles.TimesheetCarousel_noMargin
                  )}
                >
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text className={styles.TimesheetCarousel_boldText}>
                      Hours
                    </Text>
                    <Text className={styles.TimesheetCarousel_boldText}>
                      {/* {user?.hoursAndMinutesFormat
                        ? hoursWithDecimalToHoursAndMinutes(
                            getHours(
                              startTimeTimestamp,
                              endTimeTimestamp,
                              isLunchBreak,
                              leaveType,
                              publicHolidayMultiplier
                            )
                          )
                        : getHours(
                            startTimeTimestamp,
                            endTimeTimestamp,
                            isLunchBreak,
                            leaveType,
                            publicHolidayMultiplier
                          )} */}
                           {(user?.hoursAndMinutesFormat)
                        ? hoursWithDecimalToHoursAndMinutes(hours.toFixed(2))
                        : `${(hours.toFixed(2))}`}
                    </Text>
                  </Grid>
                </div>

                <div className={styles.TimesheetCarousel_separator} />

                <div className={styles.TimesheetCarousel_informationGroup}>
                  <Text className={styles.TimesheetCarousel_boldText}>
                    INCOME:
                  </Text>

                  <Text
                    className={styles.TimesheetCarousel_boldText}
                    type={textTypes.BODY.MD}
                    colorClass={colorClasses.GREEN[400]}
                  >
                    $
                    {(
                      Number(wage) *
                      getHours(
                        // startTimeTimestamp,
                        // endTimeTimestamp,
                        // isLunchBreak,
                        // leaveType,
                        // publicHolidayMultiplier

                        startTimeTimestamp,
                        endTimeTimestamp,
                        isLunchBreak,
                        lunchBreakDuration,
                        // leaveType,
                        // publicHolidayMultiplier
                      )
                    ).toFixed(2)}
                  </Text>
                </div>

                <Grid className={styles.TimesheetCarousel_informationGrid}>
                  <Text>Wage</Text>
                  <Text>${Number(wage).toFixed(2)}</Text>
                </Grid>

                <Grid className={styles.TimesheetCarousel_informationGrid}>
                  <Text>Costs</Text>

                  <Text>${totalCost.toFixed(2)}</Text>
                </Grid>

                <div className={styles.TimesheetCarousel_separator} />

                <div
                  className={cn(
                    styles.TimesheetCarousel_informationGroup,
                    styles.TimesheetCarousel_noMargin
                  )}
                >
                  <Text className={styles.TimesheetCarousel_boldText}>
                    NOTES:
                  </Text>
                  <EditableTextarea
                    initialValue={notes === '' || !notes ? null : notes}
                    value={notes}
                    onSave={(val) =>
                      updateTimesheet({
                        timesheetId,
                        notes: val,
                      })
                    }
                  />
                </div>
              </div>
            );
          }
        )}
      </Carousel>

      {totalTimesheets > 1 && (
        <div className={styles.TimesheetCarousel_carousel_buttons}>
          <button
            type="button"
            className={styles.TimesheetCarousel_carousel_button}
            onClick={() => {
              if (currentEntryIndex !== 0) {
                setCurrentEntryIndex(currentEntryIndex - 1);
              }
            }}
          >
            <Icon icon="chevron_left" />
          </button>

          <Text type={textTypes.BODY.LG}>
            {currentEntryIndex + 1}/{totalTimesheets}
          </Text>

          <button
            type="button"
            className={styles.TimesheetCarousel_carousel_button}
            onClick={() => {
              if (currentEntryIndex + 1 !== totalTimesheets) {
                setCurrentEntryIndex(currentEntryIndex + 1);
              }
            }}
          >
            <Icon icon="chevron_right" />
          </button>
        </div>
      )}
    </div>
  );
};

TimesheetCarousel.propTypes = {
  totalHours: PropTypes.number.isRequired,
  timesheetsForDay: PropTypes.array.isRequired,
  totalTimesheets: PropTypes.number.isRequired,
};

export default TimesheetCarousel;
