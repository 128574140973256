import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';

import { ControlledInput, Modal } from '../../elements';
import { buttonTypes, inputTypes } from '../../../globals';
import { useSendReviewReport } from '../../../hooks';
import { isValidEmail } from '../../../utils/string';

const validate = (values) => {
  const errors = {};

  if (!values.emailAddress) {
    errors.emailAddress = 'This field is required.';
  } else if (!isValidEmail(values.emailAddress)) {
    errors.emailAddress = 'This must be a valid email address.';
  }

  return errors;
};

const SendReviewReportModal = ({
  isOpen,
  handleClose,
  employerFullName,
  employerEmailAddress,
  vehicleId,
  reviewId,
}) => {
  const alert = useAlert();

  const { isSending: isSendingReport, sendReport } = useSendReviewReport();

  const formRef = useRef();

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Review Report"
      actions={[
        {
          text: 'Send',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isSendingReport,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          emailAddress: '',
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const { emailAddress } = values;
          const fileName = 'Review Report';

          const { responseCode: sendReportResponseCode } = await sendReport({
            emailAddress,
            employerFullName,
            employerEmailAddress,
            vehicleId,
            reviewId,
            fileName,
          });

          const sendReportCallbacks = {
            sent: () => {
              alert.success('Review report sent!');

              handleClose();
            },
            invalidFields: () => alert.error('Invalid fields.'),
            internalError: () => alert.error('Oops, something went wrong.'),
          };

          switch (sendReportResponseCode) {
            case 200:
              sendReportCallbacks.sent();
              break;
            case 400:
              sendReportCallbacks.invalidFields();
              break;
            case 500:
              sendReportCallbacks.internalError();
              break;
            default:
              break;
          }
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <ControlledInput
            type={inputTypes.SLIM}
            label="Email Address*"
            name="emailAddress"
            icon="email"
            value={values.emailAddress}
            error={errors.emailAddress}
            onChange={(e) => setFieldValue('emailAddress', e.target.value)}
          />
        )}
      </Formik>
    </Modal>
  );
};

SendReviewReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  employerFullName: PropTypes.string.isRequired,
  employerEmailAddress: PropTypes.string.isRequired,
  vehicleId: PropTypes.string.isRequired,
  reviewId: PropTypes.string.isRequired,
};

export default SendReviewReportModal;
