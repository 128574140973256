import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import styles from '../styles.module.scss';

import { ControlledSelect, Spinner, Modal } from '../../../elements';
import {
  buttonTypes,
  colorNames,
  modalPositions,
  modalSizes,
  spinnerSizes,
} from '../../../../globals';
import { XeroService } from '../../../../services';

const validate = (values) => {
  const errors = {};

  if (!values.employee) {
    errors.employee = 'This field is required.';
  }

  return errors;
};

const SelectXeroEmployeeModal = ({
  isOpen,
  handleClose,
  tokenSet,
  tenantId,
  startDate,
  endDate,
  timesheets,
  handleSuccess,
}) => {
  const cookies = new Cookies();
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employees, setEmployees] = useState(null);

  useEffect(async () => {
    try {
      const { data: getEmployeesResponse } = await XeroService.getEmployees({
        tokenSet,
        tenantId,
      });

      if (getEmployeesResponse.employees) {
        setEmployees(
          getEmployeesResponse.employees?.map(
            ({ firstName, lastName, employeeID, payrollCalendarID }) => ({
              label: `${firstName} ${lastName}`,
              value: {
                payrollCalendarId:
                  payrollCalendarID === '00000000-0000-0000-0000-000000000000'
                    ? null
                    : payrollCalendarID,
                employeeId: employeeID,
              },
            })
          )
        );
      }

      if (getEmployeesResponse.tokenSet) {
        // The tokens have been refreshed, so we update the
        // xeroTokenSet in the cookies
        cookies.set('xeroTokenSet', getEmployeesResponse.tokenSet, {
          path: '/',
        });
      }
    } catch {
      // Both access token and refresh token is invalid already.
      // We remove the xeroTokenSet in the cookies
      cookies.remove('xeroTokenSet', {
        path: '/',
      });
    }
  }, []);

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Select a XERO Employee"
      className={styles.ForgotPasswordModals}
      hasCloseButton={false}
      actions={[
        {
          text: 'Proceed',
          type: buttonTypes.PRIMARY.VIOLET,
          disabled: isSubmitting === true,
          onClick: () => {
            formRef.current.handleSubmit();
          },
        },
      ]}
    >
      {!employees ? (
        <Spinner colorName={colorNames.VIOLET} size={spinnerSizes.LG} />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{ employee: employees.length > 0 ? employees[0] : null }}
          onSubmit={async (values, { setErrors }) => {
            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            setIsSubmitting(true);

            if (!values.employee.value.payrollCalendarId) {
              // Show an error message
              setErrors({
                employee:
                  'You need to assign this employee to a pay calendar in your XERO account.',
              });
            } else {
              // Transfer timesheets
              try {
                const { data: transferTimesheetsResponse } =
                  await XeroService.transferTimesheets({
                    tokenSet,
                    tenantId,
                    startDate,
                    endDate,
                    timesheets,
                    employeeId: values.employee.value.employeeId,
                    payrollCalendarId: values.employee.value.payrollCalendarId,
                  });

                if (
                  transferTimesheetsResponse.message === 'dates_do_not_match'
                ) {
                  setErrors({
                    employee:
                      "The timesheet dates don't match any of the pay period dates of this employee.",
                  });
                } else if (
                  transferTimesheetsResponse.message ===
                  'timesheets_transferred'
                ) {
                  handleSuccess();
                }
              } catch (err) {
                setErrors({
                  employee:
                    'Oops, something went wrong. Please contact an administrator.',
                });
              }
            }

            setIsSubmitting(false);
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <ControlledSelect
                options={employees}
                name="employee"
                placeholder="Employee*"
                value={values.employee}
                error={errors.employee}
                onChange={(val) => {
                  setFieldValue('employee', {
                    label: val.label,
                    value: val.value,
                  });
                }}
              />
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

SelectXeroEmployeeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tokenSet: PropTypes.object.isRequired,
  tenantId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  timesheets: PropTypes.object.isRequired,
  // doesn't accept any parameter. This will just
  // proceed to the next modal
  handleSuccess: PropTypes.func.isRequired,
};

export default SelectXeroEmployeeModal;
