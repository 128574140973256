import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import { Dropzone, SweetAlert } from '../../../components';
import {
  Button,
  ControlledInput,
  Text,
  Spinner,
} from '../../../components/elements';
import {
  buttonKinds,
  buttonTypes,
  colorNames,
  contractorSubscriptionPlans,
  textTypes,
  userTypes,
  subscriptionPlans,
  spinnerSizes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { UsersService, SubscriptionsService } from '../../../services';
import {
  isValidEmail,
  isValidPhoneNumber,
  isBase64,
} from '../../../utils/string';

const AccountInformation = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);

  // Define constants
  let currentSubscriptionPlan;
  if (user.userType === userTypes.EMPLOYER) {
    currentSubscriptionPlan = subscriptionPlans.find(
      (plan) => user?.subscriptionPlanId === plan.id
    );
  } else if (user.userType === userTypes.CONTRACTOR) {
    currentSubscriptionPlan = contractorSubscriptionPlans.find(
      (plan) => user?.subscriptionPlanId === plan.id
    );
  }

  const [isUpdating, setIsUpdating] = useState(false);
  const [showSwitchAlert, setShowSwitchAlert] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.fullName) {
      errors.fullName = 'This field is required.';
    } else if (values.fullName.length > 60) {
      errors.fullName = 'The maximum length of this field is 60 characters.';
    }

    if (!values.emailAddress) {
      errors.emailAddress = 'This field is required.';
    } else if (!isValidEmail(values.emailAddress)) {
      errors.emailAddress = 'This must be a valid email address.';
    }

    if (user.userType === userTypes.EMPLOYER) {
      if (values.phoneNumber) {
        if (!isValidPhoneNumber(values.phoneNumber)) {
          errors.phoneNumber = 'Only numbers are allowed.';
        } else if (values.phoneNumber.length > 15) {
          errors.phoneNumber =
            'The maximum length of this field is 15 characters.';
        }
      }

      if (!values.companyName) {
        errors.companyName = 'This field is required.';
      } else if (values.companyName.length > 50) {
        errors.companyName =
          'The maximum length of this field is 50 characters.';
      }
    }

    return errors;
  };

  return (
    <div className={styles.AccountInformation}>
      <Text
        type={textTypes.HEADING.SM}
        className={styles.AccountInformation_title}
      >
        Account Information
      </Text>

      <Formik
        initialValues={{
          fullName: user.fullName,
          emailAddress: user.emailAddress,
          phoneNumber:
            user.userType === userTypes.EMPLOYER ||
            user.userType === userTypes.CONTRACTOR
              ? user.phoneNumber
              : '',
          companyName:
            user.userType === userTypes.EMPLOYER ? user.companyName : '',
          companyLogo:
            user.userType === userTypes.EMPLOYER ? user.companyLogo : undefined,
        }}
        onSubmit={async (values, { setErrors }) => {
          const currentFormValues = {
            fullName: values.fullName,
            emailAddress: values.emailAddress,
            phoneNumber: values.phoneNumber,
            companyName: values.companyName,
            companyLogo: values.companyLogo,
          };

          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          setIsUpdating(true);

          // The email address is changed, we need to verify
          // if the new email address doesn't exist.
          if (values.emailAddress !== user.emailAddress) {
            // Check if user exists with the inputted emailAddress
            const { data: userExistsResponse } = await UsersService.userExists(
              currentFormValues.emailAddress
            );
            if (userExistsResponse.message === 'user_exists') {
              setErrors({
                emailAddress: 'This email address has been used already.',
              });
              setIsUpdating(false);
              return;
            }
          }

          // Upload logo and retrieve the url
          if (user.userType === userTypes.EMPLOYER) {
            if (isBase64(currentFormValues.companyLogo)) {
              const { data: uploadCompanyLogoResponse } =
                await UsersService.uploadCompanyLogo({
                  base64Logo: currentFormValues.companyLogo,
                });

              currentFormValues.companyLogo = `https://storage.googleapis.com/timesheet-companies/${uploadCompanyLogoResponse.fileName}`;
            } else {
              currentFormValues.companyLogo = null;
            }
          }

          // If the emailAddress is valid, we proceed with
          // the update of the user
          const { data: updateResponse } =
            await UsersService.updateAccountInformation({
              id: user.id,
              ...currentFormValues,
              userType: user.userType,
            });

          if (updateResponse.message === 'user_account_information_updated') {
            // Show an alert
            alert.success('Account Information updated!');
            // Update user state
            loginUpdate({ ...user, ...currentFormValues });
          } else {
            alert.error('Oops, something went wrong.');
          }

          setIsUpdating(false);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ControlledInput
              className={styles.AccountInformation_input}
              placeholder="Full Name*"
              name="fullName"
              icon="contact_mail"
              value={values.fullName}
              error={errors.fullName}
              onChange={(e) => setFieldValue('fullName', e.target.value)}
            />

            <ControlledInput
              className={styles.AccountInformation_input}
              placeholder="Email Address*"
              name="emailAddress"
              icon="email"
              value={values.emailAddress}
              error={errors.emailAddress}
              onChange={(e) => setFieldValue('emailAddress', e.target.value)}
            />

            {(user.userType === userTypes.EMPLOYER ||
              user.userType === userTypes.CONTRACTOR) && (
              <ControlledInput
                className={styles.AccountInformation_input}
                placeholder="Phone Number"
                name="phoneNumber"
                icon="phone"
                value={values.phoneNumber}
                error={errors.phoneNumber}
                onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
              />
            )}

            {user.userType === userTypes.EMPLOYER && (
              <>
                <ControlledInput
                  className={styles.AccountInformation_input}
                  placeholder="Company Name*"
                  name="companyName"
                  icon="domain"
                  value={values.companyName}
                  error={errors.companyName}
                  onChange={(e) => setFieldValue('companyName', e.target.value)}
                />

                <Text
                  type={textTypes.HEADING.XS}
                  className={styles.AccountInformation_withMarginTop}
                >
                  Company Logo
                </Text>

                <Dropzone
                  className={styles.AccountInformation_withMarginTop}
                  title="Add Logo Here"
                  logo={values.companyLogo}
                  onUpload={(logo) => {
                    if (logo) {
                      // Get the base64 equivalent of the logo
                      const reader = new FileReader();
                      reader.readAsDataURL(logo);
                      reader.onload = function () {
                        // Set companyLogo to the base64CompanyLogo
                        const base64CompanyLogo = reader.result.substr(
                          reader.result.indexOf(',') + 1
                        );
                        setFieldValue('companyLogo', base64CompanyLogo);
                      };
                    } else {
                      setFieldValue('companyLogo', null);
                    }
                  }}
                />
              </>
            )}

            <div className={styles.AccountInformation_buttonGroup}>
              {user.userType === userTypes.CONTRACTOR && (
                <Button
                  type={buttonTypes.PRIMARY.RED}
                  icon="switch_account"
                  className={styles.NoEmployer_switchButton}
                  onClick={async () => setShowSwitchAlert(true)}
                >
                  Switch to Employee Account
                </Button>
              )}

              <Button
                className={styles.AccountInformation_buttonGroup_updateButton}
                kind={buttonKinds.SUBMIT}
                disabled={isUpdating}
                onClick={() => {}}
              >
                <span
                  className={styles.AccountInformation_buttonGroup_buttonText}
                >
                  Update
                  {isUpdating && (
                    <Spinner
                      size={spinnerSizes.XS}
                      colorName={colorNames.WHITE}
                      className={styles.AccountInformation_buttonGroup_spinner}
                    />
                  )}
                </span>
              </Button>
            </div>
          </form>
        )}
      </Formik>

      <SweetAlert
        show={showSwitchAlert}
        title="Are you sure?"
        buttonsDisabled={isSwitching}
        onConfirm={async () => {
          setIsSwitching(true);

          // Cancel subscription plan (if there is)
          let subscriptionIsCancelled = false;
          if (user?.subscriptionId) {
            const { data: cancelSubscriptionResponse } =
              await SubscriptionsService.cancelSubscription({
                previousSubscriptionPlanName: currentSubscriptionPlan?.name,
                previousSubscriptionPlanInterval:
                  currentSubscriptionPlan?.timeInterval,
                previousSubscriptionPlanQuantity:
                  user.userType === userTypes.EMPLOYER
                    ? user.subscriptionPlanQuantity
                    : undefined,
                subscriptionId: user?.subscriptionId,
                customerFullName: user.fullName,
                customerEmailAddress: user?.emailAddress,
                customerCompanyName:
                  user.userType === userTypes.EMPLOYER
                    ? user.companyName
                    : undefined,
              });

            if (
              cancelSubscriptionResponse.message === 'subscription_canceled'
            ) {
              subscriptionIsCancelled = true;
            }
          }

          // Switch contractor to employee
          const { status: switchToEmployeeResponseCode, data } =
            await UsersService.switchToEmployee({
              contractorId: user.id,
              fullName: user.fullName,
              emailAddress: user.emailAddress,
            });

          const switchToEmployeeCallbacks = {
            switched: () => {
              alert.success(
                'Switched to Employee Successfully! Redirecting...'
              );

              const userCopy = JSON.parse(JSON.stringify(user));
              delete userCopy.timesheetStartDate;
              delete userCopy.timesheetStartWeek;
              delete userCopy.isLunchBreakByDefault;
              delete userCopy.hoursAndMinutesFormat;
              delete userCopy.hourlyWage;

              setTimeout(() => {
                loginUpdate({
                  ...userCopy,
                  userType: 'Employee',
                  employerDetails: null,
                  employeeCode: data.employeeCode,
                  subscriptionStatus: subscriptionIsCancelled
                    ? 'canceled'
                    : undefined,
                });
              }, 3000);
            },
            invalidFields: () => alert.error('Invalid fields.'),
            internalError: () => alert.error('Oops, something went wrong.'),
          };

          switch (switchToEmployeeResponseCode) {
            case 200:
              switchToEmployeeCallbacks.switched();
              break;
            case 400:
              switchToEmployeeCallbacks.invalidFields();
              break;
            case 500:
              switchToEmployeeCallbacks.internalError();
              break;
            default:
              break;
          }
        }}
        onCancel={() => setShowSwitchAlert(false)}
      >
        This will cancel your current subscription plan if active.
      </SweetAlert>
    </div>
  );
};

export default AccountInformation;
