import React, { useState, useContext } from 'react';
import cn from 'classnames';
import dateFormat from 'dateformat';
import { useParams } from 'react-router-dom';

import Preloader from './Preloader';
import { Breadcrumbs, TimesheetGrid } from '../../../components';
import {
  Badge,
  Button,
  ButtonLink,
  ControlledSelect,
  Text,
} from '../../../components/elements';
import { useProject, useTimesheets, useWindowSize } from '../../../hooks';
import { TimesheetContext, UserContext } from '../../../contexts';
import {
  buttonTypes,
  colorNames,
  timesheetTypes,
  textTypes,
} from '../../../globals';
import {
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  getPrevOrAfterDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../../utils/datetime';
import {
  getDateEquivalence,
  generateDayIds,
  getHours,
} from '../../../utils/timesheets';
import styles from './styles.module.scss';

const ViewProject = () => {
  const { clientId, projectId } = useParams();
  const { user } = useContext(UserContext);

  const [viewType, setViewType] = useState({
    value: timesheetTypes.WEEKLY,
    label: `View by ${timesheetTypes.WEEKLY}`,
  });
  const [startDate, setStartDate] = useState(
    getDateEquivalence(
      timesheetTypes.WEEKLY,
      user.employerDetails.timesheetStartDate,
      'start'
    )
  );
  const [endDate, setEndDate] = useState(
    getDateEquivalence(
      timesheetTypes.WEEKLY,
      user.employerDetails.timesheetStartDate,
      'end'
    )
  );

  const dayIds = generateDayIds(
    viewType.value,
    startDate,
    user.employerDetails.timesheetStartWeek
  );

  const { isLoading: isProjectLoading, project } = useProject({
    clientId,
    projectId,
  });

  // This is for the accumulated timesheets created by the employee
  const { timesheets: allTimesheets } = useTimesheets({
    userId: user.id,
    projectId,
    startDate: dateFormat(new Date('January 1, 2018'), 'yyyy-mm-dd'),
    endDate: dateFormat(new Date('January 1, 2100'), 'yyyy-mm-dd'),
  });

  // Calculate for the overall total hours using the accumulated timesheets
  let overallTotalHours = 0;
  allTimesheets.forEach(
    ({
      date,
      startTimeDetails = {
        endTime: undefined,
        actualTime: undefined,
      },
      endTimeDetails = {
        endTime: undefined,
        actualTime: undefined,
      },
      isLunchBreak,
      isTravelCharge,
      leaveType,
      publicHolidayMultiplier,
    }) => {
      const startTimeTimestamp = convertDateTimeToTimestamp(
        date,
        convertTimeTo24HourFormat(startTimeDetails.startTime)
      );
      const endTimeTimestamp = convertDateTimeToTimestamp(
        date,
        convertTimeTo24HourFormat(endTimeDetails.endTime)
      );

      overallTotalHours += Number(
        getHours(
          startTimeTimestamp,
          endTimeTimestamp,
          isLunchBreak,
          isTravelCharge,
          leaveType,
          publicHolidayMultiplier
        )
      );
    }
  );

  // This is for the timesheets from startDate to endDate
  const {
    isLoading: isTimesheetsLoading,
    timesheets,
    updateTimesheet,
    deleteTimesheet,
  } = useTimesheets({
    userId: user.id,
    projectId,
    startDate,
    endDate,
  });

  const { windowSize } = useWindowSize();

  return (
    <div className={styles.ViewProject}>
      <div className={styles.ViewProject_firstRow}>
        <Breadcrumbs
          pageTitle="View Project"
          pages={[
            {
              name: 'Dashboard',
              link: '/employee/dashboard',
            },
            {
              name: 'Clients',
              link: '/employee/clients',
            },
            {
              name: 'View Client',
              link: `/employee/clients/view/${clientId}`,
            },
          ]}
        />
      </div>

      <Text
        type={textTypes.HEADING.XS}
        className={styles.ViewClient_withMargin}
      >
        Project Details
      </Text>

      {isProjectLoading ? (
        <Preloader />
      ) : (
        <div className={styles.ViewProject_secondRow}>
          <div className={styles.ViewProject_grid}>
            {/* Header of ViewProjectGrid starts here */}
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header,
                styles.ViewProject_grid_header_action
              )}
            >
              Status
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Name
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Start Date
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Projected Finish Date
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Address
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Expected Hours
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Overall Total Hours
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Notes
            </div>
            {/* Header of ViewProjectGrid ends here */}

            {/* Body of ViewProjectGrid starts here */}
            <div className={styles.ViewProject_grid_column}>
              <Badge
                text={project?.status.value}
                colorName={
                  project?.status.value === 'Ongoing'
                    ? colorNames.BLUE
                    : colorNames.GREEN
                }
              />
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.name}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.startDate
                ? dateFormat(project?.startDate, 'dd/mm/yyyy')
                : '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.projectedFinishDate
                ? dateFormat(project?.projectedFinishDate, 'dd/mm/yyyy')
                : '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.address || '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.expectedHours || '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {overallTotalHours.toFixed(2)}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.notes || '--'}
            </div>
            {/* Body of ViewProjectGrid ends here */}
          </div>
        </div>
      )}

      <div className={styles.ViewProject_thirdRow}>
        <div className={styles.ViewProject_navigationContainer}>
          <Text type={textTypes.HEADING.XS}>
            {viewType.value !== timesheetTypes.DAILY && (
              <>{dateFormat(new Date(startDate), 'mmm d')} - </>
            )}

            {dateFormat(new Date(endDate), 'mmm d, yyyy')}
          </Text>

          <div className={styles.ViewProject_navigationContainer_navigation}>
            <Button
              className={styles.ViewProject_navigationContainer_navigation_item}
              icon="arrow_back"
              onClick={() => {
                if (viewType.value === timesheetTypes.DAILY) {
                  setStartDate(getPrevOrAfterDate(startDate, 1, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 1, 'subtract'));
                } else if (viewType.value === timesheetTypes.WEEKLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 7, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 7, 'subtract'));
                } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 14, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 14, 'subtract'));
                } else if (viewType.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getPrevOrAfterDate(startDate, 28, 'subtract')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(
                        getPrevOrAfterDate(startDate, 28, 'subtract')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                }
              }}
            >
              <span
                className={
                  styles.ViewProject_navigationContainer_navigation_item_buttonText
                }
              >
                Prev{' '}
                {viewType.value === timesheetTypes.DAILY
                  ? 'Day'
                  : viewType.value.slice(0, -2)}
              </span>
            </Button>

            <ControlledSelect
              className={styles.ViewProject_navigationContainer_navigation_item}
              options={[
                {
                  value: timesheetTypes.DAILY,
                  label: `View by ${timesheetTypes.DAILY}`,
                },
                {
                  value: timesheetTypes.WEEKLY,
                  label: `View by ${timesheetTypes.WEEKLY}`,
                },
                {
                  value: timesheetTypes.FORTNIGHTLY,
                  label: `View by ${timesheetTypes.FORTNIGHTLY}`,
                },
                {
                  value: timesheetTypes.MONTHLY,
                  label: `View by ${timesheetTypes.MONTHLY}`,
                },
              ]}
              name="viewType"
              placeholder="View Type"
              value={viewType}
              onChange={(val) => {
                if (val.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getDateEquivalence(
                          val.value,
                          user.employerDetails.timesheetStartDate,
                          'start'
                        )
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(
                        getDateEquivalence(
                          val.value,
                          user.employerDetails.timesheetStartDate,
                          'start'
                        )
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                } else {
                  setStartDate(
                    getDateEquivalence(
                      val.value,
                      user.employerDetails.timesheetStartDate,
                      'start'
                    )
                  );
                  setEndDate(
                    getDateEquivalence(
                      val.value,
                      user.employerDetails.timesheetStartDate,
                      'end'
                    )
                  );
                }

                setViewType(val);
              }}
            />

            <Button
              className={styles.ViewProject_navigationContainer_navigation_item}
              icon="arrow_forward"
              iconPosition="right"
              onClick={() => {
                if (viewType.value === timesheetTypes.DAILY) {
                  setStartDate(getPrevOrAfterDate(startDate, 1, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 1, 'add'));
                } else if (viewType.value === timesheetTypes.WEEKLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 7, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 7, 'add'));
                } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 14, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 14, 'add'));
                } else if (viewType.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getPrevOrAfterDate(endDate, 28, 'add')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(getPrevOrAfterDate(endDate, 28, 'add')),
                      'yyyy-mm-dd'
                    )
                  );
                }
              }}
            >
              <span
                className={
                  styles.ViewProject_navigationContainer_navigation_item_buttonText
                }
              >
                Next{' '}
                {viewType.value === timesheetTypes.DAILY
                  ? 'Day'
                  : viewType.value.slice(0, -2)}
              </span>
            </Button>
          </div>
        </div>

        <ButtonLink
          to={`/employee/clients/view/${clientId}/projects/view/${projectId}/create`}
          type={buttonTypes.PRIMARY.GREEN}
          icon="add"
        >
          Create Timesheet
        </ButtonLink>
      </div>

      <TimesheetContext.Provider
        value={{
          viewType: viewType.value,
          startDate,
          endDate,
          dayIds,
          employees: [user],
          rosters: [],
          isTimesheetsLoading,
          timesheets,
          updateTimesheet,
          deleteTimesheet,
          windowSize,
        }}
      >
        <TimesheetGrid />
      </TimesheetContext.Provider>
    </div>
  );
};

export default ViewProject;
