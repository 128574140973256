import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs, SweetAlert } from '../../../components';
import {
  ButtonLink,
  IconButton,
  ControlledInput,
  IconLink,
  Text,
  Button,
} from '../../../components/elements';
import {
  buttonTypes,
  colorClasses,
  textTypes,
  userTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { useClients, useEmployees, useWindowSize } from '../../../hooks';
import NoResults from '../../../static/images/Misc/no-results.webp';
import GenerateClientReportModal from '../../../components/modals/GenerateClientReportModal';

const Clients = () => {
  const { user } = useContext(UserContext);
  const { employees } = useEmployees({
    employerId: user.id,
    includeNoLongerEmployees: true,
  });
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [reportType, setReportType] = useState(null);
  let userId = null;
  const userTypeInsensitive = user.userType.toLowerCase();
  if (
    user.userType === userTypes.EMPLOYER ||
    user.userType === userTypes.CONTRACTOR
  ) {
    userId = user.id;
  } else if (user.userType === userTypes.EMPLOYEE) {
    userId = user.employerDetails.employerId;
  }
  const [search, setSearch] = useState("");

  const handleOnChange = (e) => {
    e.preventDefault()
    setSearch(e.target.value)
  }

  const {
    isLoading: isClientsLoading,
    clients,
    deleteClient,
  } = useClients({
    userId,
    search
  });

  const { windowSize } = useWindowSize();

  return (
    <div className={styles.Clients}>
      <div className={styles.Clients_firstRow}>
        <Breadcrumbs
          pageTitle="Clients"
          pages={[
            {
              name: 'Dashboard',
              link: `/${userTypeInsensitive}/dashboard`,
            },
          ]}
        />
        <div className={styles.Clients_reportButtons}>
        {(user.userType === userTypes.EMPLOYER) && ( <Button
              className={styles.Clients_reportButtons_button}
              icon="download"
              type={buttonTypes.PRIMARY.RED}
              onClick={() =>setReportType('Download')}
              disabled={isClientsLoading || clients.length === 0}
            >
              <span className={styles.Clients_reportButtons_button_text}>
                Download
              </span>
            </Button>
             )}
            {reportType !== null && (
              <GenerateClientReportModal
                isOpen={reportType !== null}
                handleClose={() => setReportType(null)}
                reportType={reportType}
                employees={employees}
                hoursAndMinutesFormat={user?.hoursAndMinutesFormat}
              />
            )}
            {(user.userType === userTypes.EMPLOYER ||
          user.userType === userTypes.CONTRACTOR) && (
            <ButtonLink
              to={`/${userTypeInsensitive}/clients/create`}
              type={buttonTypes.PRIMARY.GREEN}
              icon="add"
            >
              Add New Client
            </ButtonLink>
          )}
        </div>
        
      </div>

      {isClientsLoading ? (
        <Preloader />
      ) : (
        <>
          <div className={styles.Clients_secondRow}>
          </div>
          <div className={styles.Clients_viewClientDiv}>
           <ControlledInput
                className={styles.Clients_searchBoxInput}
                name="search"
                placeholder="Search By Client Name..."
                value={search}
                onChange={(e) => handleOnChange(e)}
              />
              <ButtonLink
                className={styles.Clients_searchButton}
                to="/employer/clients"
                type={buttonTypes.PRIMARY.VIOLET}
                icon="search"
              ></ButtonLink>
           </div>
          {clients.length ? (
            <div
              className={cn(styles.Clients_grid, {
                [styles.Clients_grid___Employee]: user.userType === 'Employee',
              })}
            >

              {/* Header of ClientsGrid starts here */}
              <div
                className={cn(
                  styles.Clients_grid_column,
                  styles.Clients_grid_header
                )}
              >
                Client Name
              </div>
              <div
                className={cn(
                  styles.Clients_grid_column,
                  styles.Clients_grid_header
                )}
              >
                Email Address
              </div>
              <div
                className={cn(
                  styles.Clients_grid_column,
                  styles.Clients_grid_header
                )}
              >
                Phone Number
              </div>
              <div
                className={cn(
                  styles.Clients_grid_column,
                  styles.Clients_grid_header
                )}
              >
                Address
              </div>
              <div
                className={cn(
                  styles.Clients_grid_column,
                  styles.Clients_grid_header
                )}
              >
                Notes
              </div>
              <div
                className={cn(
                  styles.Clients_grid_column,
                  styles.Clients_grid_header,
                  styles.Clients_grid_header_action
                )}
              >
                Action
                {user.userType === userTypes.EMPLOYER ||
                  user.userType === userTypes.CONTRACTOR
                  ? 's'
                  : ''}
              </div>
              {/* Header of ClientsGrid ends here */}

              {/* Body of ClientsGrid starts here */}
              {clients.map(
                (
                  { id, name, emailAddress, phoneNumber, address, notes },
                  i
                ) => (
                  <div className={styles.Clients_keyWrapper} key={id}>
                    <div
                      className={styles.Clients_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 5} / 1 / ${(i + 1) * 5 + 5
                              } / 1`,
                          }
                          : undefined
                      }
                    >
                      {name}
                    </div>
                    <div
                      className={styles.Clients_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 5 + 1} / 2 / ${(i + 1) * 5 + 1
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {emailAddress || '--'}
                    </div>
                    <div
                      className={styles.Clients_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 5 + 2} / 2 / ${(i + 1) * 5 + 2
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {phoneNumber || '--'}
                    </div>
                    <div
                      className={styles.Clients_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 5 + 3} / 2 / ${(i + 1) * 5 + 3
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {address || '--'}
                    </div>
                    <div
                      className={styles.Clients_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 5 + 4} / 2 / ${(i + 1) * 5 + 4
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {notes || '--'}
                    </div>
                    <div
                      className={styles.Clients_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 5} / 3 / ${(i + 1) * 5 + 5
                              } / 3`,
                          }
                          : undefined
                      }
                    >
                      <IconLink
                        to={`/${userTypeInsensitive}/clients/view/${id}`}
                        className={styles.Clients_grid_viewButton}
                        icon="visibility"
                      />

                      {(user.userType === userTypes.EMPLOYER ||
                        user.userType === userTypes.CONTRACTOR) && (
                          <>
                            <IconLink
                              to={`/${userTypeInsensitive}/clients/update/${id}`}
                              className={styles.Clients_grid_editButton}
                              icon="edit"
                            />

                            <IconButton
                              className={styles.Clients_grid_deleteButton}
                              icon="delete"
                              onClick={() => setSelectedClientId(id)}
                            />
                          </>
                        )}
                    </div>
                  </div>
                )
              )}

              {/* Body of ClientsGrid ends here */}
            </div>
          ) : (
            <div className={styles.Clients_noClients}>
              <img
                src={NoResults}
                alt="TimeSheet No Clients"
                className={styles.Clients_noClients_image}
              />

              <Text
                colorClass={colorClasses.RED['400']}
                type={textTypes.HEADING.XS}
              >
                YOU CURRENTLY DON'T HAVE CLIENTS.
              </Text>

              <Text
                type={textTypes.HEADING.XXS}
                className={styles.Clients_noClients_withMargin}
              >
                Add one by clicking the button at the upper side of the screen.
              </Text>
            </div>
          )}
        </>
      )}

      {(user.userType === userTypes.EMPLOYER ||
        user.userType === userTypes.CONTRACTOR) && (
          <SweetAlert
            show={selectedClientId !== null}
            title="Are you sure?"
            onConfirm={async () => {
              await deleteClient(selectedClientId);
              setSelectedClientId(null);
            }}
            onCancel={() => setSelectedClientId(null)}
          >
            This will delete all of the projects under this client, and all of the
            timesheet entries under those projects.
          </SweetAlert>
        )}
    </div>
  );
};

export default Clients;
