import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Formik } from 'formik';
import ReactTooltip from 'react-tooltip';
import dateFormat from 'dateformat';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  ControlledSelect,
  ControlledTextArea,
  DatePicker,
  Icon,
  InputMask,
  Text,
  Spinner,
  HoursPicker,
} from '../../../components/elements';
import Costs from './Costs';
import {
  buttonKinds,
  colorClasses,
  colorNames,
  spinnerSizes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { useCreateTimesheet, useClient, useProject } from '../../../hooks';
import { TimesheetsService } from '../../../services';
import {
  checkIfTimeIsValid,
  convertTimeTo24HourFormat,
  convertDateTimeToTimestamp,
  hoursWithDecimalToHoursAndMinutes,
  timeMask,
  convertTimeTo12HourFormat,
  momentTo24HourTime,
} from '../../../utils/datetime';
import { getHours, isTimesheetTimesValid } from '../../../utils/timesheets';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';

const validate = (values) => {
  const errors = {};

  if (!values.date) {
    errors.date = 'This field is required.';
  }

  if (!values.startTime) {
    errors.startTime = 'This field is required.';
  } else if (!checkIfTimeIsValid(values.startTime)) {
    errors.startTime = 'Invalid time format.';
  }

  if (!values.endTime) {
    errors.endTime = 'This field is required.';
  } else if (!checkIfTimeIsValid(values.endTime)) {
    errors.endTime = 'Invalid time format.';
  }
  if (values.isLunchBreak.value && !values.lunchBreakDuration) {
    errors.lunchBreakDuration = 'This field is required.';
  }
  if(values.isLunchBreak && values.lunchBreakDuration){
    if(!checkIfTimeIsValid(convertTimeTo12HourFormat(values.lunchBreakDuration))){
        errors.lunchBreakDuration = 'Invalid time format.';
      }
  }

  return errors;
};

const CreateTimesheet = ({ pageTitle, pages }) => {
  const { clientId, projectId } = useParams();
  const alert = useAlert();
  const { user } = useContext(UserContext);
  const [hours, setHours] = useState(0);
  const [costs, setCosts] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);

  const { isCreating: isTimesheetCreating, createTimesheet } =
    useCreateTimesheet();
  const { isLoading: isClientLoading, client } = useClient({
    clientId,
  });
  const { isLoading: isProjectLoading, project } = useProject({
    clientId,
    projectId,
  });

  const updateHours = (
    date,
    startTime,
    endTime,
    isLunchBreak = false,
    lunchBreakDuration = "00:00",
    hourlyWage = 0
  ) => {
    // Set the hours depending on certain conditions
    
    if (date && checkIfTimeIsValid(startTime) && checkIfTimeIsValid(endTime)) {
      const tempHours = getHours(
        convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(startTime)),
        convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(endTime)),
        isLunchBreak,
        lunchBreakDuration,
      );
      setHours(tempHours);
      setTotalIncome(hourlyWage * tempHours);
    } 
    else {
      setHours(0);
      setTotalIncome(0);
    }
  };
  
  return (
    <div className={styles.CreateTimesheet}>
      <Breadcrumbs pageTitle={pageTitle} pages={pages} />

      {isProjectLoading || isClientLoading ? (
        <Preloader />
      ) : (
        <Card className={styles.CreateTimesheet_card}>
          <Formik
            initialValues={{
              date: new Date(),
              startTime: undefined,
              endTime: undefined,
              isLunchBreak: user.isLunchBreakByDefault
                ? { label: 'Yes', value: true }
                : {
                  label: 'No',
                  value: false,
                },
              lunchBreakDuration: user.lunchBreakDuration,
              notes: '',
              isBillable: {
                label: 'Yes',
                value: true,
              },
              hourlyWage: user?.hourlyWage ? user?.hourlyWage : 0,
            }}
            onSubmit={async (values, { setErrors, setFieldValue }) => {
              
              const errors = validate(values);
              if (!isEmpty(errors)) {
                setErrors(errors);
                return;
              }

              const newTimesheet = {
                userId: user.id,
                date: dateFormat(values.date, 'yyyy-mm-dd'),
                startTimeDetails: {
                  startTime: values.startTime,
                },
                endTimeDetails: {
                  endTime: values.endTime,
                },
                isLunchBreak: values.isLunchBreak.value,
                lunchBreakDuration: values.lunchBreakDuration,
                notes: values.notes,
                clientId: clientId || undefined,
                clientName: client?.name || undefined,
                projectId: projectId || undefined,
                projectName: project?.name || undefined,
                isBillable: projectId ? values.isBillable.value : undefined,
                costs,
                wage: values.hourlyWage || 0,
              };

              // Check if times of this timesheet is not overlapping
              // any times in timesheets array
              const { data: getTimesheetsResponse } =
                await TimesheetsService.get({
                  userId: newTimesheet.userId,
                  startDate: newTimesheet.date,
                  endDate: newTimesheet.date,
                });

              const { timesheets } = getTimesheetsResponse;
              const startTime = (values.startTime);
              const endTime = (values.endTime);
              const diffMins = moment.utc(endTime, 'HH:mm a').diff(moment.utc(startTime, 'HH:mm a'), 'minutes');
              
              const lunchTime = values.lunchBreakDuration;
              const lunchHour = moment.utc(lunchTime, 'hh:mm a').get('hour');
              const lunchMinutes = moment.utc(lunchTime, 'hh:mm a').get('minute');
              const totalMinutes = (lunchHour * 60) + lunchMinutes
             
              if ((startTime === endTime) 
              ) {
                alert.error(
                  'Oops, start and end time cannot be the same'
                );
                return;
              }
              if ((Math.abs(diffMins) < 15)) {
                alert.error(
                  'Oops, please record a timesheet for at least 15 minutes'
                );
                return;
              }
             
              if (((diffMins > 0 && Math.abs(diffMins) <= totalMinutes) || totalMinutes > 600 || totalMinutes === 0)) {
                alert.error(
                  'Oops, please select a valid lunch time'
                );
                return;
              }
              if (
                values.startTime &&
                values.endTime &&
                !isTimesheetTimesValid(timesheets, {
                  startTime: values.startTime,
                  endTime: values.endTime,
                })
              ) {
                alert.error(
                  'Oops, the times you have inputted overlaps an existing timesheet.'
                );
                return;
              }
              

              const { responseCode: createTimesheetResponseCode } =
                await createTimesheet(newTimesheet);

              const createTimesheetCallbacks = {
                created: () => {
                  alert.success('Timesheet created!');
                  // Reset the form to its initial state
                  setFieldValue('startTime', undefined);
                  setFieldValue('endTime', undefined);
                  setFieldValue(
                    'isLunchBreak',
                    user.isLunchBreakByDefault
                      ? { label: 'Yes', value: true }
                      : {
                        label: 'No',
                        value: false,
                      }
                  );
                  setFieldValue('lunchBreakDuration', user.lunchBreakDuration);
                  setFieldValue('notes', '');
                  setFieldValue(
                    'hourlyWage',
                    user?.hourlyWage ? user?.hourlyWage : 0
                  );
                  setHours(0);
                  setCosts([]);
                  setTotalIncome(0);
                },
                invalidFields: () => alert.error('Invalid fields.'),
                internalError: () => alert.error('Oops, something went wrong.'),
              };

              switch (createTimesheetResponseCode) {
                case 200:
                  createTimesheetCallbacks.created();
                  break;
                case 400:
                  createTimesheetCallbacks.invalidFields();
                  break;
                case 500:
                  createTimesheetCallbacks.internalError();
                  break;
                default:
                  break;
              }
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Text type={textTypes.HEADING.XXXS}>Worklog</Text>

                <DatePicker
                  className={styles.CreateTimesheet_withMargin}
                  dateFormat="dd/MM/yyyy"
                  selected={values.date}
                  placeholder="Date*"
                  name="date"
                  icon="today"
                  onChange={(date) => {
                    setFieldValue('date', date);
                    updateHours(
                      date,
                      values.startTime,
                      values.endTime,
                      values.isLunchBreak.value,
                      values.lunchBreakDuration,
                      values.hourlyWage
                    );
                  }}
                />

                <InputMask
                  className={styles.CreateTimesheet_withMargin}
                  mask={timeMask(values.startTime)}
                  maskPlaceholder="hh:mm xm"
                  placeholder="Start Time*"
                  name="startTime"
                  icon="schedule"
                  value={values.startTime}
                  error={errors.startTime}
                  onChange={(e) => setFieldValue('startTime', e.target.value)}
                  onBlur={() => {
                    updateHours(
                      values.date,
                      values.startTime,
                      values.endTime,
                      values.isLunchBreak.value,
                      values.lunchBreakDuration,
                      values.hourlyWage,
                    );
                  }}
                />

                <InputMask
                  className={styles.CreateTimesheet_withMargin}
                  mask={timeMask(values.endTime)}
                  maskPlaceholder="hh:mm xm"
                  placeholder="End Time*"
                  name="endTime"
                  icon="schedule"
                  value={values.endTime}
                  error={errors.endTime}
                  onChange={(e) => setFieldValue('endTime', e.target.value)}
                  onBlur={() => {
                    updateHours(
                      values.date,
                      values.startTime,
                      values.endTime,
                      values.isLunchBreak.value,
                      values.lunchBreakDuration,
                      values.hourlyWage
                    );
                  }}
                />

                <ControlledSelect
                  className={styles.CreateTimesheet_withMargin}
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                  name="isLunchBreak"
                  placeholder="Lunch Break*"
                  value={values.isLunchBreak}
                  error={errors.isLunchBreak}
                  onChange={(val) => {
                    setFieldValue('isLunchBreak', {
                      label: val.label,
                      value: val.value,
                    });
                    setFieldValue('lunchBreakDuration', val.value ? user.lunchBreakDuration : undefined);
                    updateHours(
                      values.date,
                      values.startTime,
                      values.endTime,
                      val.value,
                      user.lunchBreakDuration,
                      values.hourlyWage
                    );
                  }}
                />
                {
                  values.isLunchBreak.value &&
                  <div className={styles.CreateTimesheet_withMargin}>
                    <Text
                      type={textTypes.BODY.MD}
                    >
                      Lunch Break Duration
                    </Text>
                    {
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <HoursPicker
                          ampm={false}
                          format={'HH:mm'} 
                          name="lunchBreakDuration"
                          // time={ values.lunchBreakDuration == undefined ? null : dayjs(values.lunchBreakDuration,'hh:mm') }
                          time={dayjs(values.lunchBreakDuration,'hh:mm')}
                          minTime={dayjs().set('hour', 0).set('minutes', 0)}
                          maxTime={dayjs().set('hour', 10).set('minutes', 0)}
                          onChange={(time) => {
                            setFieldValue('lunchBreakDuration', moment(new Date(time).getTime()).format('HH:mm'));
                            updateHours(
                              values.date,
                              values.startTime,
                              values.endTime,
                              values.isLunchBreak.value,
                              moment(new Date(time).getTime()).format('HH:mm'),
                              values.hourlyWage,
                              setFieldValue
                            );
                          }}
                          error={errors.lunchBreakDuration}
                          clearable
                        // disabled={
                        //   values.leaveType.value === leaveTypes.ANNUAL ||
                        //   values.leaveType.value === leaveTypes.WITHOUT_PAY
                        // }
                        />
                      </LocalizationProvider>
                    }
                  </div>
                }
                {projectId && (
                  <ControlledSelect
                    className={styles.CreateTimesheet_withMargin}
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ]}
                    name="isBillable"
                    placeholder="Billable*"
                    value={values.isBillable}
                    error={errors.isBillable}
                    onChange={(val) => {
                      setFieldValue('isBillable', {
                        label: val.label,
                        value: val.value,
                      });
                    }}
                  />
                )}

                <ControlledTextArea
                  className={styles.CreateTimesheet_withMargin}
                  name="notes"
                  placeholder="Add Notes"
                  icon="description"
                  value={values.notes}
                  error={errors.notes}
                  onChange={(e) => setFieldValue('notes', e.target.value)}
                />

                <div className={styles.CreateTimesheet_withTooltip}>
                  <Text type={textTypes.HEADING.XXXS}>Hourly Wage</Text>

                  <a
                    className={styles.CreateTimesheet_withTooltip_tooltipLink}
                    data-tip
                    data-for="hourlyWageTooltip"
                  >
                    <Icon
                      icon="help"
                      className={styles.CreateTimesheet_withTooltip_tooltipIcon}
                    />
                  </a>

                  <ReactTooltip
                    className={styles.CreateTimesheet_withTooltip_tooltip}
                    id="hourlyWageTooltip"
                    type="info"
                    effect="solid"
                  >
                    <span>
                      This is your hourly wage and is used to compute for your
                      total income for a timesheet entry.
                      <br />
                      <br />
                      The default value can be set in Settings &gt;&gt; Account
                      Information.
                    </span>
                  </ReactTooltip>
                </div>

                <ControlledInput
                  className={styles.CreateTimesheet_withMargin}
                  name="hourlyWage"
                  placeholder="Hourly Wage*"
                  value={`${values.hourlyWage}`}
                  onChange={(e) => {
                    setFieldValue('hourlyWage', e.target.value);
                    setTotalIncome(e.target.value * hours);
                  }}
                />

                <Text
                  type={textTypes.HEADING.XXXS}
                  className={styles.CreateTimesheet_withMargin}
                >
                  Costs
                </Text>

                <Costs
                  className={styles.CreateTimesheet_withMargin}
                  costs={costs}
                  setCosts={setCosts}
                />

                <Text
                  type={textTypes.HEADING.XXXS}
                  className={styles.CreateTimesheet_withMargin}
                >
                  Summary
                </Text>

                <div className={styles.CreateTimesheet_summary}>
                  <div className={styles.CreateTimesheet_summary_row}>
                    <div>
                      <Text
                        type={textTypes.BODY.SM}
                        className={styles.CreateTimesheet_summary_label}
                      >
                        Hours
                      </Text>

                      <Text
                        type={textTypes.HEADING.XXS}
                        className={styles.CreateTimesheet_summary_value}
                      >
                        {user?.hoursAndMinutesFormat
                          ? hoursWithDecimalToHoursAndMinutes(hours)
                          : Number(hours).toFixed(2)}
                      </Text>
                    </div>
                  </div>

                  <div className={styles.CreateTimesheet_summary_row}>
                    <div className={styles.CreateTimesheet_summary_row_group}>
                      <div>
                        <Text
                          type={textTypes.BODY.SM}
                          className={styles.CreateTimesheet_summary_label}
                        >
                          Total Cost
                        </Text>

                        <Text
                          type={textTypes.HEADING.XXS}
                          className={styles.CreateTimesheet_summary_value}
                        >
                          $
                          {Number(
                            costs?.reduce((a, b) => a + b.price, 0)
                          ).toFixed(2)}
                        </Text>
                      </div>
                    </div>
                  </div>

                  <div className={styles.CreateTimesheet_summary_row}>
                    <div className={styles.CreateTimesheet_summary_row_group}>
                      <div>
                        <Text
                          type={textTypes.BODY.SM}
                          className={styles.CreateTimesheet_summary_label}
                        >
                          Total Income
                        </Text>

                        <Text
                          type={textTypes.HEADING.XXS}
                          colorClass={colorClasses.GREEN['400']}
                          className={styles.CreateTimesheet_summary_value}
                        >
                          $
                          {!Number.isNaN(totalIncome)
                            ? totalIncome.toFixed(2)
                            : '0.00'}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.CreateTimesheet_buttonGroup}>
                  <Button
                    className={styles.CreateTimesheet_buttonGroup_button}
                    kind={buttonKinds.SUBMIT}
                    icon="add"
                    disabled={isTimesheetCreating}
                    onClick={() => { }}
                  >
                    <span
                      className={styles.CreateTimesheet_buttonGroup_buttonText}
                    >
                      Create Timesheet
                      {isTimesheetCreating && (
                        <Spinner
                          size={spinnerSizes.XS}
                          colorName={colorNames.WHITE}
                          className={styles.CreateTimesheet_buttonGroup_spinner}
                        />
                      )}
                    </span>
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      )}
    </div>
  );
};

CreateTimesheet.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
};

export default CreateTimesheet;
