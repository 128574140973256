import React from 'react';
import styles from './styles.module.scss';

import { Card, Shine, Separator } from '../../../../components/elements';

const Preloader = () => (
  <Card className={styles.Preloader}>
    <div className={styles.Preloader_header}>
      <Shine className={styles.Preloader_header_shine} />
      <Shine className={styles.Preloader_header_shine} />
    </div>

    <Separator className={styles.Preloader_separator} />

    <div className={styles.Preloader_body}>
      <Shine className={styles.Preloader_body_shine} />
      <Shine className={styles.Preloader_body_shine} />
    </div>
  </Card>
);

export default Preloader;
