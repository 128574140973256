const gridTypes = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
  FIVE: 'five',
  SIX:'six',
  FILTERGRID:'filtergrid',
  TWOBYTHREE:'twoByThree',
  ONEBYTHREE:'oneByThree',
};

export default gridTypes;
