import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './styles.module.scss';

import { EditableTime, EditableSelect, EditableTextarea } from '../..';
import { Grid, Icon, IconButton, Text } from '../../elements';
import { UserContext, TimesheetContext } from '../../../contexts';
import { AddToProjectModel } from '../../../components/modals';
import {
  colorClasses,
  textTypes,
  leaveTypes,
  userTypes,
} from '../../../globals';
import { useClients, useEmployees } from '../../../hooks';
import {
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
  
} from '../../../utils/datetime';
import { getHours } from '../../../utils/timesheets';
import EditablePrice from '../../Editable/Price';
import { UsersService } from '../../../services';
import useGetEmployee from '../../../hooks/useGetEmployee';

const TimesheetCarousel = ({
  totalHours,
  isAlignedWithRosters,
  setOverrideTimesheetsData,
  timesheetsForDay,
  totalTimesheets,
  viewType = "",
}) => {

  const { user } = useContext(UserContext);

  let isEmployer = true;
  if (user.userType === 'Employee') {
    isEmployer = false;
  }


  const getEmployeeDetail = useGetEmployee({
    // emailAddress: user.emailAddress,
    emailAddress: user.userType == "Employee" ? user.employerDetails.employerEmailAddress : user.emailAddress,
  });
 
 
  const { updateTimesheet, deleteTimesheet,timesheets } = useContext(TimesheetContext);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  const [isAddProject, setIsAddProject] = useState(false);
  const { clients } = useClients({
    userId: user.id
  }); 
 
  const [width,setWidth] = useState(160);

  const [is24Hour,setIs24Hour] = useState(false);
  useEffect(() => {
    if(getEmployeeDetail.employee){
      if(getEmployeeDetail.employee.employerDetails){
        const details = getEmployeeDetail.employee.employerDetails;
        
        if(details.is24Hour){
          setIs24Hour(details.is24Hour);
        }
      }else{
        const details = getEmployeeDetail.employee;

        if(details.is24Hour){
          setIs24Hour(details.is24Hour);
        }
      }
    }
  },[getEmployeeDetail.employee]);
  
  useEffect(() => {
    if(viewType === "daily"){
      setWidth(180);
    }
  },[viewType]);

  const handleRedirectToTheCreateTimesheet = (timesheetId) => {
    if (user?.userType === 'Employee') {
      window.location.href = '/employee/timesheets/create?timesheet=' + timesheetId;
    } else {
      window.location.href = '/employer/timesheets/create?timesheet=' + timesheetId;
    }

  }

  return (
    <div className={styles.TimesheetCarousel}>
      <Carousel
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={currentEntryIndex}
        width={width}
      >
        {timesheetsForDay.map(
          ({
            timesheetId,
            userId,
            date,
            startTimeDetails = {
              startTime: undefined,
              actualTime: undefined,
              location: undefined,
            },
            endTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
              location: undefined,
            },
            isLunchBreak,
            lunchBreakDuration,
            isTravelCharge,
            leaveType,
            publicHolidayMultiplier,
            notes,
            performedNotes,
            clientId,
            clientName,
            projectId,
            projectName,
            isBillable,
            wage,
            costs,
          }) => {
            const startTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(startTimeDetails.startTime)
            );
            const endTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(endTimeDetails.endTime)
            );

            const hours = Number(
              getHours(
                startTimeTimestamp,
                endTimeTimestamp,
                isLunchBreak,
                lunchBreakDuration,
                isTravelCharge,
                leaveType,
                publicHolidayMultiplier
              )
            );
           
            const isProjectTimesheet = projectId && clientId;
            function deleteCost(tempCost,index) {
              tempCost.splice(index, 1);
              updateTimesheet({
                timesheetId,
                costs: tempCost,
              })
              costs = tempCost;
            }
            return (
              <div className={styles.TimesheetCarousel_entry} key={timesheetId}>
                <div className={styles.TimesheetCarousel_actions}>

                  {projectId === undefined &&
                    isAlignedWithRosters === false && (
                      <IconButton
                        icon="info"
                        className={styles.TimesheetCarousel_actions_action}
                        onClick={() => setOverrideTimesheetsData()}
                      />
                    )}
                  {user.reassignTimesheet && (<IconButton
                    icon="add"
                    className={cn(
                      styles.TimesheetCarousel_actions_action,
                      styles.TimesheetCarousel_addNew
                    )}
                    onClick={() => setIsAddProject(true)}
                  />
                  )}
                  <IconButton
                    icon="copy"
                    className={cn(
                      styles.TimesheetCarousel_actions_action,
                      styles.TimesheetCarousel_addNew
                    )}
                    onClick={() => { handleRedirectToTheCreateTimesheet(timesheetId) }}
                  />
                  <IconButton
                    icon="delete"
                    className={cn(
                      styles.TimesheetCarousel_actions_action,
                      styles.TimesheetCarousel_delete
                    )}
                    onClick={() => {
                      deleteTimesheet(timesheetId);
                      setCurrentEntryIndex(0);
                    }}
                  />
                  {isAddProject && (
                    <AddToProjectModel
                      isOpen={isAddProject}
                      handleClose={() => setIsAddProject(false)}
                      title='Add to Project'
                      client={clients}
                      timesheetId={timesheetId}
                      date={date}
                      userId={userId}
                    />
                  )}



                </div>
                {!window.location.href.includes('projects') && (
                  <>
                    <div className={styles.TimesheetCarousel_informationGroup}>
                      <Text className={styles.TimesheetCarousel_boldText}>
                        CLIENT:
                      </Text>
                      <Text
                        className={cn(styles.TimesheetCarousel_projectName, {
                          [styles.TimesheetCarousel_projectName___isProjectTimesheet]:
                            isProjectTimesheet,
                        })}
                        type={textTypes.BODY.MD}
                      >
                        {isProjectTimesheet ? clientName : 'None'}
                      </Text>
                    </div>

                    <div className={styles.TimesheetCarousel_informationGroup}>
                      <Text className={styles.TimesheetCarousel_boldText}>
                        PROJECT:
                      </Text>
                      <Text
                        className={cn(styles.TimesheetCarousel_projectName, {
                          [styles.TimesheetCarousel_projectName___isProjectTimesheet]:
                            isProjectTimesheet,
                        })}
                        type={textTypes.BODY.MD}
                      >
                        {isProjectTimesheet ? projectName : 'None'}
                      </Text>
                    </div>

                    <div className={styles.TimesheetCarousel_separator} />
                  </>
                )}

                <Grid className={styles.TimesheetCarousel_informationGrid}>
                  <Text className={styles.TimesheetCarousel_boldText}>
                    TOTAL
                  </Text>
                  <Text className={styles.TimesheetCarousel_boldText}>
                    { totalHours > 0 
                      ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                        ? hoursWithDecimalToHoursAndMinutes(
                          totalHours.toFixed(2)
                        )
                        :  totalHours.toFixed(2)
                       
                      : 0}
                  </Text>
                </Grid>

                <div className={styles.TimesheetCarousel_separator} />

                <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid
                    className={cn(
                      styles.TimesheetCarousel_informationGrid,
                      styles.TimesheetCarousel_informationEditable
                    )}
                  >
                    <Text>Start</Text>

                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <EditableTime
                        initialValue={startTimeDetails.startTime}
                        is24Hour={is24Hour}
                        onSave={async (val) => {
                          const isSuccessfulSave = await updateTimesheet({
                            timesheetId,
                            userId,
                            date,
                            startTimeDetails: {
                              startTime: val,
                            },
                          });

                          return isSuccessfulSave;
                        }}
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Actual</Text>
                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <Text>
                        {startTimeDetails.actualTime
                          ? startTimeDetails.actualTime
                          : 'Uneditable'}
                      </Text>
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  {user?.showLocation && (
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>Location</Text>
                      {startTimeDetails?.location?.address ? (
                        <Text>{startTimeDetails?.location?.address}</Text>
                      ) : (
                        <Text>Uneditable</Text>
                      )}
                    </Grid>
                  )}
                </div>

                <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid
                    className={cn(
                      styles.TimesheetCarousel_informationGrid,
                      styles.TimesheetCarousel_informationEditable
                    )}
                  >
                    <Text>End</Text>
                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <EditableTime
                        initialValue={endTimeDetails.endTime}
                        is24Hour={is24Hour}
                        onSave={async (val) => {
                          const isSuccessfulSave = await updateTimesheet({
                            timesheetId, 
                            userId,
                            date,
                            endTimeDetails: {
                              endTime: val,
                            },
                            
                            // lunchBreakDuration:null,
                          });
                          return isSuccessfulSave;
                        }}
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Actual</Text>
                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <Text>
                        {endTimeDetails.actualTime
                          ? endTimeDetails.actualTime
                          : 'Uneditable'}
                      </Text>
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>

                  {user?.showLocation && (
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>Location</Text>
                      {endTimeDetails?.location?.address ? (
                        <Text>{endTimeDetails?.location?.address}</Text>
                      ) : (
                        <Text>Uneditable</Text>
                      )}
                    </Grid>
                  )}
                </div>

                {/* <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Lunch</Text>
                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <EditableSelect
                        options={[
                          {
                            label: 'Yes',
                            value: 'Yes',
                          },
                          {
                            label: 'No',
                            value: 'No',
                          },
                        ]}
                        initialValue={isLunchBreak ? 'Yes' : 'No'}
                        onSave={(val) =>
                          updateTimesheet({
                            timesheetId,
                            isLunchBreak: val === 'Yes',
                          })
                        }
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>
                </div> */}
                 <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Lunch</Text>
                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <EditableTime
                        initialValue={lunchBreakDuration}
                        placeholder={'empty'}
                        is24Hour={true}
                        onSave={async (val) => {
                          const isSuccessfulSave = await updateTimesheet({
                            timesheetId,
                            userId,
                            date,
                            isLunchBreak:true,
                            lunchBreakDuration:val,
                            startTimeDetails,
                            endTimeDetails,
                            totalHours
                          });
                          return isSuccessfulSave;

                        }}
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>
                </div>
                {(isEmployer ? user?.isTravelChargeByDefault : getEmployeeDetail.employee?.employerDetails?.isTravelChargeByDefault) && <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Travel Charge</Text>
                    {leaveType === leaveTypes.NONE ||
                      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
                      leaveType === leaveTypes.SICK ? (
                      <EditableSelect
                        options={[
                          {
                            label: 'Yes',
                            value: 'Yes',
                          },
                          {
                            label: 'No',
                            value: 'No',
                          },
                        ]}
                        initialValue={isTravelCharge ? 'Yes' : 'No'}
                        onSave={(val) =>
                          updateTimesheet({
                            timesheetId,
                            isTravelCharge: val === 'Yes',
                          })
                        }
                      />
                    ) : (
                      <Text>Uneditable</Text>
                    )}
                  </Grid>
                </div>
                }
                <div className={styles.TimesheetCarousel_informationGroup}>
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text>Leave</Text>
                    <EditableSelect
                      options={[
                        {
                          label: leaveTypes.NONE,
                          value: leaveTypes.NONE,
                        },
                        {
                          label: leaveTypes.ANNUAL,
                          value: leaveTypes.ANNUAL,
                        },
                        {
                          label: leaveTypes.PUBLIC_HOLIDAY,
                          value: leaveTypes.PUBLIC_HOLIDAY,
                        },
                        {
                          label: leaveTypes.SICK,
                          value: leaveTypes.SICK,
                        },
                        {
                          label: leaveTypes.WITHOUT_PAY,
                          value: leaveTypes.WITHOUT_PAY,
                        },
                      ]}
                      initialValue={leaveType}
                      onSave={(val) =>
                        updateTimesheet({
                          timesheetId,
                          leaveType: val,
                        })
                      }
                    />
                  </Grid>
                </div>

                {projectId && user.userType === userTypes.EMPLOYER && (
                  <div className={styles.TimesheetCarousel_informationGroup}>
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>Billable</Text>
                      <EditableSelect
                        options={[
                          {
                            label: 'Yes',
                            value: 'Yes',
                          },
                          {
                            label: 'No',
                            value: 'No',
                          },
                        ]}
                        initialValue={isBillable ? 'Yes' : 'No'}
                        onSave={(val) =>
                          updateTimesheet({
                            timesheetId,
                            isBillable: val === 'Yes',
                          })
                        }
                      />
                    </Grid>
                  </div>
                )}

                <div
                  className={cn(
                    styles.TimesheetCarousel_informationGroup,
                    styles.TimesheetCarousel_noMargin
                  )}
                >
                  <Grid className={styles.TimesheetCarousel_informationGrid}>
                    <Text className={styles.TimesheetCarousel_boldText}>
                      Hours
                    </Text>
                    <Text className={styles.TimesheetCarousel_boldText}>
                      {(user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                        ? hoursWithDecimalToHoursAndMinutes(hours.toFixed(2))
                        : `${(hours.toFixed(2))}`}
                    </Text>
                  </Grid>
                </div>

                {/* <div className={styles.TimesheetCarousel_separator} /> */}

                {/* <div className={styles.TimesheetCarousel_informationGroup}>
                  <Text className={styles.TimesheetCarousel_boldText}>
                    INCOME:
                  </Text>

                  <Text
                    className={styles.TimesheetCarousel_boldText}
                    type={textTypes.BODY.MD}
                    colorClass={colorClasses.GREEN[400]}
                  >
                    $
                    {(
                      Number(wage) *
                      getHours(
                        startTimeTimestamp,
                        endTimeTimestamp,
                        isLunchBreak,
                        leaveType,
                        publicHolidayMultiplier
                      )
                    ).toFixed(2)}
                  </Text>
                </div> */}


                <div className={styles.TimesheetCarousel_separator} />
                <div className={styles.TimesheetCarousel_informationGroup}>
                  <Text className={styles.TimesheetCarousel_boldText}
                    colorClass={colorClasses.GREEN[400]}
                    type={textTypes.BODY.MD}
                  >
                    COSTS:
                  </Text>
                </div>
                {costs?.length > 0 &&
                  costs.map((cost, index) =>
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text>{cost.itemName}</Text>
                      {/* <Text>${Number(cost.price).toFixed(2)}</Text> */}
                      <EditablePrice
                        initialValue={cost.price === '' || !cost.price ? null : cost.price}
                        onSave={(val) => {
                          costs[index].price = val;
                          updateTimesheet({
                            timesheetId,
                            costs: costs,
                          })
                        }
                        }
                      />
                      <IconButton
                        icon="delete"
                        className={cn(
                          styles.TimesheetCarousel_actions_action,
                          styles.TimesheetCarousel_deleteCost
                        )}
                        onClick={() => deleteCost(costs,index)}
                      />
                    </Grid>
                  )
                }

                {(isEmployer ? !user?.hideWage : !getEmployeeDetail.employee?.employerDetails?.hideWage) &&
                  <>
                    <div className={styles.TimesheetCarousel_separator} />
                    <Grid className={styles.TimesheetCarousel_informationGrid}>
                      <Text className={styles.TimesheetCarousel_boldText}>Wage</Text>
                      <Text>${Number(wage).toFixed(2)}</Text>
                    </Grid>
                  </>
                }
                <div className={styles.TimesheetCarousel_separator} />

                {(isEmployer ? getEmployeeDetail.employee?.isWorkPerformed : getEmployeeDetail.employee?.employerDetails?.isWorkPerformed) && 
                <>
                <div
                  className={cn(
                    styles.TimesheetCarousel_informationGroup,
                    styles.TimesheetCarousel_noMargin
                  )}
                >
                  <Text className={styles.TimesheetCarousel_boldText}
                    colorClass={colorClasses.BLUE[400]}
                    type={textTypes.BODY.MD}
                  >
                    Performed For:
                  </Text>
                  <EditableTextarea
                    initialValue={performedNotes === '' || !performedNotes ? null : performedNotes}
                    value={performedNotes}
                    onSave={(val) =>
                      updateTimesheet({
                        timesheetId,
                        performedNotes: val,
                      })
                    }
                  />
                </div>
                </>
        }
                <div
                  className={cn(
                    styles.TimesheetCarousel_informationGroup,
                    styles.TimesheetCarousel_noMargin
                  )}
                >
                  <Text className={styles.TimesheetCarousel_boldText}
                    colorClass={colorClasses.BLUE[400]}
                    type={textTypes.BODY.MD}
                  >
                    Work Performed:
                  </Text>
                  <EditableTextarea
                    initialValue={notes === '' || !notes ? null : notes}
                    value={notes}
                    onSave={(val) =>
                      updateTimesheet({
                        timesheetId,
                        notes: val,
                      })
                    }
                  />
                </div>
              </div>
            );
          }
        )}
      </Carousel>

      {totalTimesheets > 1 && (
        <div className={styles.TimesheetCarousel_carousel_buttons}>
          <button
            type="button"
            className={styles.TimesheetCarousel_carousel_button}
            onClick={() => {
              if (currentEntryIndex !== 0) {
                setCurrentEntryIndex(currentEntryIndex - 1);
              }
            }}
          >
            <Icon icon="chevron_left" />
          </button>

          <Text type={textTypes.BODY.LG}>
            {currentEntryIndex + 1}/{totalTimesheets}
          </Text>

          <button
            type="button"
            className={styles.TimesheetCarousel_carousel_button}
            onClick={() => {
              if (currentEntryIndex + 1 !== totalTimesheets) {
                setCurrentEntryIndex(currentEntryIndex + 1);
              }
            }}
          >
            <Icon icon="chevron_right" />
          </button>
        </div>
      )}
    </div>
  );
};

TimesheetCarousel.defaultProps = {
  isAlignedWithRosters: null,
  setOverrideTimesheetsData: null,
};

TimesheetCarousel.propTypes = {
  totalHours: PropTypes.number.isRequired,
  isAlignedWithRosters: PropTypes.bool,
  setOverrideTimesheetsData: PropTypes.func,
  timesheetsForDay: PropTypes.array.isRequired,
  totalTimesheets: PropTypes.number.isRequired,
};

export default TimesheetCarousel;
