import { useState } from 'react';

import { VehiclesService } from '../services';

const useUpdateVehicle = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const updateVehicle = async (vehicleId, newVehicle) => {
    setIsUpdating(true);

    let responseCode;

    try {
      const response = await VehiclesService.update({
        vehicleId,
        body: { ...newVehicle },
      });
      responseCode = response.status;
    } catch (error) {
      responseCode = error.response.status;
    }

    setIsUpdating(false);

    return { responseCode };
  };

  return { isUpdating, updateVehicle };
};

export default useUpdateVehicle;
