import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import dateFormat from 'dateformat';
import styles from './styles.module.scss';

import {
  Button,
  ControlledInput,
  ControlledSelect,
  DatePicker,
  Text,
  Spinner,
} from '../../../../components/elements';
import {
  buttonKinds,
  colorNames,
  inputTypes,
  selectTypes,
  textTypes,
  spinnerSizes,
} from '../../../../globals';
import { UserContext } from '../../../../contexts';
import { UsersService } from '../../../../services';
import { isValidEmail } from '../../../../utils/string';
import useGetEmployee from "../../../../hooks/useGetEmployee";
import reportingColumns from '../constants/reportingColumns';

const validate = (values) => {
  const errors = {};

  if (!values.timesheetStartDate) {
    errors.timesheetStartDate = 'This field is required.';
  }

  if (!values.timesheetStartWeek) {
    errors.timesheetStartWeek = 'This field is required.';
  }

  if (!values.isLunchBreakByDefault) {
    errors.isLunchBreakByDefault = 'This field is required.';
  }

  if (!values.isTravelChargeByDefault) {
    errors.isTravelChargeByDefault = 'This field is required.';
  }
  if (!values.hideWage) {
    errors.hideWage = 'This field is required.';
  }
  if (!values.is24Hour) {
    errors.is24Hour = 'This field is required.';
  }
  if (!values.isProjectClient) {
    errors.isProjectClient = 'This field is required.';
  }
  if (!values.isNoteNeeded) {
    errors.isNoteNeeded = 'This field is required.';
  }
  if (!values.isWorkPerformed) {
    errors.isWorkPerformed = 'This field is required.';
  }
  if (!values.reassignTimesheet) {
    errors.reassignTimesheet = 'This field is required.';
  }

  if (!values.showXero) {
    errors.showXero = 'This field is required.';
  }

  if (!values.showLocation) {
    errors.showLocation = 'This field is required.';
  }

  if (!values.hoursAndMinutesFormat) {
    errors.hoursAndMinutesFormat = 'This field is required.';
  }

  if (
    values.weeklyReportEmailAddress &&
    !isValidEmail(values.weeklyReportEmailAddress)
  ) {
    errors.weeklyReportEmailAddress = 'This must be a valid email address.';
  }

  return errors;
};

const Timesheets = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
    const {isLoading: isUserLoading, employee: getEmployeeDetail} = useGetEmployee({
        emailAddress: user.emailAddress,
    });
    
  const [isUpdating, setIsUpdating] = useState(false);

  const [valueReportingArray, setValueReportingArray] = useState([]);

  useEffect(() => {
    if(user.reportingColumns !== undefined && user.reportingColumns.length > 0){
      const matchingArray = reportingColumns.filter(
        (item) => user.reportingColumns.includes(item.value)
      );
      setValueReportingArray(matchingArray);
    }
  },[user.reportingColumns]);

  return (
    <div className={styles.Timesheets}>
      <Text type={textTypes.HEADING.SM} className={styles.Timesheets_title}>
        Timesheets
      </Text>

      {
        !isUserLoading && (
      <Formik
        initialValues={{
          timesheetStartDate: new Date(user.timesheetStartDate),
          timesheetStartWeek: {
            label: user.timesheetStartWeek,
            value: user.timesheetStartWeek,
          },
          
          isLunchBreakByDefault: {
            label: getEmployeeDetail?.isLunchBreakByDefault ? 'Yes' : 'No',
            value: getEmployeeDetail?.isLunchBreakByDefault,
          },
          isTravelChargeByDefault: {
            label: getEmployeeDetail?.isTravelChargeByDefault ? 'Yes' : 'No',
            value: getEmployeeDetail?.isTravelChargeByDefault,
          },
          hideWage: {
            label: getEmployeeDetail?.hideWage ? 'Yes' : 'No',
            value: getEmployeeDetail?.hideWage,
          },
          is24Hour: {
            label: getEmployeeDetail?.is24Hour ? 'Yes' : 'No',
            value: getEmployeeDetail?.is24Hour,
          },
          isProjectClient: {
            label: getEmployeeDetail?.isProjectClient ? 'Yes' : 'No',
            value: getEmployeeDetail?.isProjectClient,
          },
          isNoteNeeded: {
            label: getEmployeeDetail?.isNoteNeeded ? 'Yes' : 'No',
            value: getEmployeeDetail?.isNoteNeeded,
          },
          isWorkPerformed: {
            label: getEmployeeDetail?.isWorkPerformed ? 'Yes' : 'No',
            value: getEmployeeDetail?.isWorkPerformed,
          },
          reassignTimesheet: {
            label: getEmployeeDetail?.reassignTimesheet ? 'Yes' : 'No',
            value: getEmployeeDetail?.reassignTimesheet,
          },
          showXero: {
            label: getEmployeeDetail?.showXero ? 'Yes' : 'No',
            value: getEmployeeDetail?.showXero,
          },
          showLocation: {
            label: getEmployeeDetail?.showLocation ? 'Yes' : 'No',
            value: getEmployeeDetail?.showLocation,
          },
          hoursAndMinutesFormat: {
            label: getEmployeeDetail?.hoursAndMinutesFormat ? 'Yes' : 'No',
            value: getEmployeeDetail?.hoursAndMinutesFormat,
          },
          allowEditOldTimesheet: {
            label: getEmployeeDetail?.allowEditOldTimesheet ? 'Yes' : 'No',
            value: getEmployeeDetail?.allowEditOldTimesheet,
          },
          weeklyReportEmailAddress: user?.weeklyReportEmailAddress
            ? user?.weeklyReportEmailAddress
            : null,
          reportingColumns: valueReportingArray,
          employeeCanCreateProject: {
            label: getEmployeeDetail?.employeeCanCreateProject ? 'Yes' : 'No',
            value: getEmployeeDetail?.employeeCanCreateProject
          },
          narrowTitles: {
            label: getEmployeeDetail?.narrowTitles ? 'Yes' : 'No',
            value: getEmployeeDetail?.narrowTitles,
          }
        }}
        onSubmit={async (values, { setErrors }) => {
          const currentFormValues = {
            timesheetStartDate: dateFormat(
              values.timesheetStartDate,
              'yyyy-mm-dd'
            ),
            timesheetStartWeek: values.timesheetStartWeek.value,
            isLunchBreakByDefault: values.isLunchBreakByDefault.value,
            isTravelChargeByDefault: values.isTravelChargeByDefault.value 
            ? values.isTravelChargeByDefault.value
            : false,
            hideWage: values.hideWage.value 
            ? values.hideWage.value
            : false,
            is24Hour: values.is24Hour.value 
            ? values.is24Hour.value
            : false,
            isProjectClient: values.isProjectClient.value 
            ? values.isProjectClient.value
            : false,
            isNoteNeeded: values.isNoteNeeded.value 
            ? values.isNoteNeeded.value
            : false,
            isWorkPerformed: values.isWorkPerformed.value 
            ? values.isWorkPerformed.value
            : false,
            reassignTimesheet: values.reassignTimesheet.value
            ? values.reassignTimesheet.value
            : false,
            showXero: values.showXero.value,
            showLocation: values.showLocation.value
              ? values.showLocation.value
              : false,
            hoursAndMinutesFormat: values.hoursAndMinutesFormat.value
              ? values.hoursAndMinutesFormat.value
              : false,
              allowEditOldTimesheet: values.allowEditOldTimesheet.value
              ? values.allowEditOldTimesheet.value
              : false,
            weeklyReportEmailAddress: values.weeklyReportEmailAddress
              ? values.weeklyReportEmailAddress
              : null,
            reportingColumns: values.reportingColumns ? values.reportingColumns : [],
            employeeCanCreateProject: values.employeeCanCreateProject.value ? values.employeeCanCreateProject.value : false,
            narrowTitles: values.narrowTitles.value ? values.narrowTitles.value : false,
          };

          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          setIsUpdating(true);

          // Update the user
          const { data: updateResponse } =
            await UsersService.updateTimesheetSettings({
              userId: user.id,
              userType: user.userType,
              ...currentFormValues,
            });

          if (updateResponse.message === 'timesheet_settings_updated') {
            // Show an alert
            alert.success('Timesheet Settings updated!');
            // Update user state
            loginUpdate({ ...user, ...currentFormValues });
          } else {
            alert.error('Oops, something went wrong.');
          }

          setIsUpdating(false);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <DatePicker
              type={inputTypes.SLIM}
              label="Timesheet Start Date* (For Weekly and Fortnightly Views)"
              className={styles.Timesheets_input}
              dateFormat="dd/MM/yyyy"
              selected={values.timesheetStartDate}
              name="timesheetStartDate"
              icon="today"
              onChange={(date) => {
                setFieldValue('timesheetStartDate', date);
              }}
              disableFutureDates
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Timesheet Start Week* (For Monthly View)"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Sunday',
                  value: 'Sunday',
                },
                {
                  label: 'Monday',
                  value: 'Monday',
                },
                {
                  label: 'Tuesday',
                  value: 'Tuesday',
                },
                {
                  label: 'Wednesday',
                  value: 'Wednesday',
                },
                {
                  label: 'Thursday',
                  value: 'Thursday',
                },
                {
                  label: 'Friday',
                  value: 'Friday',
                },
                {
                  label: 'Saturday',
                  value: 'Saturday',
                },
              ]}
              name="timesheetStartWeek"
              value={values.timesheetStartWeek}
              error={errors.timesheetStartWeek}
              onChange={(val) => {
                setFieldValue('timesheetStartWeek', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Lunch Break By Default*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="isLunchBreakByDefault"
              value={values.isLunchBreakByDefault}
              error={errors.isLunchBreakByDefault}
              onChange={(val) => {
                setFieldValue('isLunchBreakByDefault', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Travel Charge By Default*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="isTravelChargeByDefault"
              value={values.isTravelChargeByDefault}
              error={errors.isTravelChargeByDefault}
              onChange={(val) => {
                setFieldValue('isTravelChargeByDefault', {
                  label: val.label,
                  value: val.value,
                });
              }}
            /> 
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Hide wage*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="hideWage"
              value={values.hideWage}
              error={errors.hideWage}
              onChange={(val) => {
                setFieldValue('hideWage', {
                  label: val.label,
                  value: val.value,
                });
              }}
            /> 
            <ControlledSelect
              type={selectTypes.SLIM}
              label="24 Hour*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="is24Hour"
              value={values.is24Hour}
              error={errors.is24Hour}
              onChange={(val) => {
                setFieldValue('is24Hour', {
                  label: val.label,
                  value: val.value,
                });
              }}
            /> 
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Project/Client Needed*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="isProjectClient"
              value={values.isProjectClient}
              error={errors.isProjectClient}
              onChange={(val) => {
                setFieldValue('isProjectClient', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Notes Needed*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="isNoteNeeded"
              value={values.isNoteNeeded}
              error={errors.isNoteNeeded}
              onChange={(val) => {
                setFieldValue('isNoteNeeded', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Work Performed*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="isWorkPerformed"
              value={values.isWorkPerformed}
              error={errors.isWorkPerformed}
              onChange={(val) => {
                setFieldValue('isWorkPerformed', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Reassign Timesheet*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="reassignTimesheet"
              value={values.reassignTimesheet}
              error={errors.reassignTimesheet}
              onChange={(val) => {
                setFieldValue('reassignTimesheet', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Show XERO*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="showXero"
              value={values.showXero}
              error={errors.showXero}
              onChange={(val) => {
                setFieldValue('showXero', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Show Location*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="showLocation"
              value={values.showLocation}
              error={errors.showLocation}
              onChange={(val) => {
                setFieldValue('showLocation', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Employee Can create Project?"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="employeeCanCreateProject"
              value={values.employeeCanCreateProject}
              onChange={(val) => {
                setFieldValue('employeeCanCreateProject', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Reporting Columns"
              className={styles.Timesheets_input}
              options={reportingColumns}
              name="reportingColumns"
              isMulti
              value={values.reportingColumns}
              onChange={ (val) => { setFieldValue('reportingColumns', val) } }
            />

            <ControlledSelect
                type={selectTypes.SLIM}
                label="Narrow Titles"
                className={styles.Timesheets_input}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                name="narrowTitles"
                value={values.narrowTitles}
                error={errors.narrowTitles}
                onChange={(val) => {
                  setFieldValue('narrowTitles', {
                    label: val.label,
                    value: val.value,
                  });
                }}
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Display Hours in Hours and Minutes Format*"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="hoursAndMinutesFormat"
              value={values.hoursAndMinutesFormat}
              error={errors.hoursAndMinutesFormat}
              onChange={(val) => {
                setFieldValue('hoursAndMinutesFormat', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Allows employees to edit timesheets older than the current week?"
              className={styles.Timesheets_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="allowEditOldTimesheet"
              value={values.allowEditOldTimesheet}
              error={errors.allowEditOldTimesheet}
              onChange={(val) => {
                setFieldValue('allowEditOldTimesheet', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <ControlledInput
              type={inputTypes.SLIM}
              label="Add an email address that will receive a weekly report every week"
              name="weeklyReportEmailAddress"
              value={values.weeklyReportEmailAddress}
              error={errors.weeklyReportEmailAddress}
              onChange={(e) =>
                setFieldValue('weeklyReportEmailAddress', e.target.value)
              }
            />

            <div className={styles.Timesheets_buttonGroup}>
              <Button
                kind={buttonKinds.SUBMIT}
                disabled={
                  isUpdating ||
                  (dateFormat(values.timesheetStartDate, 'dd/mm/yyyy') ===
                    dateFormat(user.timesheetStartDate, 'dd/mm/yyyy') &&
                    values.timesheetStartWeek.value ===
                      user.timesheetStartWeek &&
                    values.isLunchBreakByDefault.value ===
                      user.isLunchBreakByDefault &&
                      values.isTravelChargeByDefault.value ===
                      user.isTravelChargeByDefault &&
                    values.showXero === user.showXero &&
                    values.showLocation === user.showLocation &&
                    values.hoursAndMinutesFormat ===
                      user.hoursAndMinutesFormat &&
                    values.allowEditOldTimesheet ===
                      user.allowEditOldTimesheet &&
                    values.weeklyReportEmailAddress ===
                      user.weeklyReportEmailAddress)
                }
                onClick={() => {}}
              >
                <span className={styles.Timesheets_buttonGroup_buttonText}>
                  Update
                  {isUpdating && (
                    <Spinner
                      size={spinnerSizes.XS}
                      colorName={colorNames.WHITE}
                      className={styles.Timesheets_buttonGroup_spinner}
                    />
                  )}
                </span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
        )
      }
      
    </div>
  );
};

export default Timesheets;
