import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import { ControlledSelect, Modal } from '../../elements';
import { buttonTypes, selectTypes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useProjects, useUpdateTimesheet } from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.client) {
    errors.client = 'This field is required.';
  } 
  if (!values.project) {
    errors.project = 'This field is required.';
  } 

  return errors;
};

const AddToProjectModel = ({ 
  isOpen, 
  handleClose, 
  title, 
  client, 
  timesheetId, 
  date, 
  userId 
}) => {

  const { isUpdating, updateTimesheet } = useUpdateTimesheet();
  
  const { user } = useContext(UserContext);
  const formRef = useRef();
  const [clientValue, setClintValue] = useState("");
  const [projectId, setProjectValue] = useState("");
  const [clientName,setClient] = useState("");
  const [projectName,setProjectName] = useState("");

    const { projects }  = useProjects({
      clientId: clientValue,
      isFromCreateTimeSheet: true,
    })
  


  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      buttonSize={true}
      actions={[
        {
          text: '+ add to project',
          type: buttonTypes.PRIMARY.GREEN,
          onClick: () => {
             formRef.current.handleSubmit();
             window.location.reload(true);
          },
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          client: '',
          project: '',
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const { message } = await updateTimesheet({
            userId,
            timesheetId,
            date,
            projectId,
            clientId: clientValue,
            clientName,
            projectName
          });

          if(message === 'timesheet_updated'){

          }

          // Close the modal
          handleClose();
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <>
            <ControlledSelect
              className={styles.AddToProjectModel_withMarginBottom}
              type={selectTypes.SLIM}
              options={client.map((client) => ({
                value: client.id,
                label: client.name,
              }))}
              name="client"
              placeholder="-- Select Client --"
              value={values.client}
              error={errors.client}
              onChange={(val) => {
                setFieldValue('client', val);
                setClintValue(val.value.toString());
                setClient(val.label);
              }}
            />
            { projects.length > 0 && <ControlledSelect
              className={styles.AddToProjectModel_withMarginBottom}
              type={selectTypes.SLIM}
              options={projects.map((project) => ({
                value: project.id,
                label: project.name,
              }))}
              placeholder="-- Select Project --"
              name="project"
              value={values.project}
              onChange={(val) => {
                setFieldValue('project', val);
                setProjectValue(val.value);
                setProjectName(val.label);
              }}
            />}
          </>
        )}
      </Formik>
    </Modal>
  );
};

AddToProjectModel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddToProjectModel;
