import React, { useState, useContext } from 'react';
import cn from 'classnames';
import dateFormat from 'dateformat';
import styles from './styles.module.scss';

import { Breadcrumbs, RosterGrid } from '../../../components';
import { Button, Text } from '../../../components/elements';
import { textTypes, timesheetTypes } from '../../../globals';
import { RosterContext, UserContext } from '../../../contexts';
import { useRosters } from '../../../hooks';
import {
  getPrevOrAfterDate,
  isMiddleBetweenDates,
} from '../../../utils/datetime';
import { getDateEquivalence, generateDayIds } from '../../../utils/timesheets';

const dateToday = dateFormat(new Date(), 'yyyy-mm-dd');

const Rosters = () => {
  const { user } = useContext(UserContext);

  const startDateBasis = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.employerDetails.timesheetStartDate,
    'start'
  );
  const endDateBasis = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.employerDetails.timesheetStartDate,
    'end'
  );

  const [startDate, setStartDate] = useState(startDateBasis);
  const [endDate, setEndDate] = useState(endDateBasis);

  const dayIds = generateDayIds(
    timesheetTypes.WEEKLY,
    startDate,
    user.employerDetails.timesheetStartWeek
  );

  const { isRostersLoading, rosters } = useRosters({
    employeeId: user.id,
    employerId: user.employerDetails.employerId,
    startDate,
    endDate,
  });

  return (
    <RosterContext.Provider
      value={{
        dayIds,
        isRostersLoading,
        rosters,
      }}
    >
      <div className={styles.Rosters}>
        <div className={styles.Rosters_firstRow}>
          <Breadcrumbs
            pageTitle="Rosters"
            pages={[
              {
                name: 'Dashboard',
                link: '/employee/dashboard',
              },
            ]}
          />
        </div>

        <div className={styles.Rosters_secondRow}>
          <Button
            className={styles.Rosters_secondRow_item}
            icon="arrow_back"
            onClick={() => {
              setStartDate(getPrevOrAfterDate(startDate, 7, 'subtract'));
              setEndDate(getPrevOrAfterDate(endDate, 7, 'subtract'));
            }}
          >
            <span className={styles.Rosters_secondRow_item_buttonText}>
              Prev Week
            </span>
          </Button>

          <Text
            className={styles.Rosters_secondRow_item}
            type={textTypes.HEADING.XS}
          >
            {dateFormat(new Date(startDate), 'mmm d')} -{' '}
            {dateFormat(new Date(endDate), 'mmm d, yyyy')}
          </Text>

          {!isMiddleBetweenDates(
            startDate,
            endDate,
            getPrevOrAfterDate(dateToday, 7, 'add')
          ) && (
            <Button
              className={styles.Rosters_secondRow_item}
              icon="arrow_forward"
              iconPosition="right"
              onClick={() => {
                setStartDate(getPrevOrAfterDate(startDate, 7, 'add'));
                setEndDate(getPrevOrAfterDate(endDate, 7, 'add'));
              }}
            >
              <span className={styles.Rosters_secondRow_item_buttonText}>
                Next Week
              </span>
            </Button>
          )}
        </div>

        <div
          className={cn(styles.Rosters_thirdRow, {
            [styles.Rosters_thirdRow___active]:
              startDate === startDateBasis ||
              startDate === getPrevOrAfterDate(startDateBasis, 7, 'add'),
          })}
        >
          <RosterGrid />
        </div>
      </div>
    </RosterContext.Provider>
  );
};

export default Rosters;
