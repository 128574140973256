import { useState, useEffect } from 'react';

import { ClientsService } from '../services';

const useClient = ({ clientId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState(null);

  useEffect(() => {
    const getClient = async () => {
      const { data: getClientResponse } = await ClientsService.singleGet({
        clientId,
      });

      if (getClientResponse.client) {
        setClient(getClientResponse.client);
      }

      setIsLoading(false);
    };

    if (clientId) {
      getClient();
    } else {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, client };
};

export default useClient;
