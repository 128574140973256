import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import {
  Button,
  ControlledSelect,
  Text,
  Spinner,
} from '../../../../components/elements';
import {
  buttonKinds,
  colorNames,
  selectTypes,
  textTypes,
  spinnerSizes,
} from '../../../../globals';
import { UserContext } from '../../../../contexts';
import { UsersService } from '../../../../services';

const validate = (values) => {
  const errors = {};

  if (!values.showVehiclesTab) {
    errors.showVehiclesTab = 'This field is required.';
  }

  return errors;
};

const Vehicles = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <div className={styles.Vehicles}>
      <Text type={textTypes.HEADING.SM} className={styles.Vehicles_title}>
        Vehicles
      </Text>

      <Formik
        initialValues={{
          showVehiclesTab: {
            label: user.showVehiclesTab ? 'Yes' : 'No',
            value: user.showVehiclesTab,
          },
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const currentFormValues = {
            showVehiclesTab: values.showVehiclesTab.value,
          };

          setIsUpdating(true);

          const { status: updateVehiclesSettingsResponseCode } =
            await UsersService.updateVehiclesSettings({
              employerId: user.id,
              ...currentFormValues,
            });

          const updateVehiclesSettingsCallbacks = {
            updated: () => {
              alert.success('Vehicles settings updated!');

              loginUpdate({ ...user, ...currentFormValues });
            },
            invalidFields: () => alert.error('Invalid fields.'),
            internalError: () => alert.error('Oops, something went wrong.'),
          };

          switch (updateVehiclesSettingsResponseCode) {
            case 200:
              updateVehiclesSettingsCallbacks.updated();
              break;
            case 400:
              updateVehiclesSettingsCallbacks.invalidFields();
              break;
            case 500:
              updateVehiclesSettingsCallbacks.internalError();
              break;
            default:
              break;
          }

          setIsUpdating(false);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Show Vehicles Tab*"
              className={styles.Vehicles_input}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              name="showVehiclesTab"
              value={values.showVehiclesTab}
              error={errors.showVehiclesTab}
              onChange={(val) => {
                setFieldValue('showVehiclesTab', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />

            <div className={styles.Vehicles_buttonGroup}>
              <Button
                kind={buttonKinds.SUBMIT}
                disabled={
                  isUpdating || values.showVehiclesTab === user.showVehiclesTab
                }
                onClick={() => {}}
              >
                <span className={styles.Vehicles_buttonGroup_buttonText}>
                  Update
                  {isUpdating && (
                    <Spinner
                      size={spinnerSizes.XS}
                      colorName={colorNames.WHITE}
                      className={styles.Vehicles_buttonGroup_spinner}
                    />
                  )}
                </span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Vehicles;
