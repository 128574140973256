/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Cookies from 'universal-cookie';
import { Provider as AlertProvider } from 'react-alert';
import {
  Contractor,
  Employer,
  Employee,
  Admin,
  LoginSignup,
} from './screen-wrappers';
import { Maintenance, Logout } from './screens/public';
import { Alert } from './components/elements';
import { UserContext } from './contexts';
import { userTypes } from './globals';
import { UsersService, SubscriptionsService } from './services';
import { convertTimestampToDate, dateTimeIsPast } from './utils/datetime';
import PrivateRoute from './hocs/PrivateRoute';
import './scss/App.scss';

const cookies = new Cookies();

// Alert configuration
const alertOptions = {
  position: 'bottom right',
  timeout: 4000,
  offset: '0 20px 10px',
  transition: 'scale',
};

/* eslint-disable react/jsx-props-no-spreading */
const App = () => {
   // const [user, setUser] = useState(cookies.get('user'));
   const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

   const loginUpdate = (userData) => {
     
     setUser(userData);
     localStorage.setItem('user', JSON.stringify(userData));
 
     // cookies.set('user', userData, {
     //   path: '/',
     // });
   };

  const loginRestart = () => {
    localStorage.clear();
    cookies.remove('user', {
      path: '/',
    });
    cookies.remove('accessToken', {
      path: '/',
    });
    cookies.remove('refreshToken', {
      path: '/',
    });
    cookies.remove('xeroTokenSet', {
      path: '/',
    });

    setUser(null);
  };

  // Checks if user is still subscribed
  const isUserSubscribed = async () => {
    if (
      (user?.userType === 'Employer' || user?.userType === 'Contractor') &&
      user?.subscriptionId
    ) {
      const { data: retrieveSubscriptionResponse } =
        await SubscriptionsService.retrieveSubscription({
          subscriptionId: user?.subscriptionId,
        });

      if (retrieveSubscriptionResponse.subscription) {
        const subscriptionEndDate = convertTimestampToDate(
          retrieveSubscriptionResponse.subscription.current_period_end
        );

        if (!dateTimeIsPast(subscriptionEndDate)) {
          loginUpdate({
            ...user,
            subscriptionEndDate,
            subscriptionStatus: retrieveSubscriptionResponse.subscription
              .cancel_at_period_end
              ? 'canceled'
              : retrieveSubscriptionResponse.subscription.status,
          });
        } else if (user?.isSubscribed) {
          // This means that the subscription of the user (employer or contractor)
          // is not active. So we update their isSubscribed property to false
          const { responseCode: updateIsSubscribedResponseCode } =
            await UsersService.updateIsSubscribed({
              userId: user?.id,
              isSubscribed: false,
            });

          switch (updateIsSubscribedResponseCode) {
            case 200:
              loginRestart();
              break;
            default:
              break;
          }
        }
      }
    }
  };

  useEffect(() => {
    isUserSubscribed();
  }, [user?.id]);

  return (
    <BrowserRouter basename="/">
      <React.Suspense fallback={<div>Loading...</div>}>
        <UserContext.Provider value={{ user, loginUpdate, loginRestart }}>
          <AlertProvider template={Alert} {...alertOptions}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Switch>
                <Route
                  path="/maintenance"
                  name="Maintenance"
                  render={(props) => <Maintenance {...props} />}
                />
                <PrivateRoute
                  forUserType={userTypes.ANY}
                  path="/logout"
                  name="Logout"
                  render={(props) => <Logout {...props} />}
                />
                <PrivateRoute
                  forUserType={userTypes.EMPLOYER}
                  path="/employer"
                  name="Employer"
                  render={(props) => <Employer {...props} />}
                />
                <PrivateRoute
                  forUserType={userTypes.EMPLOYEE}
                  path="/employee"
                  name="employee"
                  render={(props) => <Employee {...props} />}
                />
                <PrivateRoute
                  forUserType={userTypes.CONTRACTOR}
                  path="/contractor"
                  name="contractor"
                  render={(props) => <Contractor {...props} />}
                />
                <PrivateRoute
                  forUserType={userTypes.ADMIN}
                  path="/admin"
                  name="Admin"
                  render={(props) => <Admin {...props} />}
                />
                <Route
                  path="/"
                  name="Login/Sign Up"
                  render={(props) => <LoginSignup {...props} />}
                />

                <Redirect from="/" to="/login" />
                <Redirect to="/page-not-found" />
              </Switch>
            </MuiPickersUtilsProvider>
          </AlertProvider>
        </UserContext.Provider>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
