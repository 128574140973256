import { isStaging, isLocal } from '../utils/destinations';

const contractorSubscriptionPlans = [
  {
    id:
      isStaging || isLocal
        ? 'price_1KtQniDmmWh32CUjHLhX3FUi'
        : 'price_1KtQoVDmmWh32CUjsmoK8wtT',
    name: 'Contractor',
    timeInterval: 'Yearly',
    description: 'For an individual looking to keep track of their own time',
    price: 7,
  },
  {
    id:
      isStaging || isLocal
        ? 'price_1KtQniDmmWh32CUjRKUBLDsg'
        : 'price_1KtQoVDmmWh32CUjwJro9BTQ',
    name: 'Contractor',
    timeInterval: 'Monthly',
    description: 'For an individual looking to keep track of their own time',
    price: 8,
  },
];

export default contractorSubscriptionPlans;
