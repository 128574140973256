import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

import { Card, Shine, Separator } from '../../../../components/elements';
import { UserContext } from '../../../../contexts';

const Preloader = () => {
  const { user } = useContext(UserContext);
  return (
    <Card
      className={cn(styles.Preloader, {
        [styles.Preloader___Employee]: user.userType === 'Employee',
      })}
    >
      <div className={styles.Preloader_header}>
        <Shine className={styles.Preloader_header_shine} />
        <Shine className={styles.Preloader_header_shine} />
        <Shine className={styles.Preloader_header_shine} />
        <Shine className={styles.Preloader_header_shine} />
        <Shine className={styles.Preloader_header_shine} />
        <Shine className={styles.Preloader_header_shine} />
      </div>

      <Separator className={styles.Preloader_separator} />

      <div className={styles.Preloader_body}>
        <Shine className={styles.Preloader_body_shine} />
        <Shine className={styles.Preloader_body_shine} />
        <Shine className={styles.Preloader_body_shine} />
        <Shine className={styles.Preloader_body_shine} />
        <Shine className={styles.Preloader_body_shine} />
        <Shine className={styles.Preloader_body_shine} />
      </div>
    </Card>
  );
};

export default Preloader;
