import { useState, useEffect } from 'react';

import { VehiclesService } from '../services';

const useVehicles = ({ employerId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);

  const deleteVehicle = async (vehicleId) => {
    let responseCode;

    try {
      const response = await VehiclesService.delete({
        vehicleId,
      });
      responseCode = response.status;

      setVehicles((prevVehicles) =>
        prevVehicles.filter((prevVehicle) => prevVehicle.id !== vehicleId)
      );
    } catch (error) {
      responseCode = error.response.status;
    }

    return { responseCode };
  };

  useEffect(() => {
    const getVehicles = async () => {
      try {
        const response = await VehiclesService.get({
          params: { employerId },
        });

        setVehicles(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    };

    getVehicles();
  }, []);

  return { isLoading, vehicles, deleteVehicle };
};

export default useVehicles;
