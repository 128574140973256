import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { forgotPasswordSteps } from './constants';

import InputUsernameEmailModal from './InputUsernameEmailModal';
import InputConfirmationCodeModal from './InputConfirmationCodeModal';
import ChangePasswordModal from './ChangePasswordModal';
import ForgotPasswordSuccessModal from './ForgotPasswordSuccessModal';

const ForgotPasswordModals = ({ isOpen, handleClose }) => {
  const [step, setStep] = useState(forgotPasswordSteps.INPUT_USERNAME_EMAIL);
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [retrievedUserId, setRetrievedUserId] = useState(null);

  return (
    <>
      {step === forgotPasswordSteps.INPUT_USERNAME_EMAIL && (
        <InputUsernameEmailModal
          isOpen={isOpen}
          handleClose={handleClose}
          handleSuccess={({
            userId,
            confirmationCode: retrievedConfirmationCode,
          }) => {
            setRetrievedUserId(userId);
            setConfirmationCode(retrievedConfirmationCode);
            setStep(forgotPasswordSteps.INPUT_CONFIRMATION_CODE);
          }}
        />
      )}

      {step === forgotPasswordSteps.INPUT_CONFIRMATION_CODE && (
        <InputConfirmationCodeModal
          isOpen={isOpen}
          handleClose={handleClose}
          confirmationCode={confirmationCode}
          handleSuccess={() => {
            setStep(forgotPasswordSteps.CHANGE_PASSWORD);
          }}
        />
      )}

      {step === forgotPasswordSteps.CHANGE_PASSWORD && (
        <ChangePasswordModal
          isOpen={isOpen}
          handleClose={handleClose}
          userId={retrievedUserId}
          confirmationCode={confirmationCode}
          handleSuccess={() => {
            setStep(forgotPasswordSteps.FORGOT_PASSWORD_SUCCESS);
          }}
        />
      )}

      {step === forgotPasswordSteps.FORGOT_PASSWORD_SUCCESS && (
        <ForgotPasswordSuccessModal isOpen={isOpen} handleClose={handleClose} />
      )}
    </>
  );
};

ForgotPasswordModals.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ForgotPasswordModals;
