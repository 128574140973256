import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import dateFormat from 'dateformat';
import styles from './styles.module.scss';

import PreloaderSmall from './Preloader/Small';
import PreloaderLarge from './Preloader/Large';
import {
  Card,
  ControlledInput,
  Grid,
  Icon,
  Text,
} from '../../../components/elements';
import {
  colorClasses,
  gridTypes,
  textTypes,
  timesheetTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { useUsers, useGetUsersCount, useTimesheets } from '../../../hooks';
import { getPrevOrAfterDate } from '../../../utils/datetime';
import { getDateEquivalence } from '../../../utils/timesheets';

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [tempNumberOfTimesheets, setTempNumberOfTimesheets] = useState(5);
  const [tempNumberOfDays, setTempNumberOfDays] = useState(7);
  const [filterNumberOfTimesheets, setFilterNumberOfTimesheets] = useState(5);
  const [filterNumberOfDays, setFilterNumberOfDays] = useState(7);
  const [isGenerating, setIsGenerating] = useState(true);
  const [finalUsers, setFinalUsers] = useState([]);

  const thisWeekStartDate = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.timesheetStartDate,
    'start'
  );
  const thisWeekEndDate = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.timesheetStartDate,
    'end'
  );

  const {
    isLoading: isTimesheetsThisWeekLoading,
    timesheets: timesheetsThisWeek,
  } = useTimesheets({
    adminId: user.id,
    startDate: thisWeekStartDate,
    endDate: thisWeekEndDate,
  });

  const {
    isLoading: isTimesheetsThisDayLoading,
    timesheets: timesheetsThisDay,
  } = useTimesheets({
    adminId: user.id,
    startDate: dateFormat(new Date(), 'yyyy-mm-dd'),
    endDate: dateFormat(new Date(), 'yyyy-mm-dd'),
  });

  const { isLoading: filteredUsersLoading, users: filteredUsers } = useUsers({
    adminId: user.id,
    isEmployeesIncluded: true,
    isContractorsIncluded: true,
  });

  const { isLoading: usersCountLoading, usersCount } = useGetUsersCount({
    startDate: thisWeekStartDate,
    endDate: thisWeekEndDate,
  });

  const {
    isLoading: filteredTimesheetsLoading,
    timesheets: filteredTimesheets,
  } = useTimesheets({
    adminId: user.id,
    startDate: getPrevOrAfterDate(new Date(), filterNumberOfDays, 'subtract'),
    endDate: dateFormat(new Date(), 'yyyy-mm-dd'),
  });

  useEffect(() => {
    if (!filteredUsersLoading && !filteredTimesheetsLoading) {
      setIsGenerating(true);

      const includedUsers = [];
      filteredUsers.forEach((u) => {
        const countTimesheetsOfUser = filteredTimesheets.filter(
          (timesheet) => timesheet?.userId === u.id
        ).length;

        if (countTimesheetsOfUser >= filterNumberOfTimesheets) {
          includedUsers.push(u);
        }
      });

      setIsGenerating(false);
      setFinalUsers(includedUsers);
    }
  }, [
    filterNumberOfTimesheets,
    filteredUsersLoading,
    filteredTimesheetsLoading,
  ]);

  return (
    <div className={styles.Dashboard}>
      <Text type={textTypes.HEADING.XS}>Updates Summary</Text>

      <Grid type={gridTypes.FIVE} className={styles.Dashboard_cards}>
        {isTimesheetsThisWeekLoading ||
        usersCountLoading ||
        isTimesheetsThisDayLoading ? (
          <>
            <PreloaderSmall />
            <PreloaderSmall />
            <PreloaderSmall />
            <PreloaderSmall />
            <PreloaderSmall />
          </>
        ) : (
          <>
            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.GREEN['400']}
                  >
                    {timesheetsThisWeek.length}
                  </Text>
                  <Text type={textTypes.BODY.LG}>Timesheets this Week</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="date_range"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___green
                    )}
                  />
                </div>
              </Grid>
            </Card>

            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.BLUE['400']}
                  >
                    {timesheetsThisDay.length}
                  </Text>
                  <Text type={textTypes.BODY.LG}>Timesheets this Day</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="calendar_today"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___blue
                    )}
                  />
                </div>
              </Grid>
            </Card>

            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.VIOLET['400']}
                  >
                    {usersCount?.employer}
                  </Text>
                  <Text type={textTypes.BODY.LG}>New Employers this Week</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="domain"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___violet
                    )}
                  />
                </div>
              </Grid>
            </Card>

            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.YELLOW['400']}
                  >
                    {usersCount?.employee}
                  </Text>
                  <Text type={textTypes.BODY.LG}>New Employees this Week</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="badge"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___yellow
                    )}
                  />
                </div>
              </Grid>
            </Card>

            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.RED['400']}
                  >
                    {usersCount?.contractor}
                  </Text>
                  <Text type={textTypes.BODY.LG}>
                    New Contractors this Week
                  </Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="person"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___red
                    )}
                  />
                </div>
              </Grid>
            </Card>
          </>
        )}
      </Grid>

      <div className={styles.Dashboard_secondRow}>
        <div className={styles.Dashboard_secondRow_filters}>
          <ControlledInput
            className={styles.AssignRoleOrShiftModal_withMarginBottom}
            name="numberOfTimesheets"
            placeholder="Number Of Timesheets"
            value={tempNumberOfTimesheets}
            onChange={(e) => {
              if (!e.target.value) {
                setTempNumberOfTimesheets('');
              } else if (/^([1-9][0-9]{0,2}|1000)$/.test(e.target.value)) {
                setTempNumberOfTimesheets(e.target.value);
              }
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                setTempNumberOfTimesheets(1);
                setFilterNumberOfTimesheets(1);
              } else if (/^([1-9][0-9]{0,2}|1000)$/.test(e.target.value)) {
                setFilterNumberOfTimesheets(e.target.value);
              }
            }}
          />

          <ControlledInput
            className={styles.AssignRoleOrShiftModal_withMarginBottom}
            name="numberOfDays"
            placeholder="Number Of Days"
            value={tempNumberOfDays}
            onChange={(e) => {
              if (!e.target.value) {
                setTempNumberOfDays('');
              } else if (/^([1-9][0-9]{0,2}|1000)$/.test(e.target.value)) {
                setTempNumberOfDays(e.target.value);
              }
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                setTempNumberOfDays(1);
                setFilterNumberOfDays(1);
              } else if (/^([1-9][0-9]{0,2}|1000)$/.test(e.target.value)) {
                setFilterNumberOfDays(e.target.value);
              }
            }}
          />
        </div>

        {isGenerating ? (
          <PreloaderLarge />
        ) : (
          <>
            {finalUsers.length ? (
              <div className={styles.Dashboard_grid}>
                {/* Header of DashboardGrid starts here */}
                <div
                  className={cn(
                    styles.Dashboard_grid_column,
                    styles.Dashboard_grid_header
                  )}
                >
                  User Full Name
                </div>
                <div
                  className={cn(
                    styles.Dashboard_grid_column,
                    styles.Dashboard_grid_header
                  )}
                >
                  User Email Address
                </div>
                <div
                  className={cn(
                    styles.Dashboard_grid_column,
                    styles.Dashboard_grid_header
                  )}
                >
                  User Type
                </div>
                <div
                  className={cn(
                    styles.Dashboard_grid_column,
                    styles.Dashboard_grid_header
                  )}
                >
                  Employer Name
                </div>
                {/* Header of DashboardGrid ends here */}

                {/* Body of DashboardGrid starts here */}
                {finalUsers.map(
                  ({
                    id,
                    fullName,
                    emailAddress,
                    userType,
                    employerDetails,
                  }) => (
                    <div className={styles.Dashboard_keyWrapper} key={id}>
                      <div className={styles.Dashboard_grid_column}>
                        {fullName}
                      </div>
                      <div className={styles.Dashboard_grid_column}>
                        {emailAddress}
                      </div>
                      <div className={styles.Dashboard_grid_column}>
                        {userType}
                      </div>
                      <div className={styles.Dashboard_grid_column}>
                        {employerDetails?.employerFullName || 'NA'}
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className={styles.Dashboard_noUsers}>
                <Text
                  colorClass={colorClasses.RED['400']}
                  type={textTypes.HEADING.XS}
                >
                  NO USERS FOUND.
                </Text>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
