import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import {
  Card,
  ControlledInput,
  Button,
  Grid,
  Spinner,
  Text,
  ControlledSelect,
} from '../../../../components/elements';
import styles from './styles.module.scss';
import {
  buttonKinds,
  colorNames,
  inputKinds,
  spinnerSizes,
  textTypes,
  userTypes,
} from '../../../../globals';
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidDecimal,
} from '../../../../utils/string';
import { UserContext } from '../../../../contexts';
import { UsersService, TokensService } from '../../../../services';
import Logo from '../../../../static/images/Logo/logo.png';
import countryTelData from 'country-telephone-data';

const AccountDetails = ({ userType }) => {
  const [isSigningUp, setIsSigningUp] = useState(false);
  const userContext = useContext(UserContext);
  const cookies = new Cookies();
  const [countryWithCode,setCountryWithCode] = useState(countryTelData.allCountries);
  
  if (!userType) {
    window.location.href = '/signup';
    return;
  }

  const validate = (values) => {
    const errors = {};

    if (!values.fullName) {
      errors.fullName = 'This field is required.';
    } else if (values.fullName.length > 60) {
      errors.fullName = 'The maximum length of this field is 60 characters.';
    }

    if (!values.emailAddress) {
      errors.emailAddress = 'This field is required.';
    } else if (!isValidEmail(values.emailAddress)) {
      errors.emailAddress = 'This must be a valid email address.';
    }

    if (userType === userTypes.EMPLOYER || userType === userTypes.CONTRACTOR) {
      if (values.phoneNumber) {
        // if (!isValidPhoneNumber(values.phoneNumber)) {
        //   errors.phoneNumber = 'Only numbers are allowed.';
        // } else 

        if (values.phoneNumber.length > 15) {
          errors.phoneNumber =
            'The maximum length of this field is 15 characters.';
        }
      }
    }

    if (userType === userTypes.EMPLOYER) {
      if (!values.companyName) {
        errors.companyName = 'This field is required.';
      } else if (values.companyName.length > 50) {
        errors.companyName =
          'The maximum length of this field is 50 characters.';
      }
    }

    if (userType === userTypes.CONTRACTOR) {
      if (values.hourlyWage) {
        if (!isValidDecimal(values.hourlyWage)) {
          errors.hourlyWage = 'Only numbers are allowed.';
        }
      }
    }

    if (!values.password) {
      errors.password = 'This field is required.';
    } else if (values.password.length > 30) {
      errors.password = 'The maximum length of this field is 30 characters.';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'This field is required.';
    } else if (values.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = 'This must match with your password.';
    }

    return errors;
  };

  return (
    <Card className={styles.AccountDetails}>
      <div className={styles.AccountDetails_header}>
        <img
          src={Logo}
          className={styles.AccountDetails_header_logo}
          alt="TimeSheet Logo"
        />

        <div className={styles.AccountDetails_header_headingTextWrapper}>
          <Text
            type={textTypes.SPAN.MD}
            className={
              styles.AccountDetails_header_headingTextWrapper_headingText
            }
          >
            Sign Up with TimeSheet
          </Text>
        </div>
      </div>

      <div className={styles.AccountDetails_content}>
        <Formik
          initialValues={{
            fullName: '',
            emailAddress: '',
            country: '',
            phoneNumber: '',
            companyName: '',
            hourlyWage: '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={async (values, { setErrors }) => {
            const currentFormValues = {
              fullName: values.fullName,
              emailAddress: values.emailAddress,
              country: values.country,
              phoneNumber: values.phoneNumber,
              companyName: values.companyName,
              hourlyWage: Number(values.hourlyWage),
              password: values.password,
              userType,
            };

            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            setIsSigningUp(true);

            // Check if user exists with the inputted emailAddress
            const { data: userExistsResponse } = await UsersService.userExists(
              currentFormValues.emailAddress
            );
            if (userExistsResponse.message === 'user_exists') {
              setErrors({
                emailAddress: 'This email address has been used already.',
              });
              setIsSigningUp(false);
              return;
            }

            // If the emailAddress is valid, we proceed with
            // the creation of the user
            const { data: signupResponse } = await UsersService.signup(
              currentFormValues
            );

            // Call the Acquire Tokens endpoint to set the tokens
            // in to the cookies
            const { data: acquireResponse } = await TokensService.acquire({
              userId: signupResponse.id,
              emailAddress: currentFormValues.emailAddress,
              password: currentFormValues.password,
            });

            if (acquireResponse.message === 'user_is_valid') {
              cookies.set('accessToken', acquireResponse.accessToken, {
                path: '/',
              });
              cookies.set('refreshToken', acquireResponse.refreshToken, {
                path: '/',
              });

              // Add a isNewlySignedUp prop
              signupResponse.isNewlySignedUp = true;

              // Update login
              userContext.loginUpdate(signupResponse);
            }
            setIsSigningUp(false);
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <ControlledInput
                placeholder="Full Name*"
                name="fullName"
                icon="contact_mail"
                value={values.fullName}
                error={errors.fullName}
                onChange={(e) => setFieldValue('fullName', e.target.value)}
              />

              <ControlledInput
                className={styles.AccountDetails_content_withMargin}
                placeholder="Email Address*"
                name="emailAddress"
                icon="email"
                value={values.emailAddress}
                error={errors.emailAddress}
                onChange={(e) => setFieldValue('emailAddress', e.target.value)}
              />

            {userType === userTypes.EMPLOYER && (
                <ControlledSelect
                  className={styles.AccountDetails_content_withMargin}
                  options={ countryWithCode.map((country) => {
                    return {
                      value: country.dialCode,
                      label: country.name,
                    }
                  })}
                  name="country"
                  placeholder="Country"
                  value={values.country}
                  onChange={(e) => {
                    setFieldValue('country', e);
                    const findCountry = countryWithCode.find((country) => country.dialCode === e.value);
                    
                    if(findCountry !== undefined){
                      if(findCountry.format){
                        const firstString = findCountry.format.split(' ')[0];
                        if(firstString.startsWith('+')){
                          setFieldValue('phoneNumber', '+' + e.value);
                        }
                      }
                    }else{
                      setFieldValue('phoneNumber', e.value);
                    }
                  }}
                  />
              )}

              {(userType === userTypes.EMPLOYER ||
                userType === userTypes.CONTRACTOR) && (
                <ControlledInput
                  className={styles.AccountDetails_content_withMargin}
                  placeholder="Phone Number"
                  name="phoneNumber"
                  icon="phone"
                  value={values.phoneNumber}
                  error={errors.phoneNumber}
                  onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                />
              )}

              {userType === userTypes.EMPLOYER && (
                <ControlledInput
                  className={styles.AccountDetails_content_withMargin}
                  placeholder="Company Name*"
                  name="companyName"
                  icon="domain"
                  value={values.companyName}
                  error={errors.companyName}
                  onChange={(e) => setFieldValue('companyName', e.target.value)}
                />
              )}

              {userType === userTypes.CONTRACTOR && (
                <ControlledInput
                  className={styles.AccountDetails_content_withMargin}
                  placeholder="Hourly Wage"
                  name="hourlyWage"
                  icon="attach_money"
                  value={values.hourlyWage}
                  error={errors.hourlyWage}
                  onChange={(e) => setFieldValue('hourlyWage', e.target.value)}
                />
              )}

              <Grid className={styles.AccountDetails_content_withMargin}>
                <ControlledInput
                  kind={inputKinds.PASSWORD}
                  placeholder="Password*"
                  name="password"
                  icon="vpn_key"
                  value={values.password}
                  error={errors.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                />
                <ControlledInput
                  kind={inputKinds.PASSWORD}
                  placeholder="Confirm Password*"
                  name="confirmPassword"
                  icon="vpn_key"
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={(e) =>
                    setFieldValue('confirmPassword', e.target.value)
                  }
                />
              </Grid>

              <div className={styles.AccountDetails_content_buttonGroup}>
                <Button
                  kind={buttonKinds.SUBMIT}
                  icon="person"
                  disabled={isSigningUp}
                  onClick={() => {}}
                >
                  <span
                    className={
                      styles.AccountDetails_content_buttonGroup_buttonText
                    }
                  >
                    Sign Up
                    {isSigningUp && (
                      <Spinner
                        size={spinnerSizes.XS}
                        colorName={colorNames.WHITE}
                        className={
                          styles.AccountDetails_content_buttonGroup_spinner
                        }
                      />
                    )}
                  </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <div className={styles.AccountDetails_footer}>
        <Text>
          Already have an account?{' '}
          <Link
            to="/login"
            className={styles.AccountDetails_footer_signIn}
            onClick={isSigningUp ? (e) => e.preventDefault() : () => {}}
          >
            Sign In
          </Link>
        </Text>
      </div>
    </Card>
  );
};

AccountDetails.propTypes = {
  userType: PropTypes.oneOf([
    userTypes.EMPLOYER,
    userTypes.EMPLOYEE,
    userTypes.CONTRACTOR,
  ]).isRequired,
};

export default AccountDetails;
