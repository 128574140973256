import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/timesheets`;

const TimesheetsService = {
  get: (body) => axios.post(`${BASE_URL}/get`, body),
  create: (body) => axios.post(`${BASE_URL}/create`, body),
  update: (body) => axios.post(`${BASE_URL}/update`, body),
  delete: (timesheetId) => axios.post(`${BASE_URL}/delete`, timesheetId),
  generateReport: (body) =>
    axios.post(`${BASE_URL}/generateReport`, body, {
      responseType: body.type === 'Download' ? 'blob' : 'json',
    }),
  generateContractorReport: (body) =>
    axios.post(`${BASE_URL}/generateContractorReport`, body, {
      responseType: body.type === 'Download' ? 'blob' : 'json',
    }),
    generateClientReport: (body) =>
    axios.post(`${BASE_URL}/generateClientProjectReport`, body, {
      responseType: body.type === 'Download' ? 'blob' : 'json',
    }),
  timesheetById: (body) => axios.post(`${BASE_URL}/timesheet-by-id`, body),
  };

export default TimesheetsService;
