import React, { useState, useContext } from 'react';
import Cookies from 'universal-cookie';

import {
  Card,
  Button,
  ButtonLink,
  Text,
} from '../../../components/elements';
import styles from './styles.module.scss';
import {
  buttonTypes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import Logo from '../../../static/images/Logo/logo.png';

const validate = (values) => {
  const errors = {};

  if (!values.emailAddress) {
    errors.emailAddress = 'This field is required.';
  }

  if (!values.password) {
    errors.password = 'This field is required.';
  }

  return errors;
};

const DeleteEmployee = () => {
  const userContext = useContext(UserContext);
  const cookies = new Cookies();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isForgotPasswordToggled, toggleIsForgotPassword] = useState(false);

  return (
    <>
      <Card className={styles.DeleteUser}>
        <div className={styles.DeleteUser_header}>
          <img
            src={Logo}
            className={styles.DeleteUser_header_logo}
            alt="TimeSheet Logo"
          />

          <div className={styles.DeleteUser_header_headingTextWrapper}>
            <Text
              type={textTypes.SPAN.MD}
              className={styles.DeleteUser_header_headingTextWrapper_headingText}
            >
              Hello Kevin
            </Text>
          </div>
          <div className={styles.DeleteUser_header_headingTextWrapper}>
            <Text
              type={textTypes.SPAN.MD}
              className={styles.DeleteUser_header_headingTextWrapper_headingText2}
            >
              Are you sure want to delete your account?
            </Text>
          </div>
        </div>


        <div className={styles.DeleteUser_content}>
        <div className={styles.DeleteUser_content_buttonGroup}>
                  <ButtonLink
                  to="https://timesheet.global/"
                    // kind={buttonKinds.SUBMIT}
                    // icon="lock_open"
                    disabled={isLoggingIn}
                    onClick={() => {}}
                  >
                    <span
                      className={styles.DeleteUser_content_buttonGroup_buttonText}
                    >
                        Yes,Please
                    </span>
                  </ButtonLink>
                  <ButtonLink
                    type={buttonTypes.SECONDARY.VIOLET}
                    to="/login"
                    // icon="person"
                    disabled={isLoggingIn}
                  >
                    No
                  </ButtonLink>
                </div>
        </div>
      </Card>

      
    </>
  );
};

export default DeleteEmployee;
