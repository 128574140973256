const navLinks = {
  DASHBOARD: {
    label: 'Dashboard',
    icon: 'home',
  },
  DATABASE_EXPORT: {
    label: 'Database',
    icon: 'download',
  },
  MAP: {
    label: 'Map',
    icon: 'map',
  },
  TIMESHEETS: {
    label: 'Timesheets',
    icon: 'edit',
  },
  ROSTERS: {
    label: 'Rosters',
    icon: 'date_range',
  },
  CLIENTS: {
    label: 'Clients',
    icon: 'supervisor_account',
  },
  EMPLOYEES: {
    label: 'Employees',
    icon: 'badge',
  },
  TABLET_VIEW: {
    label: 'Tablet View',
    icon: 'tablet',
  },
  VEHICLES: {
    label: 'Vehicles',
    icon: 'directions_car_filled',
  },
};

export default navLinks;
