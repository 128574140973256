import { useState } from 'react';

import { ReviewsService } from '../services';

const useSendReviewReport = () => {
  const [isSending, setIsSending] = useState(false);

  const sendReport = async ({
    emailAddress,
    employerFullName,
    employerEmailAddress,
    vehicleId,
    reviewId,
    fileName,
  }) => {
    setIsSending(true);

    let responseCode;

    try {
      const response = await ReviewsService.sendReport({
        emailAddress,
        employerFullName,
        employerEmailAddress,
        vehicleId,
        reviewId,
        fileName,
      });
      responseCode = response.status;
    } catch (error) {
      responseCode = error.response.status;
    }

    setIsSending(false);

    return { responseCode };
  };

  return { isSending, sendReport };
};

export default useSendReviewReport;
