import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/misc`;

const MiscService = {
  contactUs: (body) => axios.post(`${BASE_URL}/contactUs`, body),
};

export default MiscService;
