/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styles from './styles.module.scss';

import { Icon, IconButton, Text } from '../elements';
import { iconButtonTypes, textTypes } from '../../globals';

const Dropzone = ({ className, title, logo, onUpload }) => {
  const [files, setFiles] = useState(
    logo !== null ? [{ name: 'uploadedLogo', preview: logo }] : []
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const handledAcceptedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(handledAcceptedFiles);
      onUpload(handledAcceptedFiles[0]);
    },
    maxFiles: 1,
    disabled: files.length > 0,
  });

  const thumbs = files.map((file) => (
    <img
      key={file.name}
      src={file.preview}
      className={styles.Dropzone_thumbContainer_img}
      alt="Preview"
    />
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div
      className={cn(styles.Dropzone, className, {
        [styles.Dropzone___active]: files.length,
      })}
    >
      <div {...getRootProps({ className: styles.Dropzone_input })}>
        <input {...getInputProps()} />

        <Text type={textTypes.HEADING.XS} className={styles.Dropzone_title}>
          <Icon icon="add" /> {title}
        </Text>

        <Text
          type={textTypes.HEADING.XXS}
          className={styles.Dropzone_helperText}
        >
          or drag files from your computer
        </Text>

        <div
          className={cn(styles.Dropzone_thumbContainer, {
            [styles.Dropzone_thumbContainer___active]: files.length,
          })}
        >
          {thumbs}
        </div>

        {thumbs.length > 0 && (
          <div className={styles.Dropzone_buttons}>
            <IconButton
              className={styles.Dropzone_buttons_close}
              type={iconButtonTypes.SOLID.XS}
              icon="close"
              onClick={() => {
                setFiles([]);
                onUpload(null);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Dropzone.defaultProps = {
  className: null,
  logo: null,
};

Dropzone.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
};

export default Dropzone;
