import React, { useState, useContext } from 'react';
import cn from 'classnames';
import dateFormat from 'dateformat';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import { SubscriptionCard, SweetAlert } from '../../../components';
import {
  Button,
  Card,
  Checkbox,
  Grid,
  Spinner,
  Text,
} from '../../../components/elements';
import {
  colorClasses,
  colorNames,
  contractorSubscriptionPlans,
  spinnerSizes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { SubscriptionsService } from '../../../services';
import { useRetrievePaymentMethod } from '../../../hooks';
import { dateTimeIsPast } from '../../../utils/datetime';

import Amex from '../../../static/images/Cards/amex.svg';
import Mastercard from '../../../static/images/Cards/mastercard.svg';
import Visa from '../../../static/images/Cards/visa.svg';

const Subscription = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);

  // Define constants
  const userCustomerId = user?.customerId;
  const userIsSubscribed = user?.isSubscribed;
  const userSubscriptionId = user?.subscriptionId;
  const currentSubscriptionPlan = contractorSubscriptionPlans.find(
    (plan) => user?.subscriptionPlanId === plan.id
  );

  // Define states
  const [selectedTimeInterval, setSelectedTimeInterval] = useState('Monthly');
  const [selectedPlan, setSelectedPlan] = useState(
    currentSubscriptionPlan || null
  );
  const [isCardOkay, setIsCardOkay] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(
    !userIsSubscribed
  );

  // Define stripe
  const stripe = useStripe();
  const elements = useElements();

  // Retrieve payment method if isSubscribed
  const {
    isRetrieving: isPaymentMethodRetrieving,
    paymentMethod: currentPaymentMethod,
  } = useRetrievePaymentMethod({
    customerId: userCustomerId,
    isSubscribed: userIsSubscribed,
  });

  const handleSubmit = async (type) => {
    setShowVerification(false);
    setIsSubscribing(true);

    if (type === 'create') {
      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.fullName,
            email: user.emailAddress,
          },
        });

        if (!error) {
          try {
            // Create customer
            const { data: createCustomerResponse } =
              await SubscriptionsService.createCustomer({
                name: paymentMethod.billing_details.name,
                email: paymentMethod.billing_details.email,
                paymentMethod: paymentMethod.id,
              });

            if (createCustomerResponse.customer) {
              try {
                // Create subscription
                const { data: createSubscriptionResponse } =
                  await SubscriptionsService.createSubscription({
                    customerId: createCustomerResponse.customer.id,
                    subscriptionPlanId: selectedPlan.id,
                    userId: user.id,
                    customerFullName: user.fullName,
                    customerEmailAddress: user.emailAddress,
                    subscriptionPlanName: selectedPlan.name,
                    subscriptionPlanInterval: selectedPlan.timeInterval,
                  });

                if (createSubscriptionResponse.message === 'subscribed') {
                  alert.success('Subscription Plan Activated! Redirecting...');

                  loginUpdate({
                    ...user,
                    ...createSubscriptionResponse.fieldsToSet,
                  });

                  setTimeout(() => {
                    window.location.href = '/contractor/settings/subscription';
                  }, 3000);
                }
              } catch {
                alert.error('Oops, something went wrong.');
              }
            }
          } catch {
            alert.error('Oops, something went wrong.');
          }
        }
      } catch {
        alert.error('Oops, something went wrong.');
      }
    } else if (type === 'update') {
      if (changePaymentMethod) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.fullName,
            email: user.emailAddress,
          },
        });

        if (!error) {
          // Update payment method
          await SubscriptionsService.updatePaymentMethod({
            customerId: userCustomerId,
            newPaymentMethod: paymentMethod.id,
          });
        }
      }

      try {
        // Update subscription
        const { data: updateSubscriptionResponse } =
          await SubscriptionsService.updateSubscription({
            previousSubscriptionPlanName: currentSubscriptionPlan?.name,
            previousSubscriptionPlanInterval:
              currentSubscriptionPlan?.timeInterval,
            subscriptionId: userSubscriptionId,
            subscriptionPlanId: selectedPlan.id,
            subscriptionPlanName: selectedPlan.name,
            subscriptionPlanInterval: selectedPlan.timeInterval,
            userId: user.id,
            customerFullName: user.fullName,
            customerEmailAddress: user.emailAddress,
          });

        if (updateSubscriptionResponse.message === 'subscription_updated') {
          alert.success('Subscription Plan Updated! Redirecting...');

          loginUpdate({
            ...user,
            ...updateSubscriptionResponse.fieldsToSet,
          });

          setTimeout(() => {
            window.location.href = '/contractor/settings/subscription';
          }, 3000);
        }
      } catch {
        alert.error('Oops, something went wrong.');
      }
    }

    setIsSubscribing(false);
  };

  return (
    <div className={styles.Subscription}>
      {!dateTimeIsPast(user.subscriptionTrialEndDate) && (
        <div className={styles.Subscription_alert}>
          <Text
            type={textTypes.HEADING.XXXS}
            colorClass={colorClasses.NEUTRAL[0]}
          >
            WARNING: Your free trial will end on{' '}
            {dateFormat(
              new Date(user?.subscriptionTrialEndDate),
              'mmmm d, yyyy'
            )}
            . If you wish to select a subscription plan, your free trial will
            end right away.
          </Text>
        </div>
      )}

      <div className={styles.Subscription_grid}>
        <Card className={styles.Subscription_card}>
          <Text
            type={textTypes.HEADING.XXS}
            className={styles.Subscription_card_title}
          >
            Choose Your Subscription Plan
          </Text>

          <div className={styles.Subscription_timeIntervalButtons}>
            <Button
              className={cn(styles.Subscription_timeIntervalButtons_button, {
                [styles.Subscription_timeIntervalButtons_button___active]:
                  selectedTimeInterval === 'Monthly',
              })}
              onClick={() => setSelectedTimeInterval('Monthly')}
            >
              Monthly Package
            </Button>
            <Button
              className={cn(styles.Subscription_timeIntervalButtons_button, {
                [styles.Subscription_timeIntervalButtons_button___active]:
                  selectedTimeInterval === 'Yearly',
              })}
              onClick={() => setSelectedTimeInterval('Yearly')}
            >
              Yearly Package
            </Button>
          </div>

          {contractorSubscriptionPlans.map((subscriptionPlan) => (
            <div
              className={styles.Subscription_keyWrapper}
              key={subscriptionPlan.id}
            >
              {subscriptionPlan.timeInterval === selectedTimeInterval && (
                <SubscriptionCard
                  planName={subscriptionPlan.name}
                  planDescription={subscriptionPlan.description}
                  planPrice={subscriptionPlan.price}
                  isEmployer={false}
                  isCurrentPlan={selectedPlan?.id === subscriptionPlan.id}
                  onClick={() => setSelectedPlan(subscriptionPlan)}
                />
              )}
            </div>
          ))}
        </Card>

        <div>
          <Card
            className={cn(
              styles.Subscription_card,
              styles.Subscription_withMarginBottom
            )}
          >
            {user.isSubscribed && (
              <>
                {isPaymentMethodRetrieving ? (
                  <p>Loading...</p>
                ) : (
                  <div className={styles.Subscription_withMarginBottom}>
                    <Text className={styles.Subscription_label}>
                      Current Card Number
                    </Text>

                    <div
                      className={
                        styles.Subscription_currentPaymentMethod_container
                      }
                    >
                      <img
                        src={
                          // eslint-disable-next-line no-nested-ternary
                          currentPaymentMethod.card.brand === 'amex'
                            ? Amex
                            : // eslint-disable-next-line no-nested-ternary
                            currentPaymentMethod.card.brand === 'mastercard'
                            ? Mastercard
                            : currentPaymentMethod.card.brand === 'visa'
                            ? Visa
                            : ''
                        }
                        className={
                          styles.Subscription_currentPaymentMethod_cardImage
                        }
                        alt="Card"
                      />

                      <span>
                        ***********
                        {currentPaymentMethod.card.brand !== 'amex' ? '*' : ''}
                        {currentPaymentMethod.card.last4}
                      </span>
                    </div>
                  </div>
                )}

                <Checkbox
                  className={styles.Subscription_withMarginBottom}
                  name="changePaymentMethod"
                  label="Change Payment Method"
                  onChange={() => setChangePaymentMethod(!changePaymentMethod)}
                  checked={changePaymentMethod}
                />
              </>
            )}

            {changePaymentMethod && (
              <>
                <Text className={styles.Subscription_label}>
                  Credit/Debit Card
                </Text>
                <CardElement
                  onChange={(e) => {
                    if (e.error === undefined && e.complete) {
                      setIsCardOkay(true);
                    } else {
                      setIsCardOkay(false);
                    }
                  }}
                />
              </>
            )}
          </Card>

          <Card className={styles.Subscription_card}>
            <Text
              type={textTypes.HEADING.XXS}
              className={styles.Subscription_card_title}
            >
              Summary
            </Text>

            <Grid className={styles.Subscription_summaryRow}>
              <Text type={textTypes.BODY.LG}>
                {selectedPlan
                  ? `${selectedPlan.name} (${selectedPlan?.timeInterval})`
                  : 'Plan Name'}
              </Text>
              <Text type={textTypes.BODY.LG}>
                ${selectedPlan ? selectedPlan.price : 0}
              </Text>
            </Grid>

            <Grid className={styles.Subscription_summaryRow}>
              <Text type={textTypes.HEADING.XXS}>Total (NZD)</Text>
              <Text type={textTypes.HEADING.XXS}>
                ${/* eslint-disable-next-line no-nested-ternary */}
                {selectedPlan
                  ? selectedPlan.timeInterval === 'Yearly'
                    ? selectedPlan.price * 12
                    : selectedPlan.price
                  : 0}{' '}
                {selectedPlan &&
                  `/ ${selectedPlan.timeInterval.slice(0, -2).toLowerCase()}`}
              </Text>
            </Grid>

            <Button
              className={styles.Subscription_submitButton}
              onClick={() => setShowVerification(true)}
              disabled={
                selectedPlan === null ||
                !stripe ||
                (!isCardOkay && changePaymentMethod)
              }
            >
              {userIsSubscribed ? 'Update Subscription' : 'Complete Payment'}
            </Button>

            <SweetAlert
              show={showVerification}
              title="Are you sure?"
              onConfirm={() =>
                handleSubmit(userIsSubscribed ? 'update' : 'create')
              }
              onCancel={() => setShowVerification(false)}
            >
              Your credit/debit card will gain charges depending on your
              selected plan.
            </SweetAlert>
          </Card>
        </div>
      </div>

      {isSubscribing && (
        <div className={styles.Subscription_loader}>
          <Spinner
            size={spinnerSizes.LG}
            colorName={colorNames.WHITE}
            className={styles.Subscription_loader_spinner}
          />
        </div>
      )}
    </div>
  );
};

export default Subscription;
