import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { UserContext } from '../../contexts';
import { userTypes } from '../../globals';

import { navLinks } from '../constants';
import SidebarLink from './Link';

const Sidebar = ({ isToggled }) => {
  const { user } = useContext(UserContext);
  const userTypeInsensitive = user.userType.toLowerCase();

  return (
    <nav
      className={cn(styles.Sidebar, { [styles.Sidebar___toggled]: isToggled })}
    >
      <SidebarLink
        to={`/${userTypeInsensitive}/dashboard`}
        label={navLinks.DASHBOARD.label}
        icon={navLinks.DASHBOARD.icon}
      />

      {user.userType === userTypes.ADMIN && (
        <SidebarLink
          to={`/${userTypeInsensitive}/database-export`}
          label={navLinks.DATABASE_EXPORT.label}
          icon={navLinks.DATABASE_EXPORT.icon}
        />
      )}

      {user.userType === userTypes.EMPLOYER && (
        <SidebarLink
          to={`/${userTypeInsensitive}/map`}
          label={navLinks.MAP.label}
          icon={navLinks.MAP.icon}
        />
      )}

      <SidebarLink
        to={`/${userTypeInsensitive}/timesheets`}
        label={navLinks.TIMESHEETS.label}
        icon={navLinks.TIMESHEETS.icon}
      />

      {(user.userType === userTypes.EMPLOYER ||
        user.userType === userTypes.EMPLOYEE) && (
        <SidebarLink
          to={`/${userTypeInsensitive}/rosters`}
          label={navLinks.ROSTERS.label}
          icon={navLinks.ROSTERS.icon}
        />
      )}

      <SidebarLink
        to={`/${userTypeInsensitive}/clients`}
        label={navLinks.CLIENTS.label}
        icon={navLinks.CLIENTS.icon}
      />

      {user.userType === userTypes.EMPLOYER && (
        <>
          <SidebarLink
            to={`/${userTypeInsensitive}/employees`}
            label={navLinks.EMPLOYEES.label}
            icon={navLinks.EMPLOYEES.icon}
          />

          <SidebarLink
            to={`/${userTypeInsensitive}/tablet-view`}
            label={navLinks.TABLET_VIEW.label}
            icon={navLinks.TABLET_VIEW.icon}
          />
        </>
      )}

      {user.userType === userTypes.EMPLOYER && user?.showVehiclesTab && (
        <SidebarLink
          to={`/${userTypeInsensitive}/vehicles`}
          label={navLinks.VEHICLES.label}
          icon={navLinks.VEHICLES.icon}
        />
      )}
    </nav>
  );
};

Sidebar.propTypes = {
  isToggled: PropTypes.bool.isRequired,
};

export default Sidebar;
