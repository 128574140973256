import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { useAlert } from 'react-alert';
import FileDownload from 'js-file-download';
import dateFormat from 'dateformat';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';

import { Breadcrumbs } from '../../../components';
import { Button, Icon, Text } from '../../../components/elements';
import { SendReviewReportModal } from '../../../components/modals';
import { buttonTypes, colorClasses, textTypes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { ReviewsService } from '../../../services';
import { useVehicle, useReview } from '../../../hooks';
import styles from './styles.module.scss';

const ViewReview = () => {
  const alert = useAlert();
  const { vehicleId, reviewId } = useParams();
  const { user } = useContext(UserContext);
  const [showReportModal, setShowReportModal] = useState(false);
  const [photos, setPhotos] = useState([]);

  const { isLoading: isVehicleLoading, vehicle } = useVehicle({
    vehicleId,
  });

  const { isLoading: isReviewLoading, review } = useReview({
    vehicleId,
    reviewId,
  });

  useEffect(() => {
    if (!isReviewLoading) {
      const tempPhotos = [];
      for (let i = 0; i < review.photos.length; i++) {
        tempPhotos.push({
          original: `https://storage.googleapis.com/timesheet-vehicles/${review.photos[i]}`,
          thumbnail: `https://storage.googleapis.com/timesheet-vehicles/${review.photos[i]}`,
        });
      }

      setPhotos(tempPhotos);
    }
  }, [isReviewLoading]);

  return (
    <div className={styles.ViewReview}>
      <div className={styles.ViewReview_firstRow}>
        <Breadcrumbs
          pageTitle="View Review"
          pages={[
            {
              name: 'Dashboard',
              link: '/employer/dashboard',
            },
            {
              name: 'Vehicles',
              link: '/employer/vehicles',
            },
            {
              name: 'View Vehicle',
              link: `/employer/vehicles/view/${vehicleId}`,
            },
          ]}
        />

        <div className={styles.ViewReview_reportButtons}>
          <Button
            className={styles.ViewReview_reportButtons_button}
            icon="send"
            onClick={() => setShowReportModal(true)}
            disabled={isVehicleLoading || isReviewLoading}
          >
            <span className={styles.ViewReview_reportButtons_button_text}>
              Send Report
            </span>
          </Button>

          <Button
            className={styles.ViewReview_reportButtons_button}
            icon="download"
            type={buttonTypes.PRIMARY.RED}
            onClick={async () => {
              const fileName = 'Review Report';
              let downloadReportResponseCode;
              let fileBlob;

              try {
                const response = await ReviewsService.downloadReport({
                  vehicleId,
                  reviewId,
                  fileName,
                });
                downloadReportResponseCode = response.status;
                fileBlob = response.data;
              } catch (error) {
                downloadReportResponseCode = error.response.status;
              }

              const downloadReportCallbacks = {
                downloaded: () => {
                  FileDownload(fileBlob, `${fileName}.xlsx`);
                  alert.success('Review report downloaded!');
                },
                invalidFields: () => alert.error('Invalid fields.'),
                internalError: () => alert.error('Oops, something went wrong.'),
              };

              switch (downloadReportResponseCode) {
                case 200:
                  downloadReportCallbacks.downloaded();
                  break;
                case 400:
                  downloadReportCallbacks.invalidFields();
                  break;
                case 500:
                  downloadReportCallbacks.internalError();
                  break;
                default:
                  break;
              }
            }}
            disabled={isVehicleLoading || isReviewLoading}
          >
            <span className={styles.ViewReview_reportButtons_button_text}>
              Download Report
            </span>
          </Button>
        </div>

        {showReportModal && (
          <SendReviewReportModal
            isOpen={showReportModal}
            handleClose={() => setShowReportModal(false)}
            employerFullName={user.fullName}
            employerEmailAddress={user.emailAddress}
            vehicleId={vehicleId}
            reviewId={reviewId}
          />
        )}
      </div>

      {!isVehicleLoading && !isReviewLoading && (
        <>
          <div className={styles.ViewReview_secondRow}>
            <Text type={textTypes.HEADING.MD}>{vehicle.name}</Text>
          </div>

          <div className={styles.ViewReview_mainRow}>
            <div className={styles.ViewReview_mainRow_leftColumn}>
              <ImageGallery
                items={photos}
                showPlayButton={false}
                showFullscreenButton={false}
              />
            </div>

            <div className={styles.ViewReview_mainRow_rightColumn}>
              <div className={styles.ViewReview_mainRow_firstGrid}>
                <div>
                  <Text>Date Reviewed</Text>
                  <Text
                    type={textTypes.HEADING.XS}
                    className={styles.ViewReview_mainRow_firstGrid_value}
                  >
                    {dateFormat(new Date(review.dateReviewed), 'd mmmm yyyy')}
                  </Text>
                </div>
                <div>
                  <Text>Name of Employee</Text>
                  <Text
                    type={textTypes.HEADING.XS}
                    className={styles.ViewReview_mainRow_firstGrid_value}
                  >
                    {review.employeeFullName}
                  </Text>
                </div>
              </div>

              <div className={styles.ViewReview_mainRow_secondGrid}>
                {(review.rego || vehicle.rego) && (
                  <>
                    <div>
                      <Text>WOF/Rego</Text>
                    </div>
                    <div>
                      <Text
                        type={textTypes.HEADING.XS}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        {review.rego || vehicle.rego}
                      </Text>
                    </div>
                  </>
                )}

                <div>
                  <Text>Oil Checked</Text>
                </div>
                <div>
                  {review.isOilChecked ? (
                    <>
                      <Icon
                        icon="check"
                        className={cn(
                          styles.ViewReview_mainRow_secondGrid_icon,
                          styles.ViewReview_mainRow_secondGrid_icon___green
                        )}
                      />
                      <Text
                        type={textTypes.HEADING.XS}
                        colorClass={colorClasses.GREEN['400']}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        YES
                      </Text>
                    </>
                  ) : (
                    <>
                      <Icon
                        icon="close"
                        className={cn(
                          styles.ViewReview_mainRow_secondGrid_icon,
                          styles.ViewReview_mainRow_secondGrid_icon___red
                        )}
                      />
                      <Text
                        type={textTypes.HEADING.XS}
                        colorClass={colorClasses.RED['400']}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        NO
                      </Text>
                    </>
                  )}
                </div>

                <div>
                  <Text>Vehicle Checked</Text>
                </div>
                <div>
                  {review.isVehicleChecked ? (
                    <>
                      <Icon
                        icon="check"
                        className={cn(
                          styles.ViewReview_mainRow_secondGrid_icon,
                          styles.ViewReview_mainRow_secondGrid_icon___green
                        )}
                      />
                      <Text
                        type={textTypes.HEADING.XS}
                        colorClass={colorClasses.GREEN['400']}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        YES
                      </Text>
                    </>
                  ) : (
                    <>
                      <Icon
                        icon="close"
                        className={cn(
                          styles.ViewReview_mainRow_secondGrid_icon,
                          styles.ViewReview_mainRow_secondGrid_icon___red
                        )}
                      />
                      <Text
                        type={textTypes.HEADING.XS}
                        colorClass={colorClasses.RED['400']}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        NO
                      </Text>
                    </>
                  )}
                </div>

                <div>
                  <Text>Vehicle Broken</Text>
                </div>
                <div>
                  {review.isVehicleBroken ? (
                    <>
                      <Icon
                        icon="close"
                        className={cn(
                          styles.ViewReview_mainRow_secondGrid_icon,
                          styles.ViewReview_mainRow_secondGrid_icon___red
                        )}
                      />
                      <Text
                        type={textTypes.HEADING.XS}
                        colorClass={colorClasses.RED['400']}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        YES
                      </Text>
                    </>
                  ) : (
                    <>
                      <Icon
                        icon="check"
                        className={cn(
                          styles.ViewReview_mainRow_secondGrid_icon,
                          styles.ViewReview_mainRow_secondGrid_icon___green
                        )}
                      />
                      <Text
                        type={textTypes.HEADING.XS}
                        colorClass={colorClasses.GREEN['400']}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        NO
                      </Text>
                    </>
                  )}
                </div>

                <div>
                  <Text>Hours Used</Text>
                </div>
                <div>
                  <Text
                    type={textTypes.HEADING.XS}
                    className={styles.ViewReview_mainRow_secondGrid_value}
                  >
                    {review.hours} hrs
                  </Text>
                </div>

                {review.notes && (
                  <>
                    <div>
                      <Text>Other Notes</Text>
                    </div>
                    <div>
                      <Text
                        type={textTypes.HEADING.XS}
                        className={styles.ViewReview_mainRow_secondGrid_value}
                      >
                        {review.notes}
                      </Text>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewReview;
