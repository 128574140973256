import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { Schedule } from '../..';
import { Modal, Text } from '../../elements';
import { colorClasses, modalSizes, textTypes } from '../../../globals';
import { RosterContext } from '../../../contexts';

const ViewRosterModal = ({
  isOpen,
  handleClose,
  title,
  roleId,
  roleName,
  roleDescription,
  roleColor,
  roleEmployees,
  employeeId,
  schedules,
}) => {
  const { dayIds, getEmployeeFullName } = useContext(RosterContext);

  return (
    <Modal
      size={modalSizes.MD}
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
    >
      <div className={styles.ViewRosterModal_withMarginBottom}>
        <Text type={textTypes.BODY.MD} className={styles.ViewRosterModal_label}>
          {roleId ? 'Role' : 'Employee'} Name
        </Text>

        <Text
          type={textTypes.HEADING.XXS}
          colorClass={colorClasses.VIOLET['400']}
        >
          {roleId ? roleName : getEmployeeFullName(employeeId)}
        </Text>
      </div>

      {roleId && (
        <>
          <div className={styles.ViewRosterModal_withMarginBottom}>
            <Text
              type={textTypes.BODY.MD}
              className={styles.ViewRosterModal_label}
            >
              Role Description
            </Text>

            <Text type={textTypes.HEADING.XXXS}>{roleDescription}</Text>
          </div>

          <div className={styles.ViewRosterModal_withMarginBottom}>
            <Text
              type={textTypes.BODY.MD}
              className={styles.ViewRosterModal_label}
            >
              Role Color
            </Text>

            <Text type={textTypes.HEADING.XXXS}>
              {roleColor.charAt(0).toUpperCase() + roleColor.slice(1)}
            </Text>
          </div>

          <div className={styles.ViewRosterModal_withMarginBottom}>
            <Text
              type={textTypes.BODY.MD}
              className={styles.ViewRosterModal_label}
            >
              Employees
            </Text>

            <Text type={textTypes.HEADING.XXXS}>
              {roleEmployees.map((roleEmployeeId) => (
                <Text type={textTypes.HEADING.XXXS} key={roleEmployeeId}>
                  {getEmployeeFullName(roleEmployeeId)}
                </Text>
              ))}
            </Text>
          </div>
        </>
      )}

      <Text type={textTypes.BODY.MD} className={styles.ViewRosterModal_label}>
        Schedules
      </Text>

      <Schedule
        dayIds={dayIds}
        values={{
          schedules,
        }}
        errors={{}}
        setFieldValue={() => {}}
        disabled
      />
    </Modal>
  );
};

ViewRosterModal.defaultProps = {
  roleId: null,
  roleName: null,
  roleDescription: null,
  roleColor: null,
  employeeId: null,
  roleEmployees: [],
  schedules: [],
};

ViewRosterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  roleId: PropTypes.string,
  roleName: PropTypes.string,
  roleDescription: PropTypes.string,
  roleColor: PropTypes.string,
  employeeId: PropTypes.string,
  roleEmployees: PropTypes.array,
  schedules: PropTypes.array,
};

export default ViewRosterModal;
