import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import EasyEdit from 'react-easy-edit';
import { useAlert } from 'react-alert';
import styles from '../styles.module.scss';

import { Icon, Text } from '../../elements';

const EditableNumber = ({
  minLength,
  maxLength,
  initialValue,
  placeholder,
  onSave,
}) => {
  const alert = useAlert();
  const [inputtedNumber, setInputtedNumber] = useState(initialValue);

  return (
    <EasyEdit
      type="number"
      saveButtonLabel={<Icon icon="check" />}
      cancelButtonLabel={<Icon icon="close" />}
      saveButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_save
      )}
      cancelButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_cancel
      )}
      displayComponent={
        <Text className={styles.Editable_displayValue}>{inputtedNumber}</Text>
      }
      placeholder={placeholder}
      value={inputtedNumber}
      onSave={(val) => {
        if (val < minLength || val > maxLength) {
          alert.error(`Oops, value should be >= ${minLength} to <= ${maxLength}.`);
          return;
        }

        setInputtedNumber(val);
        onSave(val);
      }}
    />
  );
};

EditableNumber.defaultProps = {
  minLength: 1,
  maxLength: 100,
  initialValue: null,
  placeholder: 'Empty',
};

EditableNumber.propTypes = {
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default EditableNumber;
