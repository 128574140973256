import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import EasyEdit from 'react-easy-edit';
import styles from '../styles.module.scss';

import { Icon, Text, ControlledInput, InputMask } from '../../elements';
import { useEffect } from 'react';

const EditablePrice= ({ initialValue, placeholder, onSave }) => {
  const [inputtedText, setInputtedText] = useState('');
  const [currentText, setCurrentText] = useState(initialValue);

  useEffect(() => {
    setCurrentText(initialValue);
  },[initialValue]);

  return (
    <EasyEdit
      type="number"
      saveButtonLabel={<Icon icon="check" />}
      cancelButtonLabel={<Icon icon="close" />}
      saveButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_save
      )}
      cancelButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_cancel
      )}
      editComponent={
        <InputMask
          maskPlaceholder=""
          alwaysShowMask
          name="EditablePrice"
          inputClassName={styles.Editable_input}
          onChange={(e) => setInputtedText(e.target.value)}
          withValue={false}
          withStyles={false}
        />
      }
      displayComponent={
        <Text>
          {currentText
            ? '$' + Number(currentText).toFixed(2)
            : ''}
        </Text>
      }
      placeholder={placeholder}
      value={currentText}
      onSave={() => {
        if (inputtedText != "") {
          setCurrentText(inputtedText);
          onSave(inputtedText);
        } else {
          setInputtedText(currentText);
        }
      }}
      cssClassPrefix={!currentText ? 'placeholder' : ''}
      onCancel={() => setInputtedText(currentText)}
    />
  );
};

EditablePrice.defaultProps = {
  initialValue: null,
  placeholder: 'Empty',
};

EditablePrice.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default EditablePrice;
