import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { RostersService } from '../services';
import { modalTypes } from '../screens/employer/Rosters/constants';

const useRosters = ({ employeeId, employerId, startDate, endDate }) => {
  const alert = useAlert();
  const [isRostersLoading, setIsRostersLoading] = useState(true);
  const [isRolesLoading, setIsRolesLoading] = useState(true);
  const [isAssigning, setIsAssigning] = useState(false);
  const [rosters, setRosters] = useState([]);
  const [roles, setRoles] = useState([]);

  const assignRoleOrShift = async (modalType, data) => {
    setIsAssigning(true);

    if (modalType === modalTypes.ASSIGN_SHIFT) {
      let shouldResetForm = false;

      // Create or update roster
      const { data: createOrUpdateRosterResponse } =
        await RostersService.createOrUpdateRoster({
          employerId,
          startDate,
          endDate,
          ...data,
        });

      if (createOrUpdateRosterResponse.message === 'roster_created') {
        // Set rosters state
        setRosters([
          ...rosters,
          { id: createOrUpdateRosterResponse.id, ...data },
        ]);

        // Since it is successful, we should reset the form.
        shouldResetForm = true;

        // Show an alert
        alert.success('Shift assigned!');
      } else if (createOrUpdateRosterResponse.message === 'roster_updated') {
        // Set rosters state
        setRosters((prevRosters) =>
          prevRosters.map((prevRoster) =>
            prevRoster.id === createOrUpdateRosterResponse.id
              ? {
                  id: createOrUpdateRosterResponse.id,
                  ...data,
                }
              : prevRoster
          )
        );

        // Since it is successful, we should reset the form.
        shouldResetForm = true;

        // Show an alert
        alert.success('Shift updated!');
      }

      setIsAssigning(false);

      return { shouldResetForm };
    }

    if (modalType === modalTypes.CREATE_ASSIGN_ROLE) {
      // Create role
      const { data: createRoleResponse } = await RostersService.createRole({
        employerId,
        ...data,
      });

      if (createRoleResponse.message === 'role_created') {
        // Set roles state
        setRoles([...roles, { roleId: createRoleResponse.id, ...data }]);
      }

      setIsAssigning(false);

      return { newlyCreatedRoleId: createRoleResponse.id };
    }

    if (modalType === modalTypes.UPDATE_ASSIGN_ROLE) {
      // Update role
      const { data: updateRoleResponse } = await RostersService.updateRole(
        data
      );

      if (updateRoleResponse.message === 'role_updated') {
        // Set roles state
        setRoles((prevRoles) =>
          prevRoles.map((prevRole) =>
            prevRole.roleId === data.roleId ? data : prevRole
          )
        );
      }

      setIsAssigning(false);
    }
  };

  const createOrUpdateRoster = async (roster) => {
    const { data: createOrUpdateRosterResponse } =
      await RostersService.createOrUpdateRoster({
        employerId,
        startDate,
        endDate,
        ...roster,
      });

    let shouldResetForm = false;

    if (createOrUpdateRosterResponse.message === 'roster_created') {
      // Set rosters state
      setRosters([
        ...rosters,
        { id: createOrUpdateRosterResponse.id, ...roster },
      ]);

      // Since it is successful, we should reset the form.
      shouldResetForm = true;

      // Show an alert
      alert.success('Role created and assigned!');
    } else if (createOrUpdateRosterResponse.message === 'roster_updated') {
      // Delete unneeded properties
      delete roster.employerId;
      delete roster.startDate;
      delete roster.endDate;

      // Set rosters state
      setRosters((prevRosters) =>
        prevRosters.map((prevRoster) =>
          prevRoster.id === createOrUpdateRosterResponse.id
            ? {
                id: createOrUpdateRosterResponse.id,
                ...roster,
              }
            : prevRoster
        )
      );

      // Since it is successful, we should reset the form.
      shouldResetForm = true;

      // Show an alert
      alert.success('Role updated and assigned!');
    } else {
      alert.error('Oops, something went wrong.');
    }

    return { shouldResetForm };
  };

  const deleteRoster = async (roleId, rosterId) => {
    const { data: deleteRosterResponse } = await RostersService.deleteRoster({
      rosterId,
    });

    // We assume that the roster deleted is a shift
    let alertMessage = 'Shift deleted.';
    let showAlert = true;

    if (deleteRosterResponse.message === 'roster_deleted') {
      // Set rosters
      setRosters((prevRosters) =>
        prevRosters.filter((prevRoster) => prevRoster.id !== rosterId)
      );

      if (roleId) {
        // This means the roster we are about to delete
        // is created from a role. So, we should delete the role as well
        const { data: deleteRoleResponse } = await RostersService.deleteRole({
          roleId,
        });

        if (deleteRoleResponse.message === 'role_deleted') {
          alertMessage = 'Role deleted.';

          setRoles((prevRoles) =>
            prevRoles.filter((prevRole) => prevRole.roleId !== roleId)
          );
        } else {
          showAlert = false;
          alert.error('Oops, something went wrong.');
        }
      }
    } else {
      showAlert = false;
      alert.error('Oops, something went wrong.');
    }

    if (showAlert) {
      alert.success(alertMessage);
    }
  };

  useEffect(() => {
    const getRosters = async () => {
      setIsRostersLoading(true);

      const { data: getRostersResponse } = await RostersService.getRosters({
        employerId,
        employeeId,
        startDate,
        endDate,
      });

      if (getRostersResponse.rosters) {
        setRosters(getRostersResponse.rosters);
      }

      setIsRostersLoading(false);
    };

    getRosters();
  }, [startDate, endDate]);

  useEffect(() => {
    const getRoles = async () => {
      setIsRolesLoading(true);

      const { data: getRolesResponse } = await RostersService.getRoles({
        employerId,
      });

      if (getRolesResponse.roles) {
        setRoles(getRolesResponse.roles);
      }

      setIsRolesLoading(false);
    };

    getRoles();
  }, []);

  return {
    isRostersLoading,
    rosters,
    isRolesLoading,
    roles,
    isAssigning,
    assignRoleOrShift,
    createOrUpdateRoster,
    deleteRoster,
  };
};

export default useRosters;
