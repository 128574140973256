import React, {useState, useContext, useEffect} from 'react';
import cn from 'classnames';
import styles from '../styles.module.scss';

import { Badge, Icon, IconButton, Text } from '../../elements';
import { SweetAlert } from '../..';
import { UserContext, TimesheetContext } from '../../../contexts';
import { colorClasses, colorNames, leaveTypes } from '../../../globals';
import {
  getPrevOrAfterDate,
  getDayNameFromDayId,
  getDayIdFromDate,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  convertTimeTo12HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import { getHours, checkStatus } from '../../../utils/timesheets';

import TimesheetCarousel from '../TimesheetCarousel';
import useGetEmployee from "../../../hooks/useGetEmployee";

const WeeklyView = ({handleChildState}) => {
  const { user } = useContext(UserContext);
  
  const {
    startDate,
    dayIds,
    employees,
    rosters,
    timesheets,
    overrideTimesheets,
    windowSize,
  } = useContext(TimesheetContext);

  const [activeEmployee, setActiveEmployee] = useState(null);
  const [overrideTimesheetsData, setOverrideTimesheetsData] = useState(null);

  const getEmployeeDetail = useGetEmployee({
    // emailAddress: user.emailAddress,
    emailAddress: user.userType == "Employee" ? user.employerDetails.employerEmailAddress : user.emailAddress,
  });

  useEffect(()=>{
    handleChildState(activeEmployee);
  },[activeEmployee,getEmployeeDetail.employee]);

  return (
    <>
      {employees.map(
        (
          {
            id,
            fullName,
            employerDetails: {
              isOvertime,
              expectedHoursPerWeek,
              isLunchBreakByDefault,
            },
          },
          pos
        ) => {
          let overallTotalHours = 0;
          let overtimeHours = 0;

          return (
            <div className={styles.TimesheetGrid_keyWrapper} key={id}>
              {activeEmployee !== id ? (
                <>
                  <button
                    type="button"
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_clickable
                    )}
                    onClick={() => setActiveEmployee(id)}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 1 / ${
                              (pos + 1) * 8 + 1
                            } / 1`,
                          }
                        : undefined
                    }
                  >
                    <Icon
                      icon="expand_more"
                      className={styles.Navbar_navUser_dropdown_link_icon}
                    />
                  </button>

                  <div
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_centerText
                    )}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 2 / ${
                              (pos + 1) * 8 + 1
                            } / 2`,
                          }
                        : undefined
                    }
                  >
                    {checkStatus(timesheets, id) === 'Active' ? (
                      <Badge text="Active" />
                    ) : (
                      <Badge text="Inactive" colorName={colorNames.GRAY} />
                    )}
                  </div>

                  <div
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_centerText
                    )}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 3 / ${pos * 8 + 1} / 3`,
                            fontWeight: 700,
                          }
                        : undefined
                    }
                  >
                    {fullName}
                  </div>

                  {windowSize.width <= 767 && (
                    <>
                      {dayIds.map((dayId) => (
                        <div
                          className={cn(styles.TimesheetGrid_column)}
                          key={getDayNameFromDayId(dayId)}
                          style={{
                            alignItems: 'flex-start',
                          }}
                        >
                          {getDayNameFromDayId(dayId)}
                        </div>
                      ))}
                    </>
                  )}

                  {[...Array(7)].map((_, i) => {
                    const timesheetDate = getPrevOrAfterDate(
                      startDate,
                      i,
                      'add'
                    );
                    const timesheetsForDay = timesheets.filter(
                      (timesheet) =>
                        timesheet.userId === id &&
                        timesheet.date === timesheetDate
                    );

                    let totalHours = 0;
                    timesheetsForDay.forEach(
                      ({
                        date,
                        startTimeDetails = {
                          endTime: undefined,
                          actualTime: undefined,
                        },
                        endTimeDetails = {
                          endTime: undefined,
                          actualTime: undefined,
                        },
                        isLunchBreak,
                        lunchBreakDuration,
                        isTravelCharge,
                        leaveType,
                        publicHolidayMultiplier,
                      }) => {
                        
                        const startTimeTimestamp = convertDateTimeToTimestamp(
                          date,
                          convertTimeTo24HourFormat(startTimeDetails.startTime)
                        );
                        const endTimeTimestamp = convertDateTimeToTimestamp(
                          date,
                          convertTimeTo24HourFormat(endTimeDetails.endTime)
                        );

                        const currentTimesheetHours = Number(
                          getHours(
                            startTimeTimestamp,
                            endTimeTimestamp,
                            isLunchBreak,
                            lunchBreakDuration,
                            isTravelCharge,
                            leaveType,
                            publicHolidayMultiplier
                          )
                        );
                      
                        totalHours += currentTimesheetHours;

                        if (
                          leaveType !== leaveTypes.ANNUAL &&
                          leaveType !== leaveTypes.SICK
                        ) {
                          overtimeHours += currentTimesheetHours;
                        }
                      }
                    );

                    overallTotalHours += totalHours;

                    return (
                      <div
                        className={styles.TimesheetGrid_column}
                        key={i}
                        style={
                          windowSize.width <= 767
                            ? {
                                gridArea: `${pos * 8 + 1 + (i + 1)} / 4 / ${
                                  pos * 8 + 1 + (i + 1)
                                } / 4`,
                              }
                            : undefined
                        }
                      >
                        {totalHours > 0
                          ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                            ? hoursWithDecimalToHoursAndMinutes(
                                totalHours.toFixed(2)
                              )
                            : (totalHours.toFixed(2))
                          : 0}
                      </div>
                    );
                  })}

                  <div
                    className={cn(styles.TimesheetGrid_column)}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 4 / ${pos * 8 + 1} / 4`,
                          }
                        : undefined
                    }
                  >
                    {!isOvertime ? (
                      <Text className={styles.TimesheetGrid_boldText}>
                        {overallTotalHours > 0
                          ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                            ? hoursWithDecimalToHoursAndMinutes(
                                overallTotalHours.toFixed(2)
                              )
                            : overallTotalHours.toFixed(2)
                          : 0}
                      </Text>
                    ) : (
                      <>
                        <Text
                          colorClass={colorClasses.BLUE['400']}
                          className={cn(
                            styles.TimesheetGrid_boldText,
                            styles.TimesheetGrid_centerText
                          )}
                        >
                          Expected:
                          <Text className={styles.TimesheetGrid_boldText}>
                            {expectedHoursPerWeek > 0
                              ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                                ? hoursWithDecimalToHoursAndMinutes(
                                    expectedHoursPerWeek.toFixed(2)
                                  )
                                : expectedHoursPerWeek.toFixed(2)
                              : 0}
                          </Text>
                        </Text>
                        <Text
                          colorClass={colorClasses.RED['400']}
                          className={cn(
                            styles.TimesheetGrid_boldText,
                            styles.TimesheetGrid_centerText,
                            styles.TimesheetGrid_withMargin
                          )}
                        >
                          Overtime:
                          <Text className={styles.TimesheetGrid_boldText}>
                            {overtimeHours > expectedHoursPerWeek
                              ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                                ? hoursWithDecimalToHoursAndMinutes(
                                    (
                                      overtimeHours - expectedHoursPerWeek
                                    ).toFixed(2)
                                  )
                                : (
                                    overtimeHours - expectedHoursPerWeek
                                  ).toFixed(2)
                              : 0}
                          </Text>
                        </Text>
                        <Text
                          colorClass={colorClasses.GREEN['400']}
                          className={cn(
                            styles.TimesheetGrid_boldText,
                            styles.TimesheetGrid_centerText,
                            styles.TimesheetGrid_withMargin
                          )}
                        >
                          Total:
                          <Text className={styles.TimesheetGrid_boldText}>
                            {overallTotalHours > 0
                              ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                                ? hoursWithDecimalToHoursAndMinutes(
                                    overallTotalHours.toFixed(2)
                                  )
                                : overallTotalHours.toFixed(2)
                              : 0}
                          </Text>
                        </Text>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_clickable,
                      styles.TimesheetGrid_clickable___active
                    )}
                    onClick={() => setActiveEmployee(null)}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 1 / ${
                              (pos + 1) * 8 + 1
                            } / 1`,
                          }
                        : undefined
                    }
                  >
                    <Icon
                      icon="expand_less"
                      className={styles.Navbar_navUser_dropdown_link_icon}
                    />
                  </button>

                  <div
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_column___active,
                      styles.TimesheetGrid_centerText
                    )}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 2 / ${
                              (pos + 1) * 8 + 1
                            } / 2`,
                          }
                        : undefined
                    }
                  >
                    {checkStatus(timesheets, id) === 'Active' ? (
                      <Badge text="Active" />
                    ) : (
                      <Badge text="Inactive" colorName={colorNames.GRAY} />
                    )}
                  </div>

                  <div
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_column___active,
                      styles.TimesheetGrid_centerText
                    )}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 3 / ${pos * 8 + 1} / 3`,
                            fontWeight: 700,
                          }
                        : undefined
                    }
                  >
                    {fullName}
                  </div>

                  {windowSize.width <= 767 && (
                    <>
                      {dayIds.map((dayId) => (
                        <div
                          className={cn(
                            styles.TimesheetGrid_column,
                            styles.TimesheetGrid_column___active
                          )}
                          key={getDayNameFromDayId(dayId)}
                          style={{
                            alignItems: 'flex-start',
                          }}
                        >
                          {getDayNameFromDayId(dayId)}
                        </div>
                      ))}
                    </>
                  )}

                  {[...Array(7)].map((_, i) => {
                    const timesheetDate = getPrevOrAfterDate(
                      startDate,
                      i,
                      'add'
                    );
                    const timesheetDayId = getDayIdFromDate(timesheetDate);

                    // Get rosters for day for us to compare if
                    // timesheets created are aligned with the rosters
                    const rostersForDay = [];
                    rosters.forEach((roster) => {
                      let isValid = false;

                      // Filtering rosters by employee
                      if (roster.roleId) {
                        // Roster is created from role
                        const employee = roster.employees.find(
                          (empId) => empId === id
                        );

                        // If employee is found, we set isValid to true
                        if (employee) {
                          isValid = true;
                        }
                      } else if (roster.employeeId === id) {
                        // Roster is created from shift.
                        // If employee is found, we set isValid to true
                        isValid = true;
                      }

                      if (isValid) {
                        // Filtering rosters by dayId
                        const filteredRoster = roster.schedules.find(
                          (schedule) => schedule.day === timesheetDayId
                        );
                        // We copy the filteredRoster since we will override
                        // the properties below.
                        const copyFilteredRoster = { ...filteredRoster };

                        if (Object.keys(copyFilteredRoster).length) {
                          // Convert times to timestamp
                          copyFilteredRoster.startTimeDetails = {
                            startTime: convertTimeTo12HourFormat(
                              copyFilteredRoster.startTime
                            ),
                          };

                          copyFilteredRoster.endTimeDetails = {
                            endTime: convertTimeTo12HourFormat(
                              copyFilteredRoster.endTime
                            ),
                          };

                          // Add/remove necessary properties
                          copyFilteredRoster.employeeId = id;
                          copyFilteredRoster.date = timesheetDate;
                          copyFilteredRoster.isLunchBreak =
                            isLunchBreakByDefault;
                          copyFilteredRoster.leaveType = 'None';
                          copyFilteredRoster.notes = '';
                          delete copyFilteredRoster.day;
                          delete copyFilteredRoster.startTime;
                          delete copyFilteredRoster.endTime;

                          // Then push the roster in rostersForDay
                          rostersForDay.push(copyFilteredRoster);
                        }
                      }
                    });

                    let isAlignedWithRosters = false;
                    let totalHours = 0;

                    const timesheetsForDay = timesheets.filter(
                      (timesheet) =>
                        timesheet.userId === id &&
                        timesheet.date === timesheetDate
                    );
                    const totalTimesheetsForDay = timesheetsForDay.length;
                    const totalTimesheetsForDayWithoutProjects =
                      timesheetsForDay.filter(
                        (timesheet) => timesheet.projectId === undefined
                      ).length;
                    const timesheetIdsForDay = [];

                    // Checking if rosters and timesheets are aligned starts here.
                    // If the condition below is false, it means that it is not aligned already
                    if (
                      totalTimesheetsForDayWithoutProjects ===
                      rostersForDay.length
                    ) {
                      isAlignedWithRosters = true;
                    }

                    timesheetsForDay.forEach(
                      ({
                        timesheetId,
                        date,
                        startTimeDetails = {
                          endTime: undefined,
                          actualTime: undefined,
                        },
                        endTimeDetails = {
                          endTime: undefined,
                          actualTime: undefined,
                        },
                        isLunchBreak,
                        lunchBreakDuration,
                        isTravelCharge,
                        leaveType,
                        publicHolidayMultiplier,
                        projectId,
                      }) => {
                        const startTimeTimestamp = convertDateTimeToTimestamp(
                          date,
                          convertTimeTo24HourFormat(startTimeDetails.startTime)
                        );
                        const endTimeTimestamp = convertDateTimeToTimestamp(
                          date,
                          convertTimeTo24HourFormat(endTimeDetails.endTime)
                        );

                        const currentTimesheetHours = Number(
                          getHours(
                            startTimeTimestamp,
                            endTimeTimestamp,
                            isLunchBreak,
                            lunchBreakDuration,
                            isTravelCharge,
                            leaveType,
                            publicHolidayMultiplier
                          )
                        );
                       
                        totalHours += currentTimesheetHours;

                        if (
                          leaveType !== leaveTypes.ANNUAL &&
                          leaveType !== leaveTypes.SICK
                        ) {
                          overtimeHours += currentTimesheetHours;
                        }

                        if (timesheetId && projectId === undefined) {
                          timesheetIdsForDay.push(timesheetId);
                        }

                        // Continuing checking if rosters and timesheets are aligned.
                        // We check if timesheet startTime and endTime can be found under the rosters
                        // NOTE: We only check the main timesheets, and if there is a possibility that
                        // both are aligned.
                        if (projectId === undefined && isAlignedWithRosters) {
                          let flag = false;
                          for (
                            let index = 0;
                            index < rostersForDay.length;
                            index++
                          ) {
                            const roster = rostersForDay[index];

                            if (
                              roster.startTimeDetails.startTime ===
                                startTimeDetails.startTime &&
                              roster.endTimeDetails.endTime ===
                                endTimeDetails.endTime
                            ) {
                              flag = true;
                              break;
                            }
                          }

                          if (!flag) {
                            isAlignedWithRosters = false;
                          }
                        }
                      }
                    );

                    overallTotalHours += totalHours;
                    
                    return (
                      <div
                        className={cn(
                          styles.TimesheetGrid_column,
                          styles.TimesheetGrid_column___active
                        )}
                        key={i}
                        style={
                          windowSize.width <= 767
                            ? {
                                gridArea: `${pos * 8 + 1 + (i + 1)} / 4 / ${
                                  pos * 8 + 1 + (i + 1)
                                } / 4`,
                              }
                            : undefined
                        }
                      >
                        {!totalTimesheetsForDay ? (
                          <>
                            {isAlignedWithRosters === false ? (
                              <IconButton
                                icon="info"
                                className={styles.TimesheetGrid_overrideIcon}
                                onClick={() =>
                                  setOverrideTimesheetsData({
                                    timesheetsToDelete: timesheetIdsForDay,
                                    timesheetsToCreate: rostersForDay,
                                  })
                                }
                              />
                            ) : (
                              <Text className={styles.TimesheetGrid_centerText}>
                                --
                              </Text>
                            )}
                          </>
                        ) : (
                          <TimesheetCarousel
                            totalHours={totalHours}
                            isAlignedWithRosters={isAlignedWithRosters}
                            setOverrideTimesheetsData={() =>
                              setOverrideTimesheetsData({
                                timesheetsToDelete: timesheetIdsForDay,
                                timesheetsToCreate: rostersForDay,
                              })
                            }
                            timesheetsForDay={timesheetsForDay}
                            totalTimesheets={totalTimesheetsForDay}
                          />
                        )}
                      </div>
                    );
                  })}

                  <div
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_column___active
                    )}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${pos * 8 + 1} / 4 / ${pos * 8 + 1} / 4`,
                          }
                        : undefined
                    }
                  >
                    {!isOvertime ? (
                      <Text className={styles.TimesheetGrid_boldText}>
                        {overallTotalHours > 0
                          ?( user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                            ? hoursWithDecimalToHoursAndMinutes(
                                overallTotalHours.toFixed(2)
                              )
                            : overallTotalHours.toFixed(2)
                          : 0}
                      </Text>
                    ) : (
                      <>
                        <Text
                          colorClass={colorClasses.BLUE['400']}
                          className={cn(
                            styles.TimesheetGrid_boldText,
                            styles.TimesheetGrid_centerText
                          )}
                        >
                          Expected:
                          <Text className={styles.TimesheetGrid_boldText}>
                            {expectedHoursPerWeek > 0
                              ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat )
                                ? hoursWithDecimalToHoursAndMinutes(
                                    expectedHoursPerWeek.toFixed(2)
                                  )
                                : expectedHoursPerWeek.toFixed(2)
                              : 0}
                          </Text>
                        </Text>
                        <Text
                          colorClass={colorClasses.RED['400']}
                          className={cn(
                            styles.TimesheetGrid_boldText,
                            styles.TimesheetGrid_centerText,
                            styles.TimesheetGrid_withMargin
                          )}
                        >
                          Overtime:
                          <Text className={styles.TimesheetGrid_boldText}>
                            {overtimeHours > expectedHoursPerWeek
                              ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                                ? hoursWithDecimalToHoursAndMinutes(
                                    (
                                      overtimeHours - expectedHoursPerWeek
                                    ).toFixed(2)
                                  )
                                : (
                                    overtimeHours - expectedHoursPerWeek
                                  ).toFixed(2)
                              : 0}
                          </Text>
                        </Text>
                        <Text
                          colorClass={colorClasses.GREEN['400']}
                          className={cn(
                            styles.TimesheetGrid_boldText,
                            styles.TimesheetGrid_centerText,
                            styles.TimesheetGrid_withMargin
                          )}
                        >
                          Total:
                          <Text className={styles.TimesheetGrid_boldText}>
                            {overallTotalHours > 0
                              ? (user?.hoursAndMinutesFormat || getEmployeeDetail.employee?.hoursAndMinutesFormat)
                                ? hoursWithDecimalToHoursAndMinutes(
                                    overallTotalHours.toFixed(2)
                                  )
                                : overallTotalHours.toFixed(2)
                              : 0}
                          </Text>
                        </Text>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        }
      )}

      <SweetAlert
        show={overrideTimesheetsData !== null}
        title="Are you sure?"
        onConfirm={() => {
          overrideTimesheets(
            overrideTimesheetsData.timesheetsToDelete,
            overrideTimesheetsData.timesheetsToCreate
          );

          setOverrideTimesheetsData(null);
        }}
        onCancel={() => setOverrideTimesheetsData(null)}
      >
        This will override all of the timesheets (except project timesheets)
        under this day to the roster set for this day.
      </SweetAlert>
    </>
  );
};

export default WeeklyView;
