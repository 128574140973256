import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Formik } from 'formik';
import ReactTooltip from 'react-tooltip';
import dateFormat from 'dateformat';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';
import cn from 'classnames';
import swal from 'sweetalert';
import Preloader from './Preloader';
import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  ControlledSelect,
  ControlledTextArea,
  DatePicker,
  Icon,
  TimePicker,
  KeyboardTimePicker,
  InputMask,
  Spinner,
  Text,
  IconButton,
  HoursPicker,
} from '../../../components/elements';
import { CreateReviewModal } from '../../../components/modals';
import {
  buttonKinds,
  colorClasses,
  colorNames,
  leaveTypes,
  spinnerSizes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import {
  useCreateTimesheet,
  useVehicles,
  useClient,
  useProject,
  useClients,
  useProjects,
} from '../../../hooks';
import { TimesheetsService, UsersService } from '../../../services';
import {
  checkIfTimeIsValid,
  convertTimeTo24HourFormat,
  convertDateTimeToTimestamp,
  timeMask,
  convertTimeTo12HourFormat,
  createMomentWithDummyDate,
  momentTo24HourTime,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import { getHours, isTimesheetTimesValid } from '../../../utils/timesheets';

import useGetEmployee from '../../../hooks/useGetEmployee';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useGetSingleTimesheet from '../../../hooks/useGetSingleTimesheet';
import axios from 'axios';
import config from '../../../services/config';
const validate = (values) => {
  const errors = {};

  if (!values.date) {
    errors.date = 'This field is required.';
  }

  if (values.leaveType.value === leaveTypes.NONE) {
    if (!values.startTime) {
      errors.startTime = 'This field is required.';
    } else if (!checkIfTimeIsValid(convertTimeTo12HourFormat(momentTo24HourTime(values.startTime)))) {
      errors.startTime = 'Invalid time format.';
    }

    if (!values.endTime) {
      errors.endTime = 'This field is required.';
    } else if (!checkIfTimeIsValid(convertTimeTo12HourFormat(momentTo24HourTime(values.endTime)))) {
      errors.endTime = 'Invalid time format.';
    }
    if (values.isLunchBreak.value && !values.lunchBreakDuration) {
      errors.lunchBreakDuration = 'This field is required.';
    } 
    // if(!checkIfTimeIsValid(convertTimeTo12HourFormat(values.lunchBreakDuration))){
    //   errors.lunchBreakDuration = 'Invalid time format.';
    // }
    if(values.isLunchBreak && values.lunchBreakDuration){
      if(!checkIfTimeIsValid(convertTimeTo12HourFormat(values.lunchBreakDuration))){
          errors.lunchBreakDuration = 'Invalid time format.';
        }
    }
  }
  
  return errors;
};

const CreateTimesheet = ({ pageTitle, pages }) => {

  let { clientId, projectId } = useParams();
  const BASE_URL = `${config.API_URL}/timesheets`;

  const alert = useAlert();
  const { user } = useContext(UserContext);
  const {isLoading: isUserLoading, employee: getEmployeeDetail} = useGetEmployee({
    emailAddress: user.userType == "Employee" ? user.employerDetails.employerEmailAddress : user.emailAddress,
  });
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [isSettingVehicleOptions, setIsSettingVehicleOptions] = useState(true);
  const [showReviewVehicleModal, setShowReviewVehicleModal] = useState(false);
  const [createNewTimesheetFunc, setCreateNewTimesheetFunc] = useState(null);
  const [hours, setHours] = useState(0);
  const [addCost, setAddCost] = useState([]);
  const [costItemName, setCostItemName] = useState('');
  const [costItemPrice, setCostItemPrice] = useState('');
  const [clientValue, setClientValue] = useState('');
  const [clientName, setClientName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const { clients } = useClients({
    userId: user?.employerDetails?.employerId,
  });
  const { projects, isLoading: isClientProjectLoading } = useProjects({
    clientId: clientValue,
    isFromCreateTimeSheet: true,
  })
  if (clientValue !== null && clientValue !== undefined) {
    clientId = clientValue;
  }
  if (projectValue !== null && projectValue !== undefined) {
    projectId = projectValue;
  }
  const { isCreating: isTimesheetCreating, createTimesheet } =
    useCreateTimesheet();

  const urlParams = new URLSearchParams(window.location.search);
  const [copyTimesheetData,setCopyTimesheetData] = useState(null);

  const timesheet  = urlParams.get('timesheet');

  useEffect(() => {
    if (timesheet) {
      axios.post(`${BASE_URL}/timesheet-by-id`, {
        timesheet_id: timesheet
      }).then((response) => {
        if (response.data.success) {
          setCopyTimesheetData(response.data.data);

          if (response.data.data.clientId) {
            setClientValue(response.data.data.clientId);
            setClientName(response.data.data.clientName);
          }
          if (response.data.data.projectId) {
            setProjectValue(response.data.data.projectId);
            setProjectName(response.data.data.projectName);
          }

          const tempData = response.data.data;
          const date = new Date(moment.unix(tempData.date._seconds).format('Y-MM-DD'));
          const startTime = tempData.startTimeDetails?.startTime;
          const endTime = tempData.endTimeDetails?.endTime;
          setHours(
            getHours(
              convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(startTime)),
              convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(endTime)),
              tempData.isLunchBreak,
              tempData.lunchBreakDuration,
              tempData.isTravelCharge,
              tempData.leaveType,
              tempData.publicHolidayMultiplier
            )
          );
        }
      });
    }

  }, []);

  const { isLoading: isClientLoading, client } = useClient({
    clientId,
  });

  const { isLoading: isVehiclesLoading, vehicles } = useVehicles({
    employerId: user.employerDetails.employerId,
  });

  const { isLoading: isProjectLoading, project } = useProject({
    clientId,
    projectId,
  });
  const onClickAddCost = (event) => {
    if (costItemName != '') {
      setAddCost(addCost.concat({
        itemName: costItemName,
        price: costItemPrice || 0,
      }));
    }
  };
  const removeCost = (index) => {
    const newCost = addCost.filter((_, i) => i !== index);
    setAddCost(newCost);
  };

  const updateHours = (
    date,
    startTime,
    endTime,
    isLunchBreak = false,
    lunchBreakDuration = "00:00",
    isTravelCharge = false,
    leaveType = 'None',
    multiplier,
    setFieldValue
  ) => {

    // Set form field values depending on leaveType
    startTime = convertTimeTo12HourFormat(momentTo24HourTime(startTime));
    endTime = convertTimeTo12HourFormat(momentTo24HourTime(endTime));
    if (
      leaveType === leaveTypes.ANNUAL ||
      leaveType === leaveTypes.WITHOUT_PAY
    ) {
      setFieldValue('startTime', undefined);
      setFieldValue('endTime', undefined);
      setFieldValue(
        'isLunchBreak',
        user.employerDetails.isLunchBreakByDefault
          ? { label: 'Yes', value: true }
          : {
            label: 'No',
            value: false,
          }
      );
      setFieldValue(
        'isTravelCharge',
        user.employerDetails.isTravelChargeByDefault
          ? { label: 'Yes', value: true }
          : {
            label: 'No',
            value: false,
          }
      );
      setHours(getHours(null, null,null, null, leaveType));

      return;
    }

    // Set the hours depending on certain conditions
    if (date && checkIfTimeIsValid(startTime) && checkIfTimeIsValid(endTime)) {
      setHours(
        getHours(
          convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(startTime)),
          convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(endTime)),
          isLunchBreak,
          lunchBreakDuration,
          isTravelCharge,
          leaveType,
          multiplier
        )
      );
    }
    else if (
      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
      leaveType === leaveTypes.SICK
    ) {
      setHours(getHours(null, null, null, null, leaveType));
    } else {
      setHours(0);
    }
  };

  // Generate vehicle options
  useEffect(() => {
    if (!isVehiclesLoading) {
      const options = [
        {
          label: 'N/A',
          value: null,
        },
      ];
      vehicles.forEach(({ id, name }) => {
        const option = {
          label: name,
          value: id,
        };

        options.push(option);
      });

      setVehicleOptions(options);
      setIsSettingVehicleOptions(false);
    }
  }, [isVehiclesLoading]);

  return (
    <div className={styles.CreateTimesheet}>

      <Breadcrumbs pageTitle={pageTitle} pages={pages} />
      {isSettingVehicleOptions || isClientLoading || isProjectLoading || isUserLoading || isClientProjectLoading ? (
        <Preloader />
      ) : (
        <Card className={styles.CreateTimesheet_card}>
          <Formik
            initialValues={{
              date: new Date(),
              startTime: copyTimesheetData?.startTimeDetails?.startTime ? moment(copyTimesheetData?.startTimeDetails?.startTime,user.employerDetails?.is24Hour ? 'HH:mm a' : 'hh:mm a').format() : undefined,
              endTime: copyTimesheetData?.endTimeDetails?.endTime ? moment(copyTimesheetData?.endTimeDetails?.endTime,user.employerDetails?.is24Hour ? 'HH:mm a' : 'hh:mm a').format() : undefined,
              isLunchBreak: (user.employerDetails.isLunchBreakByDefault)
                ? { label: 'Yes', value: true }
                : {
                  label: 'No',
                  value: false,
                },
              lunchBreakDuration: copyTimesheetData?.isLunchBreak ? copyTimesheetData?.lunchBreakDuration : undefined,
              isTravelCharge: {
                label: 'No',
                value: false,
              }, leaveType: {
                label: leaveTypes.NONE,
                value: leaveTypes.NONE,
              },
              vehicle: {
                label: 'N/A',
                value: null,
              },
              notes: copyTimesheetData?.notes ? copyTimesheetData?.notes : undefined,
              performedNotes: copyTimesheetData?.performedNotes ? copyTimesheetData?.performedNotes : undefined,
              isBillable: {
                label: 'No',
                value: false,
              },
              costs: [],
              client: copyTimesheetData?.clientId ? {
                value: clients.find((item) => item.id === copyTimesheetData?.clientId)?.id,
                label: clients.find((item) => item.id === copyTimesheetData?.clientId)?.name,
              } : undefined,
              project: projects.length > 0 ? {
                value: projects.find((item) => item.id === copyTimesheetData?.projectId)?.id,
                label: projects.find((item) => item.id === copyTimesheetData?.projectId)?.name,
              } : undefined,
            }}
            onSubmit={async (values, { setErrors, setFieldValue }) => {
          
              const errors = validate(values);

              if (!isEmpty(errors)) {
                setErrors(errors);
                return;
              }
              // return;
              const createNewTimesheet = async (vehicleReviewId) => {
                const newTimesheet = {
                  userId: user.id,
                  employerId: user.employerDetails.employerId,
                  date: dateFormat(values.date, 'yyyy-mm-dd'),
                  leaveType: values.leaveType.value,
                  publicHolidayMultiplier:
                    user.employerDetails.publicHolidayMultiplier,
                  notes: values.notes,
                  performedNotes: values.performedNotes,
                  clientId: clientId || undefined,
                  clientName: clientName || client?.name || undefined,
                  projectId: projectId || undefined,
                  projectName: projectName || project?.name || undefined,
                  isBillable: projectId ? values.isBillable.value : undefined,
                  vehicleReviewId: vehicleReviewId || undefined,
                  wage: user.employerDetails?.wage || 0,
                  costs: addCost || [],
                };
                // Add necessary properties depending on leaveType
                if (
                  values.leaveType.value === leaveTypes.NONE ||
                  values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                  values.leaveType.value === leaveTypes.SICK
                ) {
                  newTimesheet.startTimeDetails = {
                    startTime: convertTimeTo12HourFormat(momentTo24HourTime(values.startTime)),
                  };
                  newTimesheet.endTimeDetails = {
                    endTime: convertTimeTo12HourFormat(momentTo24HourTime(values.endTime)),
                  };
                  newTimesheet.isLunchBreak = values.isLunchBreak.value;
                  newTimesheet.lunchBreakDuration = values.lunchBreakDuration;
                  newTimesheet.isTravelCharge = values.isTravelCharge.value;
                }
              
                // Check if times of this timesheet is not overlapping
                // any times in timesheets array
                const { data: getTimesheetsResponse } =
                  await TimesheetsService.get({
                    userId: newTimesheet.userId,
                    startDate: newTimesheet.date,
                    endDate: newTimesheet.date,
                  });

                const { timesheets } = getTimesheetsResponse;
                const startTime = convertTimeTo12HourFormat(momentTo24HourTime(values.startTime));
                const endTime = convertTimeTo12HourFormat(momentTo24HourTime(values.endTime));
                const diffMins = moment.utc(endTime, 'HH:mm a').diff(moment.utc(startTime, 'HH:mm a'), 'minutes');

                const lunchTime = values.lunchBreakDuration;
                const lunchHour = moment.utc(lunchTime,'hh:mm a').get('hour');
                const lunchMinutes = moment.utc(lunchTime,'hh:mm a').get('minute');
                const totalMinutes = (lunchHour * 60) + lunchMinutes

                if ((startTime === endTime) && 
                  (values.leaveType.value === leaveTypes.NONE ||
                    values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                    values.leaveType.value === leaveTypes.SICK)) {
                    alert.error(
                      'Oops, start and end time cannot be the same'
                    );
                    return;
                  }
                  if ((Math.abs(diffMins) < 15) && 
                  (values.leaveType.value === leaveTypes.NONE ||
                    values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                    values.leaveType.value === leaveTypes.SICK)) {
                    alert.error(
                      'Oops, please record a timesheet for at least 15 minutes'
                    );
                    return;
                  }
                  if ((( diffMins > 0 && Math.abs(diffMins) <= totalMinutes) || totalMinutes > 600 || totalMinutes === 0) &&
                  (values.leaveType.value === leaveTypes.NONE ||
                    values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                    values.leaveType.value === leaveTypes.SICK)) {
                  alert.error(
                    'Oops, please select a valid lunch time'
                  );
                  return;
                  }
                // if (
                //   values.startTime &&
                //   values.endTime &&
                //   !isTimesheetTimesValid(timesheets, {
                //     startTime: convertTimeTo12HourFormat(momentTo24HourTime(values.startTime)),
                //     endTime: convertTimeTo12HourFormat(momentTo24HourTime(values.endTime)),
                //   }) &&
                //   (values.leaveType.value === leaveTypes.NONE ||
                //     values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                //     values.leaveType.value === leaveTypes.SICK)
                // ) {
                //   alert.error(
                //     'Oops, the time/s you have inputted overlaps an existing timesheet.'
                //   );
                //   return;
                // }
                if (getEmployeeDetail?.isNoteNeeded) {
                  if (getEmployeeDetail?.isWorkPerformed && (newTimesheet.notes === undefined || newTimesheet.performedNotes === undefined)) {
                    swal({
                      title: "Notes needed",
                      text: "You must have to add a notes",
                      icon: "warning"
                    })
                    return;
                  }
                  if (!getEmployeeDetail?.isWorkPerformed && newTimesheet.notes === undefined) {
                    swal({
                      title: "Notes needed",
                      text: "You must have to add a notes",
                      icon: "warning"
                    })
                    return;
                  }
                }
                if (getEmployeeDetail?.isProjectClient && (newTimesheet.clientId === undefined || newTimesheet.projectId === undefined)) {
                  swal({
                    title: "Are you sure?",
                    text: "You haven’t added a timesheet to a project/client are you sure you want to continue",
                    icon: "warning",
                    buttons: ["No", "Yes"],
                  }).then(async (ok) => {
                    if (ok) {
                      const { responseCode: createTimesheetResponseCode } =
                        await createTimesheet(newTimesheet);

                      const createTimesheetCallbacks = {
                        created: () => {
                          alert.success('Timesheet created!');
                          setFieldValue('client', clients[0]);
                          setFieldValue('project', projects[0]);
                          // Reset the form to its initial state
                          setFieldValue('startTime', undefined);
                          setFieldValue('endTime', undefined);
                          setFieldValue(
                            'isLunchBreak',
                            user.employerDetails.isLunchBreakByDefault
                              ? { label: 'Yes', value: true }
                              : {
                                label: 'No',
                                value: false,
                              }
                          );
                          setFieldValue('lunchBreakDuration', undefined);
                          setFieldValue(
                            'isTravelCharge', {
                            label: 'No',
                            value: false,
                          }
                          );
                          setFieldValue('leaveType', {
                            label: leaveTypes.NONE,
                            value: leaveTypes.NONE,
                          });
                          setFieldValue('vehicle', vehicleOptions[0]);
                          setFieldValue('notes', undefined);
                          setFieldValue('performedNotes', undefined);
                          setFieldValue('itemName', '');
                          setFieldValue('price', '');
                          setHours(0);
                          setAddCost([]);
                        },
                        invalidFields: () => alert.error('Invalid fields.'),
                        internalError: () =>
                          alert.error('Oops, something went wrong.'),
                      };

                      switch (createTimesheetResponseCode) {
                        case 200:
                          createTimesheetCallbacks.created();
                          break;
                        case 400:
                          createTimesheetCallbacks.invalidFields();
                          break;
                        case 500:
                          createTimesheetCallbacks.internalError();
                          break;
                        default:
                          break;
                      }
                    }
                  })
                } else {
                  const { responseCode: createTimesheetResponseCode } =
                    await createTimesheet(newTimesheet);

                  const createTimesheetCallbacks = {
                    created: () => {
                      alert.success('Timesheet created!');
                      setFieldValue('client', clients[0]);
                      setFieldValue('project', projects[0]);
                      // Reset the form to its initial state
                      setFieldValue('startTime', undefined);
                      setFieldValue('endTime', undefined);
                      setFieldValue(
                        'isLunchBreak',
                        user.employerDetails.isLunchBreakByDefault
                          ? { label: 'Yes', value: true }
                          : {
                            label: 'No',
                            value: false,
                          }
                      );
                      setFieldValue('lunchBreakDuration', undefined);
                      setFieldValue(
                        'isTravelCharge', {
                        label: 'No',
                        value: false,
                      }
                      );
                      setFieldValue('leaveType', {
                        label: leaveTypes.NONE,
                        value: leaveTypes.NONE,
                      });
                      setFieldValue('vehicle', vehicleOptions[0]);
                      setFieldValue('notes', undefined);
                      setFieldValue('performedNotes', undefined);
                      setFieldValue('itemName', '');
                      setFieldValue('price', '');
                      setHours(0);
                      setAddCost([]);
                    },
                    invalidFields: () => alert.error('Invalid fields.'),
                    internalError: () =>
                      alert.error('Oops, something went wrong.'),
                  };

                  switch (createTimesheetResponseCode) {
                    case 200:
                      createTimesheetCallbacks.created();
                      break;
                    case 400:
                      createTimesheetCallbacks.invalidFields();
                      break;
                    case 500:
                      createTimesheetCallbacks.internalError();
                      break;
                    default:
                      break;
                  }
                }
              };

              if (
                user?.employerDetails?.showVehiclesTab &&
                values?.vehicle?.value
              ) {
                setShowReviewVehicleModal(true);
                setCreateNewTimesheetFunc(() => createNewTimesheet);
              } else {
                createNewTimesheet();
              }
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DatePicker
                    className={styles.CreateTimesheet_withMargin}
                    dateFormat="dd/MM/yyyy"
                    selected={values.date}
                    placeholder="Date*"
                    name="date"
                    icon="today"
                    onChange={(date) => {
                      setFieldValue('date', date);
                      updateHours(
                        date,
                        values.startTime,
                        values.endTime,
                        values.isLunchBreak.value,
                        values.lunchBreakDuration,
                        values.isTravelCharge.value,
                        values.leaveType.value,
                        user.employerDetails.publicHolidayMultiplier,
                        setFieldValue
                      );
                    }}
                  />
                  <ControlledSelect
                    className={styles.CreateTimesheet_withMarginDrop}
                    options={clients.map((client) => ({
                      value: client.id,
                      label: client.name,
                    }))}
                    name="client"
                    placeholder="Client"
                    value={values.client}
                    error={errors.client}
                    onChange={(val) => {
                      setFieldValue('client', val);
                      setClientValue(val.value.toString());
                      setClientName(val.label);

                      setFieldValue('project', "");
                      setProjectValue("");
                      setProjectName("");
                    }}
                  />
                  <ControlledSelect
                    className={styles.CreateTimesheet_withMarginDrop}
                    options={projects.map((project) => ({
                      value: project.id,
                      label: project.name,
                    }))}
                    name="project"
                    placeholder="Project"
                    value={values.project}
                    error={errors.project}
                    onChange={(val) => {
                      setFieldValue('project', val);
                      setProjectValue(val.value);
                      setProjectName(val.label);
                    }}
                  />
                  {/* <div className={styles.CreateTimesheet_withMargin}>
                    <Text
                      type={textTypes.BODY.MD}
                    >
                      Start Time *
                    </Text>
                    <TimePicker
                      time={values?.startTime ? createMomentWithDummyDate(values.startTime) : null}
                      onChange={(newTime) => {
                        setFieldValue('startTime', momentTo24HourTime(newTime),
                          updateHours(
                            values.date,
                            momentTo24HourTime(newTime),
                            values.endTime,
                            values.isLunchBreak.value,
                            values.isTravelCharge.value,
                            values.leaveType.value,
                            user.employerDetails.publicHolidayMultiplier,
                            setFieldValue
                          ))
                      }
                      }
                      error={errors.startTime}
                      clearable
                      icon="schedule"
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    />
                  </div> */}
                  {/* <div className={styles.CreateTimesheet_withMargin}>
                    <Text
                      type={textTypes.BODY.MD}
                    >
                      End Time *
                    </Text>
                    <TimePicker
                      time={values?.endTime ? createMomentWithDummyDate(values.endTime) : null}
                      onChange={(newTime) => {
                        setFieldValue('endTime', momentTo24HourTime(newTime),
                          updateHours(
                            values.date,
                            values.startTime,
                            momentTo24HourTime(newTime),
                            values.isLunchBreak.value,
                            values.isTravelCharge.value,
                            values.leaveType.value,
                            user.employerDetails.publicHolidayMultiplier,
                            setFieldValue
                          ));

                      }
                      }
                      error={errors.endTime}
                      clearable
                      icon="schedule"
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    />
                  </div> */}
                  <div className={styles.CreateTimesheet_withMargin}>
                    <Text
                      type={textTypes.BODY.MD}
                    >
                      Start Time *
                    </Text>
                    <KeyboardTimePicker
                      time={values?.startTime}
                      onChange={(newTime) => {
                        setFieldValue('startTime', newTime,
                          updateHours(
                            values.date,
                            newTime,
                            values.endTime,
                            values.isLunchBreak.value,
                            values.lunchBreakDuration,
                            values.isTravelCharge.value,
                            values.leaveType.value,
                            user.employerDetails.publicHolidayMultiplier,
                            setFieldValue
                          ))
                      }}
                      ampm={getEmployeeDetail?.is24Hour ? false : true}
                      mask={getEmployeeDetail?.is24Hour ? "__:__" : "__:__ _M"}
                      error={errors.startTime}
                      clearable
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    />
                  </div>
                  <div className={styles.CreateTimesheet_withMargin}>
                    <Text
                      type={textTypes.BODY.MD}
                    >
                      End Time *
                    </Text>
                    <KeyboardTimePicker
                      time={values?.endTime}
                      onChange={(newTime) => {
                        setFieldValue('endTime', newTime,
                          updateHours(
                            values.date,
                            values.startTime,
                            newTime,
                            values.isLunchBreak.value,
                            values.lunchBreakDuration,
                            values.isTravelCharge.value,
                            values.leaveType.value,
                            user.employerDetails.publicHolidayMultiplier,
                            setFieldValue
                          ));
                      }
                      }
                      ampm={getEmployeeDetail?.is24Hour ? false : true}
                      mask={getEmployeeDetail?.is24Hour ? "__:__" : "__:__ _M"}
                      error={errors.endTime}
                      clearable
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    />
                  </div>
                  {/* <InputMask
                      className={cn(
                        styles.CreateTimesheet_withMargin,
                        styles.CreateTimesheet_removeRightBorder,
                      )}
                      mask={timeMask(values.startTime)}
                      maskPlaceholder="hh:mm xm"
                      placeholder="Start Time*"
                      name="startTime"
                      value={values.startTime}
                      error={errors.startTime}
                      onChange={(e) => setFieldValue('startTime', e.target.value)}
                      onBlur={() => {
                        updateHours(
                          values.date,
                          values.startTime,
                          values.endTime,
                          values.isLunchBreak.value,
                          values.isTravelCharge.value,
                          values.leaveType.value,
                          user.employerDetails.publicHolidayMultiplier,
                          setFieldValue
                        );
                      }}
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    /> */}

                  {/* <InputMask
                    className={styles.CreateTimesheet_withMargin}
                    mask={timeMask(values.endTime)}
                    maskPlaceholder="hh:mm xm"
                    placeholder="End Time*"
                    name="endTime"
                    icon="schedule"
                    value={values.endTime}
                    error={errors.endTime}
                    onChange={(e) => setFieldValue('endTime', e.target.value)}
                    onBlur={() => {
                      updateHours(
                        values.date,
                        values.startTime,
                        values.endTime,
                        values.isLunchBreak.value,
                        values.isTravelCharge.value,
                        values.leaveType.value,
                        user.employerDetails.publicHolidayMultiplier,
                        setFieldValue
                      );
                    }}
                    disabled={
                      values.leaveType.value === leaveTypes.ANNUAL ||
                      values.leaveType.value === leaveTypes.WITHOUT_PAY
                    }
                  /> */}
                  <ControlledSelect
                    className={styles.CreateTimesheet_withMarginDrop}
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ]}
                    name="isLunchBreak"
                    placeholder="Lunch Break*"
                    value={values.isLunchBreak}
                    error={errors.isLunchBreak}
                    onChange={(val) => {
                      setFieldValue('isLunchBreak', {
                        label: val.label,
                        value: val.value,
                      });
                      setFieldValue('lunchBreakDuration', val.value ? values.lunchBreakDuration : undefined);
                      updateHours(
                        values.date,
                        values.startTime,
                        values.endTime,
                        val.value,
                        values.lunchBreakDuration,
                        values.isTravelCharge.value,
                        values.leaveType.value,
                        user.employerDetails.publicHolidayMultiplier,
                        setFieldValue
                      );
                    }}
                    disabled={
                      values.leaveType.value === leaveTypes.ANNUAL ||
                      values.leaveType.value === leaveTypes.WITHOUT_PAY
                    }
                  />
                    {
                      values.isLunchBreak.value &&
                      <div className={styles.CreateTimesheet_withMargin}>
                        <Text
                          type={textTypes.BODY.MD}
                        >
                          Lunch Break Duration
                        </Text>
                       {
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <HoursPicker
                            ampm={false}
                            format={'HH:mm'}
                            minTime={dayjs().set('hour',0).set('minutes',0)}
                            maxTime={dayjs().set('hour',10).set('minutes',0)}
                            onChange={(time)=>{
                              setFieldValue('lunchBreakDuration',moment(new Date(time).getTime()).format('HH:mm'));
                              updateHours(
                                values.date,
                                values.startTime,
                                values.endTime,
                                values.isLunchBreak,
                                moment(new Date(time).getTime()).format('HH:mm'),
                                values.isTravelCharge.value,
                                values.leaveType.value,
                                user.employerDetails.publicHolidayMultiplier,
                                setFieldValue
                              );
                            }}
                            error={errors.lunchBreakDuration}
                            clearable
                            disabled={
                              values.leaveType.value === leaveTypes.ANNUAL ||
                              values.leaveType.value === leaveTypes.WITHOUT_PAY
                            }
                          /> 
                        </LocalizationProvider>
                       }
                      </div>
                    }

                  {getEmployeeDetail?.isTravelChargeByDefault &&
                    <ControlledSelect
                      className={styles.CreateTimesheet_withMarginDrop}
                      options={[
                        {
                          label: 'Yes',
                          value: true,
                        },
                        {
                          label: 'No',
                          value: false,
                        },
                      ]}
                      name="isTravelCharge"
                      placeholder="Travel Charge*"
                      value={values.isTravelCharge}
                      error={errors.isTravelCharge}
                      onChange={(val) => {
                        setFieldValue('isTravelCharge', {
                          label: val.label,
                          value: val.value,
                        });

                        updateHours(
                          values.date,
                          values.startTime,
                          values.endTime,
                          values.isLunchBreak.value,
                          values.lunchBreakDuration,
                          val.value,
                          values.leaveType.value,
                          user.employerDetails.publicHolidayMultiplier,
                          setFieldValue
                        );
                      }}
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    />
                  }
                  <ControlledSelect
                    className={styles.CreateTimesheet_withMarginDrop}
                    options={[
                      {
                        label: leaveTypes.NONE,
                        value: leaveTypes.NONE,
                      },
                      {
                        label: leaveTypes.ANNUAL,
                        value: leaveTypes.ANNUAL,
                      },
                      {
                        label: leaveTypes.PUBLIC_HOLIDAY,
                        value: leaveTypes.PUBLIC_HOLIDAY,
                      },
                      {
                        label: leaveTypes.SICK,
                        value: leaveTypes.SICK,
                      },
                      {
                        label: leaveTypes.WITHOUT_PAY,
                        value: leaveTypes.WITHOUT_PAY,
                      },
                    ]}
                    name="leaveType"
                    placeholder="Leave Type*"
                    value={values.leaveType}
                    error={errors.leaveType}
                    onChange={(val) => {
                      setFieldValue('leaveType', {
                        label: val.label,
                        value: val.value,
                      });

                      updateHours(
                        values.date,
                        values.startTime,
                        values.endTime,
                        values.isLunchBreak.value,
                        values.lunchBreakDuration,
                        values.isTravelCharge.value,
                        val.value,
                        user.employerDetails.publicHolidayMultiplier,
                        setFieldValue
                      );
                    }}
                  />

                  {user?.employerDetails?.showVehiclesTab && (
                    <ControlledSelect
                      className={styles.CreateTimesheet_withMarginDrop}
                      options={vehicleOptions}
                      name="vehicle"
                      placeholder="Vehicle/Plant Used*"
                      value={values.vehicle}
                      error={errors.vehicle}
                      onChange={(val) => {
                        setFieldValue('vehicle', {
                          label: val.label,
                          value: val.value,
                        });
                      }}
                    />
                  )}

                  <ControlledInput
                    className={styles.CreateTimesheet_withMargin}
                    name="hours"
                    placeholder="Hours"
                    icon="schedule"
                    // value={`${hoursWithDecimalToHoursAndMinutes(hours)}`}
                    value={
                      getEmployeeDetail?.hoursAndMinutesFormat
                        ? hoursWithDecimalToHoursAndMinutes(hours)
                        : `${hours}`
                    }
                    disabled
                  />
                  {getEmployeeDetail?.isWorkPerformed && <ControlledTextArea
                    className={styles.CreateTimesheet_withMargin}
                    name="performedNotes"
                    placeholder="Performed For:"
                    icon="description"
                    value={values.performedNotes}
                    error={errors.performedNotes}
                    onChange={(e) => setFieldValue('performedNotes', e.target.value)}
                  />
                  }
                  <ControlledTextArea
                    className={styles.CreateTimesheet_withMargin}
                    name="notes"
                    placeholder="Work Performed: (issue/resolution)"
                    icon="description"
                    value={values.notes}
                    error={errors.notes}
                    onChange={(e) => setFieldValue('notes', e.target.value)}
                  />

                  <div className={styles.CreateTimesheet_withCosts}>
                    <Text type={textTypes.HEADING.XXXS}>Costs</Text>
                    <div className={styles.CreateTimesheet_withCostItem}>
                      <ControlledInput
                        className={styles.CreateTimesheet_withMargin}
                        name="itemName"
                        placeholder="Item name"
                        value={values.itemName}
                        error={errors.itemName}
                        onChange={(e) => {
                          setFieldValue('itemName', e.target.value);
                          setCostItemName(e.target.value);
                        }}
                      />
                      <ControlledInput
                        className={styles.CreateTimesheet_withMargin}
                        name="price"
                        placeholder="Price ($)"
                        value={values.price}
                        error={errors.price}
                        onChange={(e) => {
                          setFieldValue('price', e.target.value);
                          setCostItemPrice(e.target.value);
                        }}
                      />
                      <IconButton
                        icon="add"
                        className={cn(
                          styles.CreateTimesheet_withCostItem_action,
                          styles.CreateTimesheet_addNew
                        )}

                        onClick={() => {
                          onClickAddCost();
                          setFieldValue('itemName', '');
                          setFieldValue('price', '');
                          setCostItemName('');
                          setCostItemPrice('');
                        }
                        }
                      />

                    </div>
                    {addCost.length > 0 &&
                      addCost.map((value, index) => (
                        <div key={index} className={styles.CreateTimesheet_withCostDetail}>
                          <Text type={textTypes.HEADING.XXXS}>{value.itemName}</Text>
                          <Text type={textTypes.HEADING.XXXS}>${value.price}</Text>
                          <IconButton
                            icon="delete"
                            className={cn(
                              styles.CreateTimesheet_withCostDetail_action,
                              styles.CreateTimesheet_delete
                            )}
                            onClick={() => removeCost(index)}
                          />
                        </div>
                      ))
                    }
                  </div>
                  {!getEmployeeDetail?.hideWage && (
                    <>
                      <div className={styles.CreateTimesheet_withTooltip}>
                        <Text type={textTypes.HEADING.XXXS}>Hourly Wage</Text>

                        <a
                          className={styles.CreateTimesheet_withTooltip_tooltipLink}
                          data-tip
                          data-for="hourlyWageTooltip"
                        >
                          <Icon
                            icon="help"
                            className={
                              styles.CreateTimesheet_withTooltip_tooltipIcon
                            }
                          />
                        </a>

                        <ReactTooltip
                          className={styles.CreateTimesheet_withTooltip_tooltip}
                          id="hourlyWageTooltip"
                          type="info"
                          effect="solid"
                        >
                          <span>
                            This is your hourly wage and is used to compute for your
                            total income for a timesheet entry.
                            <br />
                            <br />
                            Only your employer can set this for you.
                          </span>
                        </ReactTooltip>
                      </div>

                      <ControlledInput
                        className={styles.CreateTimesheet_withMargin}
                        name="hourlyWage"
                        placeholder="Hourly Wage"
                        value={`${user.employerDetails?.wage}`}
                        disabled
                      />
                    </>)
                  }

                  <Text
                    type={textTypes.HEADING.XXXS}
                    className={styles.CreateTimesheet_withMargin}
                  >
                    Summary
                  </Text>

                  <div className={styles.CreateTimesheet_summary}>
                    <div className={styles.CreateTimesheet_summary_row}>
                      <div>
                        <Text
                          type={textTypes.BODY.SM}
                          className={styles.CreateTimesheet_summary_label}
                        >
                          Hours
                        </Text>

                        <Text
                          type={textTypes.HEADING.XXS}
                          className={styles.CreateTimesheet_summary_value}
                        >
                          {/* {Number(hours).toFixed(2)} */}
                          {/* {user?.employerDetails?.hoursAndMinutesFormat
                              ? hoursWithDecimalToHoursAndMinutes(hours)
                              : Number(hours).toFixed(2)} */}
                              {getEmployeeDetail?.hoursAndMinutesFormat
                              ? hoursWithDecimalToHoursAndMinutes(hours)
                              : Number(hours).toFixed(2)}
                        </Text>
                      </div>
                    </div>

                    <div className={styles.CreateTimesheet_summary_row}>
                      <div className={styles.CreateTimesheet_summary_row_group}>
                        {!getEmployeeDetail?.hideWage && (
                          <>
                            <div>
                              <Text
                                type={textTypes.BODY.SM}
                                className={styles.CreateTimesheet_summary_label}
                              >
                                Total Income
                              </Text>

                              <Text
                                type={textTypes.HEADING.XXS}
                                colorClass={colorClasses.GREEN['400']}
                                className={styles.CreateTimesheet_summary_value}
                              >
                                $
                                {!Number.isNaN(hours * user.employerDetails?.wage)
                                  ? hours * user.employerDetails?.wage.toFixed(2)
                                  : '0.00'}
                              </Text>
                            </div>
                          </>)
                        }
                      </div>
                    </div>
                  </div>

                  <div className={styles.CreateTimesheet_buttonGroup}>
                    <Button
                      className={styles.CreateTimesheet_buttonGroup_button}
                      kind={buttonKinds.SUBMIT}
                      icon="add"
                      disabled={isTimesheetCreating}
                      onClick={() => { }}
                    >
                      <span
                        className={
                          styles.CreateTimesheet_buttonGroup_buttonText
                        }
                      >
                        Create Timesheet
                        {isTimesheetCreating && (
                          <Spinner
                            size={spinnerSizes.XS}
                            colorName={colorNames.WHITE}
                            className={
                              styles.CreateTimesheet_buttonGroup_spinner
                            }
                          />
                        )}
                      </span>
                    </Button>
                  </div>
                </form>

                {user?.employerDetails?.showVehiclesTab && (
                  <CreateReviewModal
                    isOpen={showReviewVehicleModal}
                    handleClose={() => setShowReviewVehicleModal(false)}
                    vehicleId={values?.vehicle?.value}
                    createNewTimesheetFunc={createNewTimesheetFunc}
                  />
                )}
              </>
            )}
          </Formik>
        </Card>
      )}
    </div>
  );
};

CreateTimesheet.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
};

export default CreateTimesheet;
