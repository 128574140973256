import React, {useState, useContext, useEffect} from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import {
  Button,
  ControlledSelect,
  Text,
  Spinner,
} from '../../../../components/elements';
import {
  buttonKinds,
  colorNames,
  selectTypes,
  tabletViewRecordingTypes,
  textTypes,
  spinnerSizes,
} from '../../../../globals';
import { UserContext } from '../../../../contexts';
import { UsersService } from '../../../../services';
import useGetEmployee from "../../../../hooks/useGetEmployee";

const validate = (values) => {
  const errors = {};

  if (!values.recordingType) {
    errors.recordingType = 'This field is required.';
  }

  return errors;
};

const TabletView = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const {isLoading: isUserLoading, employee: getEmployeeDetail} = useGetEmployee({
    emailAddress: user.emailAddress,
  });

  const [isUpdating, setIsUpdating] = useState(false);
    const [passwordRequiredValue,setPasswordRequiredValue] = useState({});

  useEffect(() => {
    if(getEmployeeDetail){

      if(getEmployeeDetail.isPasswordRequired !== null){
        setPasswordRequiredValue({
            label: getEmployeeDetail.isPasswordRequired ? "Yes" : "No",
            value: getEmployeeDetail.isPasswordRequired,
        });
      }
    }
  },[getEmployeeDetail]);

  return (
    <div className={styles.TabletView}>
      <Text type={textTypes.HEADING.SM} className={styles.TabletView_title}>
        Tablet View
      </Text>
        {
            !isUserLoading && (
                <Formik
                    initialValues={{
                        recordingType: {
                            label: user.tabletViewRecordingType
                                ? user.tabletViewRecordingType
                                : tabletViewRecordingTypes.MANUALLY_RECORD,
                            value: user.tabletViewRecordingType
                                ? user.tabletViewRecordingType
                                : tabletViewRecordingTypes.MANUALLY_RECORD,
                        },
                        isPasswordRequired: passwordRequiredValue
                    }}
                    onSubmit={async (values, { setErrors }) => {
                        const errors = validate(values);
                        if (!isEmpty(errors)) {
                            setErrors(errors);
                            return;
                        }

                        const currentFormValues = {
                            tabletViewRecordingType: values.recordingType.value,
                        };

                        setIsUpdating(true);

                        // Update the user
                        const { data: updateResponse } =
                            await UsersService.updateTabletViewSettings({
                                employerId: user.id,
                                isPasswordRequired: values.isPasswordRequired,
                                ...currentFormValues,
                            });

                        if (updateResponse.message === 'tablet_view_settings_updated') {
                            // Show an alert
                            alert.success('Tablet View settings updated!');
                            // Update user state
                            loginUpdate({ ...user, ...currentFormValues });
                        } else {
                            alert.error('Oops, something went wrong.');
                        }

                        setIsUpdating(false);
                    }}
                >
                    {({ errors, values, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <ControlledSelect
                                type={selectTypes.SLIM}
                                label="Recording Type*"
                                className={styles.TabletView_input}
                                options={[
                                    {
                                        label: tabletViewRecordingTypes.MANUALLY_RECORD,
                                        value: tabletViewRecordingTypes.MANUALLY_RECORD,
                                    },
                                    {
                                        label: tabletViewRecordingTypes.AUTOMATICALLY_RECORD,
                                        value: tabletViewRecordingTypes.AUTOMATICALLY_RECORD,
                                    },
                                ]}
                                name="recordingType"
                                value={values.recordingType}
                                error={errors.recordingType}
                                onChange={(val) => {
                                    setFieldValue('recordingType', {
                                        label: val.label,
                                        value: val.value,
                                    });
                                }}
                            />
                            
                            <ControlledSelect
                                type={selectTypes.SLIM}
                                label="Password Is Required?"
                                className={styles.TabletView_input}
                                options={[
                                    {
                                        label: "Yes",
                                        value: true,
                                    },
                                    {
                                        label: "No",
                                        value: false,
                                    },
                                ]}
                                name="passwordIsRequired"
                                value={values.isPasswordRequired}
                                error={errors?.isPasswordRequired}
                                onChange={(val) => {
                                    setFieldValue('isPasswordRequired', {
                                        label: val.label,
                                        value: val.value,
                                    });
                                }}
                            />

                            <div className={styles.TabletView_buttonGroup}>
                                <Button
                                    kind={buttonKinds.SUBMIT}
                                    disabled={
                                        isUpdating || values.recordingType === user.recordingType
                                    }
                                    onClick={() => {}}
                                >
                <span className={styles.TabletView_buttonGroup_buttonText}>
                  Update
                    {isUpdating && (
                        <Spinner
                            size={spinnerSizes.XS}
                            colorName={colorNames.WHITE}
                            className={styles.TabletView_buttonGroup_spinner}
                        />
                    )}
                </span>
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            )
        }
    </div>
  );
};

export default TabletView;
