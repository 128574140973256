import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { IconButton, Text } from '../../elements';
import { textTypes, userTypes } from '../../../globals';
import { RosterContext, UserContext } from '../../../contexts';
import { convertTimeTo12HourFormat } from '../../../utils/datetime';

const RosterCard = ({ roster, schedule }) => {
  const { user } = useContext(UserContext);
  const {
    getEmployeeFullName,
    setSelectedRoleId,
    setSelectedRosterId,
    setViewRosterValues,
  } = useContext(RosterContext);

  return (
    <div
      className={cn(styles.RosterCard, {
        [styles[`RosterCard___${roster.roleColor}`]]: roster.roleId,
      })}
    >
      <Text
        type={textTypes.BODY.SM}
        className={cn(
          styles.RosterCard_schedule,
          styles.RosterCard_textEllipsis
        )}
      >
        {convertTimeTo12HourFormat(schedule.startTime)} -{' '}
        {convertTimeTo12HourFormat(schedule.endTime)}
      </Text>

      {roster.roleId && (
        <Text type={textTypes.HEADING.XXS}>{roster.roleName}</Text>
      )}

      {roster.roleId ? (
        <>
          {user.userType === userTypes.EMPLOYER ? (
            <>
              {roster.employees.map((employeeId) => (
                <Text
                  type={textTypes.BODY.MD}
                  className={styles.RosterCard_textEllipsis}
                  key={employeeId}
                >
                  {getEmployeeFullName(employeeId)}
                </Text>
              ))}
            </>
          ) : (
            <Text
              type={textTypes.BODY.MD}
              className={styles.RosterCard_textEllipsis}
            >
              {user.fullName}
            </Text>
          )}
        </>
      ) : (
        <Text
          type={textTypes.HEADING.XXXS}
          className={styles.RosterCard_textEllipsis}
        >
          {user.userType === userTypes.EMPLOYER
            ? getEmployeeFullName(roster.employeeId)
            : user.fullName}
        </Text>
      )}

      {user.userType === userTypes.EMPLOYER && (
        <div className={styles.RosterCard_icons}>
          <IconButton
            icon="visibility"
            className={styles.RosterCard_icons_icon}
            onClick={() => setViewRosterValues(roster)}
          />

          <IconButton
            icon="delete"
            className={styles.RosterCard_icons_icon}
            onClick={() => {
              setSelectedRoleId(roster.roleId);
              setSelectedRosterId(roster.id);
            }}
          />
        </div>
      )}
    </div>
  );
};

RosterCard.propTypes = {
  roster: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
};

export default RosterCard;
