import React, { useState, useContext } from 'react';
import cn from 'classnames';
import styles from '../styles.module.scss';

import { Badge, Icon, Text } from '../../elements';
import { UserContext, TimesheetContext } from '../../../contexts';
import { colorNames } from '../../../globals';
import {
  getPrevOrAfterDate,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';

import { getHours, checkStatus } from '../../../utils/timesheets';

import TimesheetCarousel from '../TimesheetCarousel';
import useGetEmployee from '../../../hooks/useGetEmployee';

const DailyView = () => {
  const { user } = useContext(UserContext);
  const { startDate, employees, timesheets } = useContext(TimesheetContext);
  const [activeEmployee, setActiveEmployee] = useState(null);

  const {isLoading: isUserLoading, employee: getEmployeeDetail} = useGetEmployee({
    emailAddress: user.userType == "Employee" ? user.employerDetails.employerEmailAddress : user.emailAddress,
  });

  return (
    <>
      {employees.map(({ id, fullName }) => {
        let overallTotalHours = 0;
        return (
          <div className={styles.TimesheetGrid_keyWrapper} key={id}>
            {activeEmployee !== id ? (
              <>
                <button
                  type="button"
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_clickable
                  )}
                  onClick={() => setActiveEmployee(id)}
                >
                  <Icon
                    icon="expand_more"
                    className={styles.Navbar_navUser_dropdown_link_icon}
                  />
                </button>

                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_centerText
                  )}
                >
                  {checkStatus(timesheets, id) === 'Active' ? (
                    <Badge text="Active" />
                  ) : (
                    <Badge text="Inactive" colorName={colorNames.GRAY} />
                  )}
                </div>

                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_centerText
                  )}
                >
                  {fullName}
                </div>

                {[...Array(1)].forEach((_, i) => {
                  const timesheetDate = getPrevOrAfterDate(startDate, i, 'add');
                  const timesheetsForDay = timesheets.filter(
                    (timesheet) =>
                      timesheet.userId === id &&
                      timesheet.date === timesheetDate
                  );

                  let totalHours = 0;
                  timesheetsForDay.forEach(
                    ({
                      date,
                      startTimeDetails = {
                        startTime: undefined,
                        actualTime: undefined,
                      },
                      endTimeDetails = {
                        endTime: undefined,
                        actualTime: undefined,
                      },
                      isLunchBreak,
                      lunchBreakDuration,
                      isTravelCharge,
                      leaveType,
                      publicHolidayMultiplier,
                    }) => {
                      const startTimeTimestamp = convertDateTimeToTimestamp(
                        date,
                        convertTimeTo24HourFormat(startTimeDetails.startTime)
                      );
                      const endTimeTimestamp = convertDateTimeToTimestamp(
                        date,
                        convertTimeTo24HourFormat(endTimeDetails.endTime)
                      );

                      totalHours += Number(
                        getHours(
                          startTimeTimestamp,
                          endTimeTimestamp,
                          isLunchBreak,
                          lunchBreakDuration,
                          isTravelCharge,
                          leaveType,
                          publicHolidayMultiplier
                        )
                      );
                    }
                  );

                  overallTotalHours += totalHours;
                })}

                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_boldText
                  )}
                >
                  {overallTotalHours > 0
                    ? (user?.hoursAndMinutesFormat || getEmployeeDetail?.hoursAndMinutesFormat)
                      ? hoursWithDecimalToHoursAndMinutes(
                          overallTotalHours.toFixed(2)
                        )
                      : overallTotalHours.toFixed(2)
                    : 0}
                </div>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_clickable,
                    styles.TimesheetGrid_clickable___active
                  )}
                  onClick={() => setActiveEmployee(null)}
                >
                  <Icon
                    icon="expand_less"
                    className={styles.Navbar_navUser_dropdown_link_icon}
                  />
                </button>

                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_column___active,
                    styles.TimesheetGrid_centerText
                  )}
                >
                  {checkStatus(timesheets, id) === 'Active' ? (
                    <Badge text="Active" />
                  ) : (
                    <Badge text="Inactive" colorName={colorNames.GRAY} />
                  )}
                </div>

                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_column___active,
                    styles.TimesheetGrid_centerText
                  )}
                >
                  {fullName}
                </div>

                {[...Array(1)].map((_, i) => {
                  const timesheetDate = getPrevOrAfterDate(startDate, i, 'add');
                  const timesheetsForDay = timesheets.filter(
                    (timesheet) =>
                      timesheet.userId === id &&
                      timesheet.date === timesheetDate
                  );
                  const totalTimesheetsForDay = timesheetsForDay?.length || 0;

                  let totalHours = 0;
                  timesheetsForDay.forEach(
                    ({
                      date,
                      startTimeDetails = {
                        startTime: undefined,
                        actualTime: undefined,
                      },
                      endTimeDetails = {
                        endTime: undefined,
                        actualTime: undefined,
                      },
                      isLunchBreak,
                      lunchBreakDuration,
                      isTravelCharge,
                      leaveType,
                      publicHolidayMultiplier,
                    }) => {
                      const startTimeTimestamp = convertDateTimeToTimestamp(
                        date,
                        convertTimeTo24HourFormat(startTimeDetails.startTime)
                      );
                      const endTimeTimestamp = convertDateTimeToTimestamp(
                        date,
                        convertTimeTo24HourFormat(endTimeDetails.endTime)
                      );

                      totalHours += Number(
                        getHours(
                          startTimeTimestamp,
                          endTimeTimestamp,
                          isLunchBreak,
                          lunchBreakDuration,
                          isTravelCharge,
                          leaveType,
                          publicHolidayMultiplier
                        )
                      );
                    }
                  );

                  overallTotalHours += totalHours;

                  return (
                    <div
                      className={cn(
                        styles.TimesheetGrid_column,
                        styles.TimesheetGrid_column___active
                      )}
                      key={i}
                    >
                      {!totalTimesheetsForDay ? (
                        <Text className={styles.TimesheetGrid_centerText}>
                          --
                        </Text>
                      ) : (
                        <TimesheetCarousel
                          totalHours={totalHours}
                          timesheetsForDay={timesheetsForDay}
                          totalTimesheets={totalTimesheetsForDay}
                          viewType="daily"
                        />
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default DailyView;
