import { useState, useEffect } from 'react';

import { ProjectsService } from '../services';

const useProject = ({ clientId, projectId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);

  useEffect(() => {
    const getProject = async () => {
      const { data: getProjectResponse } = await ProjectsService.singleGet({
        clientId,
        projectId,
      });

      if (getProjectResponse.project) {
        getProjectResponse.project.status = {
          label: getProjectResponse.project.status,
          value: getProjectResponse.project.status,
        };

        if (getProjectResponse.project.startDate) {
          getProjectResponse.project.startDate = new Date(
            getProjectResponse.project.startDate
          );
        }

        if (getProjectResponse.project.projectedFinishDate) {
          getProjectResponse.project.projectedFinishDate = new Date(
            getProjectResponse.project.projectedFinishDate
          );
        }

        setProject(getProjectResponse.project);
      }

      setIsLoading(false);
    };

    if (clientId && projectId) {
      getProject();
    } else {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, project };
};

export default useProject;
