import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../contexts';
import { userTypes } from '../globals';
import Cookies from 'universal-cookie';

/* eslint-disable react/jsx-props-no-spreading */
const PrivateRoute = ({ forUserType, ...rest }) => {
  const { user } = useContext(UserContext);
  let userType = null;

  if (user?.userType) {
    userType = user.userType;
  }

  const cookies = new Cookies();
  const refreshToken = cookies.get('refreshToken');
  if(!refreshToken){
    localStorage.clear();
    window.location.href = "/";
  }

  // the page can be accessed by the user
  if (
    (userType != null && forUserType === userTypes.ANY) ||
    (userType === userTypes.EMPLOYER && forUserType === userTypes.EMPLOYER) ||
    (userType === userTypes.EMPLOYEE && forUserType === userTypes.EMPLOYEE) ||
    (userType === userTypes.CONTRACTOR &&
      forUserType === userTypes.CONTRACTOR) ||
    (userType === userTypes.ADMIN && forUserType === userTypes.ADMIN)
  ) {
    return <Route {...rest} />;
  }

  /**
   *   Table for pages that can be accessed by the different user types
   *    ---------------------------------------------------
   *   |     Type      |  Employer | Employee | Contractor |
   *    ---------------------------------------------------
   *   |   Employer    |     /     |     x    |     x      |
   *    ---------------------------------------------------
   *   |   Employee    |     x     |     /    |     x      |
   *    ---------------------------------------------------
   *   |   Contractor  |     x     |     x    |     /      |
   *    ---------------------------------------------------
   */
  if (userType === userTypes.EMPLOYER && forUserType !== userTypes.EMPLOYER) {
    return (
      <Route
        name="Employer"
        render={() => <Redirect to="/employer/dashboard" />}
      />
    );
  }

  if (userType === userTypes.EMPLOYEE && forUserType !== userTypes.EMPLOYEE) {
    return (
      <Route
        name="Employee"
        render={() => <Redirect to="/employee/dashboard" />}
      />
    );
  }

  if (
    userType === userTypes.CONTRACTOR &&
    forUserType !== userTypes.CONTRACTOR
  ) {
    return (
      <Route
        name="Contractor"
        render={() => <Redirect to="/contractor/dashboard" />}
      />
    );
  }

  if (userType === userTypes.ADMIN && forUserType !== userTypes.ADMIN) {
    return (
      <Route name="Admin" render={() => <Redirect to="/admin/dashboard" />} />
    );
  }

  return <Route name="Login" render={() => <Redirect to="/login" />} />;
};

PrivateRoute.propTypes = {
  forUserType: PropTypes.string.isRequired,
};

export default PrivateRoute;
