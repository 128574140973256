import React, { useState, useContext } from 'react';
import cn from 'classnames';
import styles from '../styles.module.scss';

import { Icon, Text } from '../../elements';
import { UserContext, TimesheetContext } from '../../../contexts';
import {
  getPrevOrAfterDate,
  getDayNameFromDayId,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import { getHours } from '../../../utils/timesheets';

import TimesheetCarousel from '../TimesheetCarousel';

const WeeklyView = () => {
  const { user } = useContext(UserContext);
  const { startDate, dayIds, timesheets, windowSize } =
    useContext(TimesheetContext);
  const [isActive, setIsActive] = useState(false);

  let overallTotalHours = 0;

  return (
    <div className={styles.TimesheetGrid_keyWrapper}>
      {!isActive ? (
        <>
          <button
            type="button"
            className={cn(
              styles.TimesheetGrid_column,
              styles.TimesheetGrid_clickable
            )}
            onClick={() => setIsActive(true)}
            style={
              windowSize.width <= 767
                ? {
                    gridArea: '1 / 1 / 10 / 1',
                  }
                : undefined
            }
          >
            <Icon
              icon="expand_more"
              className={styles.Navbar_navUser_dropdown_link_icon}
            />
          </button>

          {windowSize.width <= 767 && (
            <>
              {dayIds.map((dayId) => (
                <div
                  className={cn(styles.TimesheetGrid_column)}
                  key={getDayNameFromDayId(dayId)}
                  style={{
                    alignItems: 'flex-start',
                  }}
                >
                  {getDayNameFromDayId(dayId)}
                </div>
              ))}
            </>
          )}

          {[...Array(7)].map((_, i) => {
            const timesheetDate = getPrevOrAfterDate(startDate, i, 'add');
            const timesheetsForDay = timesheets.filter(
              (timesheet) => timesheet.date === timesheetDate
            );

            let totalHours = 0;
            timesheetsForDay.forEach(
              ({
                date,
                startTimeDetails = {
                  endTime: undefined,
                  actualTime: undefined,
                },
                endTimeDetails = {
                  endTime: undefined,
                  actualTime: undefined,
                },
                isLunchBreak,
                lunchBreakDuration,
                leaveType,
                publicHolidayMultiplier,
              }) => {
                const startTimeTimestamp = convertDateTimeToTimestamp(
                  date,
                  convertTimeTo24HourFormat(startTimeDetails.startTime)
                );
                const endTimeTimestamp = convertDateTimeToTimestamp(
                  date,
                  convertTimeTo24HourFormat(endTimeDetails.endTime)
                );

                const currentTimesheetHours = Number(
                  getHours(
                    startTimeTimestamp,
                    endTimeTimestamp,
                    isLunchBreak,
                    lunchBreakDuration,
                    // leaveType,
                    // publicHolidayMultiplier
                  )
                );

                totalHours += currentTimesheetHours;
              }
            );

            overallTotalHours += totalHours;

            return (
              <div
                className={styles.TimesheetGrid_column}
                key={i}
                style={
                  windowSize.width <= 767
                    ? {
                        gridArea: `${i + 2} / 3 / ${i + 2} / 3`,
                      }
                    : undefined
                }
              >
                {totalHours > 0
                  ? user?.hoursAndMinutesFormat
                    ? hoursWithDecimalToHoursAndMinutes(totalHours.toFixed(2))
                    : totalHours.toFixed(2)
                  : 0}
              </div>
            );
          })}

          <div
            className={cn(styles.TimesheetGrid_column)}
            style={
              windowSize.width <= 767
                ? {
                    gridArea: `1 / 2 / 1 / 4`,
                  }
                : undefined
            }
          >
            <Text className={styles.TimesheetGrid_boldText}>
              {overallTotalHours > 0
                ? user?.hoursAndMinutesFormat
                  ? hoursWithDecimalToHoursAndMinutes(
                      overallTotalHours.toFixed(2)
                    )
                  : overallTotalHours.toFixed(2)
                : 0}
            </Text>
          </div>
        </>
      ) : (
        <>
          <button
            type="button"
            className={cn(
              styles.TimesheetGrid_column,
              styles.TimesheetGrid_clickable,
              styles.TimesheetGrid_clickable___active
            )}
            onClick={() => setIsActive(false)}
            style={
              windowSize.width <= 767
                ? {
                    gridArea: '1 / 1 / 10 / 1',
                  }
                : undefined
            }
          >
            <Icon
              icon="expand_less"
              className={styles.Navbar_navUser_dropdown_link_icon}
            />
          </button>

          {windowSize.width <= 767 && (
            <>
              {dayIds.map((dayId) => (
                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_column___active
                  )}
                  key={getDayNameFromDayId(dayId)}
                  style={{
                    alignItems: 'flex-start',
                  }}
                >
                  {getDayNameFromDayId(dayId)}
                </div>
              ))}
            </>
          )}

          {[...Array(7)].map((_, i) => {
            const timesheetDate = getPrevOrAfterDate(startDate, i, 'add');

            let totalHours = 0;

            const timesheetsForDay = timesheets.filter(
              (timesheet) =>
                timesheet.userId === user.id && timesheet.date === timesheetDate
            );
            const totalTimesheetsForDay = timesheetsForDay.length;

            timesheetsForDay.forEach(
              ({
                date,
                startTimeDetails = {
                  endTime: undefined,
                  actualTime: undefined,
                },
                endTimeDetails = {
                  endTime: undefined,
                  actualTime: undefined,
                },
                isLunchBreak,
                lunchBreakDuration,
                // leaveType,
                // publicHolidayMultiplier,
              }) => {
                const startTimeTimestamp = convertDateTimeToTimestamp(
                  date,
                  convertTimeTo24HourFormat(startTimeDetails.startTime)
                );
                const endTimeTimestamp = convertDateTimeToTimestamp(
                  date,
                  convertTimeTo24HourFormat(endTimeDetails.endTime)
                );

                const currentTimesheetHours = Number(
                  getHours(
                    startTimeTimestamp,
                    endTimeTimestamp,
                    isLunchBreak,
                    lunchBreakDuration,
                    // leaveType,
                    // publicHolidayMultiplier
                  )
                );

                totalHours += currentTimesheetHours;
              }
            );

            overallTotalHours += totalHours;

            return (
              <div
                className={cn(
                  styles.TimesheetGrid_column,
                  styles.TimesheetGrid_column___active
                )}
                key={i}
                style={
                  windowSize.width <= 767
                    ? {
                        gridArea: `${i + 2} / 3 / ${i + 2} / 3`,
                      }
                    : undefined
                }
              >
                {!totalTimesheetsForDay ? (
                  <Text className={styles.TimesheetGrid_centerText}>--</Text>
                ) : (
                  <TimesheetCarousel
                    totalHours={totalHours}
                    timesheetsForDay={timesheetsForDay}
                    totalTimesheets={totalTimesheetsForDay}
                  />
                )}
              </div>
            );
          })}

          <div
            className={cn(
              styles.TimesheetGrid_column,
              styles.TimesheetGrid_column___active
            )}
            style={
              windowSize.width <= 767
                ? {
                    gridArea: `1 / 2 / 1 / 4`,
                  }
                : undefined
            }
          >
            <Text className={styles.TimesheetGrid_boldText}>
              {overallTotalHours > 0
                ? user?.hoursAndMinutesFormat
                  ? hoursWithDecimalToHoursAndMinutes(
                      overallTotalHours.toFixed(2)
                    )
                  : overallTotalHours.toFixed(2)
                : 0}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};

export default WeeklyView;
