import { useState } from 'react';

import { VehiclesService } from '../services';

const useGenerateVehicleReport = () => {
  const [isGenerating, setIsGenerating] = useState(false);

  const generateReport = async ({
    emailAddress,
    employerFullName,
    employerEmailAddress,
    vehicleId,
    from,
    to,
    fileName,
    generateType,
  }) => {
    setIsGenerating(true);

    let responseCode;
    let fileBlob;

    try {
      const response = await VehiclesService.generateSingleReport({
        emailAddress: generateType === 'Send' ? emailAddress : undefined,
        employerFullName:
          generateType === 'Send' ? employerFullName : undefined,
        employerEmailAddress:
          generateType === 'Send' ? employerEmailAddress : undefined,
        vehicleId,
        from,
        to,
        fileName,
        generateType,
      });
      responseCode = response.status;
      fileBlob = response.data;
    } catch (error) {
      responseCode = error.response.status;
    }

    setIsGenerating(false);

    return { responseCode, fileBlob };
  };

  return { isGenerating, generateReport };
};

export default useGenerateVehicleReport;
