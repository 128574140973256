import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from '../styles.module.scss';

import {
  Checkbox,
  ControlledInput,
  ControlledSelect,
  ControlledTextArea,
  InputMask,
  Modal,
  Text,
} from '../../../elements';
import {
  buttonTypes,
  colorClasses,
  leaveTypes,
  modalPositions,
  modalSizes,
  textTypes,
} from '../../../../globals';
import {
  checkIfTimeIsValid,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  convertTimestampToTimeWithSuffix,
  hoursWithDecimalToHoursAndMinutes,
  timeMask,
} from '../../../../utils/datetime';
import { getHours, isTimesheetTimesValid } from '../../../../utils/timesheets';
import { UserContext } from '../../../../contexts';
import { useCreateTimesheet, useUpdateTimesheet } from '../../../../hooks';
import { TimesheetsService } from '../../../../services';

const InputTimesheetModal = ({
  isOpen,
  handleClose,
  employeeId,
  activeTimesheet,
  location,
  handleSuccess,
}) => {
  const { user } = useContext(UserContext);
  const formRef = useRef();
  const [hours, setHours] = useState('0.00');

  const { isCreating, createTimesheet } = useCreateTimesheet();
  const { isUpdating, updateTimesheet } = useUpdateTimesheet();

  const validate = (values) => {
    const errors = {};

    if (!values.startTime) {
      errors.startTime = 'This field is required.';
    } else if (!checkIfTimeIsValid(values.startTime)) {
      errors.startTime = 'Invalid time format.';
    }

    if (values.showEndTimeDetails) {
      if (!values.endTime) {
        errors.endTime = 'This field is required.';
      } else if (!checkIfTimeIsValid(values.endTime)) {
        errors.endTime = 'Invalid time format.';
      }
    }

    return errors;
  };

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Input Timesheet"
      className={styles.TabletViewTimesheetModals}
      actions={[
        {
          text: 'Proceed',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isCreating || isUpdating,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          date: activeTimesheet
            ? activeTimesheet?.date
            : dateFormat(new Date(), 'yyyy-mm-dd'),
          startTime: activeTimesheet
            ? activeTimesheet?.startTimeDetails?.startTime
            : undefined,
          showEndTimeDetails: !!activeTimesheet?.startTimeDetails?.startTime,
          endTime: undefined,
          isLunchBreak: user.isLunchBreakByDefault
            ? { label: 'Yes', value: true }
            : {
                label: 'No',
                value: false,
              },
          notes: '',
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          // We need these timesheets later to check if any of the times of this timesheet
          // is overlapping any previous timesheets of the employee today
          const { data: getTimesheetsResponse } = await TimesheetsService.get({
            userId: employeeId,
            startDate: values.date,
            endDate: values.date,
          });
          const { timesheets } = getTimesheetsResponse;

          // Then we check if we need to create a new timesheet
          // or update the existing one
          if (!activeTimesheet) {
            // WE WILL CREATE A NEW TIMESHEET HERE
            const newTimesheet = {
              employerId: user.id,
              userId: employeeId,
              date: values.date,
              leaveType: leaveTypes.NONE,
              startTimeDetails: {
                startTime: values.startTime,
                actualTime: convertTimestampToTimeWithSuffix(
                  Math.floor(Date.now() / 1000)
                ),
                location: location || undefined,
              },
            };

            // We check if the employee opted to add an
            // endTime details right away
            if (values.showEndTimeDetails) {
              // We add the endTime details properties to our newTimesheet object
              newTimesheet.endTimeDetails = {
                endTime: values.endTime,
                actualTime: convertTimestampToTimeWithSuffix(
                  Math.floor(Date.now() / 1000)
                ),
                location: location || undefined,
              };
              newTimesheet.isLunchBreak = values.isLunchBreak.value;
              newTimesheet.notes = values.notes;

              // Then we check if the endTime is valid
              if (
                !isTimesheetTimesValid(timesheets, {
                  startTime: values.startTime,
                  endTime: values.endTime,
                })
              ) {
                setErrors({
                  overall:
                    'Oops, the end time you have inputted overlaps an existing timesheet.',
                });
                return;
              }
            }

            // We check if the startTime is valid
            if (
              !isTimesheetTimesValid(timesheets, {
                startTime: values.startTime,
              })
            ) {
              setErrors({
                overall:
                  'Oops, the start time you have inputted overlaps an existing timesheet.',
              });
              return;
            }

            const { responseCode: createTimesheetResponseCode } =
              await createTimesheet(newTimesheet);

            const createTimesheetCallbacks = {
              created: () =>
                handleSuccess({
                  successType: !values.showEndTimeDetails ? 'start' : 'end',
                }),
              invalidFields: () =>
                setErrors({
                  overall: 'Invalid fields',
                }),
              internalError: () =>
                setErrors({
                  overall: 'Oops, something went wrong.',
                }),
            };

            switch (createTimesheetResponseCode) {
              case 200:
                createTimesheetCallbacks.created();
                break;
              case 400:
                createTimesheetCallbacks.invalidFields();
                break;
              case 500:
                createTimesheetCallbacks.internalError();
                break;
              default:
                break;
            }
          } else {
            // WE WILL UPDATE THE EXISTING TIMESHEET HERE

            // Check if startTime has been changed
            if (
              activeTimesheet.startTimeDetails.startTime !== values.startTime
            ) {
              // Since it has been changed, we then update the startTime
              // of the existing timesheet
              activeTimesheet.startTimeDetails.startTime = values.startTime;
              activeTimesheet.startTimeDetails.actualTime =
                convertTimestampToTimeWithSuffix(Math.floor(Date.now() / 1000));
              activeTimesheet.startTimeDetails.location = location || undefined;
            }

            // Add the endTime details in activeTimesheet
            activeTimesheet.endTimeDetails = {
              endTime: values.endTime,
              actualTime: convertTimestampToTimeWithSuffix(
                Math.floor(Date.now() / 1000)
              ),
              location: location || undefined,
            };
            activeTimesheet.isLunchBreak = values.isLunchBreak.value;
            activeTimesheet.notes = values.notes;

            // We check if the startTime is valid
            if (
              !isTimesheetTimesValid(timesheets, {
                startTime: values.startTime,
              })
            ) {
              setErrors({
                overall:
                  'Oops, the start time you have inputted overlaps an existing timesheet.',
              });
              return;
            }

            // We check if the endTime is valid
            if (
              !isTimesheetTimesValid(timesheets, {
                startTime: values.startTime,
                endTime: values.endTime,
              })
            ) {
              setErrors({
                overall:
                  'Oops, the end time you have inputted overlaps an existing timesheet.',
              });
              return;
            }

            // Update the timesheet
            const { message } = await updateTimesheet(activeTimesheet);

            if (message === 'timesheet_updated') {
              handleSuccess({
                successType: 'end',
              });
            } else {
              setErrors({
                overall: 'Oops, something went wrong.',
              });
            }
          }
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <form onSubmit={(e) => e.preventDefault()}>
            <InputMask
              mask={timeMask(values.startTime)}
              maskPlaceholder="hh:mm xm"
              placeholder="Start Time*"
              name="startTime"
              icon="schedule"
              value={values.startTime}
              error={errors.startTime}
              onChange={(e) => setFieldValue('startTime', e.target.value)}
              onBlur={() => {
                if (values.startTime && values.endTime) {
                  setHours(
                    getHours(
                      convertDateTimeToTimestamp(
                        values.date,
                        convertTimeTo24HourFormat(values.startTime)
                      ),
                      convertDateTimeToTimestamp(
                        values.date,
                        convertTimeTo24HourFormat(values.endTime)
                      ),
                      values.isLunchBreak
                    ).toString()
                  );
                }
              }}
            />

            {!activeTimesheet?.startTimeDetails?.startTime && (
              <Checkbox
                className={styles.TabletViewTimesheetModals_withMarginTop}
                name="showEndTimeDetails"
                label="Add End Time?"
                onChange={() =>
                  setFieldValue(
                    'showEndTimeDetails',
                    !values.showEndTimeDetails
                  )
                }
                checked={values.showEndTimeDetails}
              />
            )}

            {values.showEndTimeDetails && (
              <>
                <InputMask
                  className={styles.TabletViewTimesheetModals_withMarginTop}
                  mask={timeMask(values.endTime)}
                  maskPlaceholder="hh:mm xm"
                  placeholder="End Time*"
                  name="endTime"
                  icon="schedule"
                  value={values.endTime}
                  error={errors.endTime}
                  onChange={(e) => setFieldValue('endTime', e.target.value)}
                  onBlur={() => {
                    if (values.startTime && values.endTime) {
                      setHours(
                        getHours(
                          convertDateTimeToTimestamp(
                            values.date,
                            convertTimeTo24HourFormat(values.startTime)
                          ),
                          convertDateTimeToTimestamp(
                            values.date,
                            convertTimeTo24HourFormat(values.endTime)
                          ),
                          values.isLunchBreak
                        ).toString()
                      );
                    }
                  }}
                />

                <ControlledSelect
                  className={styles.TabletViewTimesheetModals_withMarginTop}
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                  name="isLunchBreak"
                  placeholder="Lunch Break*"
                  value={values.isLunchBreak}
                  error={errors.isLunchBreak}
                  onChange={(val) => {
                    setFieldValue('isLunchBreak', {
                      label: val.label,
                      value: val.value,
                    });

                    if (values.startTime && values.endTime) {
                      setHours(
                        getHours(
                          convertDateTimeToTimestamp(
                            values.date,
                            convertTimeTo24HourFormat(values.startTime)
                          ),
                          convertDateTimeToTimestamp(
                            values.date,
                            convertTimeTo24HourFormat(values.endTime)
                          ),
                          val.value
                        ).toString()
                      );
                    }
                  }}
                />

                <ControlledInput
                  className={styles.TabletViewTimesheetModals_withMarginTop}
                  name="hours"
                  placeholder="Hours"
                  icon="schedule"
                  value={
                    user?.hoursAndMinutesFormat
                      ? hoursWithDecimalToHoursAndMinutes(hours)
                      : hours
                  }
                  disabled
                />

                <ControlledTextArea
                  className={styles.TabletViewTimesheetModals_withMarginTop}
                  name="notes"
                  placeholder="Notes"
                  icon="description"
                  value={values.notes}
                  error={errors.notes}
                  onChange={(e) => setFieldValue('notes', e.target.value)}
                />
              </>
            )}

            {errors.overall && (
              <Text
                className={styles.TabletViewTimesheetModals_withMargin}
                type={textTypes.BODY.XS}
                colorClass={colorClasses.RED['400']}
              >
                {errors.overall}
              </Text>
            )}
          </form>
        )}
      </Formik>
    </Modal>
  );
};

InputTimesheetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  activeTimesheet: PropTypes.object,
  location: PropTypes.object,
  // doesn't accept any parameter. This will just
  // proceed to the next modal
  handleSuccess: PropTypes.func.isRequired,
};

export default InputTimesheetModal;
