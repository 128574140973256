import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/tokens`;

const TokensService = {
  acquire: (body) => axios.post(`${BASE_URL}/acquire`, body),
  verify: (refreshToken) => axios.post(`${BASE_URL}/verify`, refreshToken),
  renew: (refreshToken) => axios.post(`${BASE_URL}/renew`, refreshToken),
};

export default TokensService;
