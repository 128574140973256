import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import {
  Button,
  Text,
  TimePicker,
  Spinner,
} from '../../../../components/elements';
import {
  buttonKinds,
  colorNames,
  textTypes,
  spinnerSizes,
  timesheetTypes,
} from '../../../../globals';
import { UserContext } from '../../../../contexts';
import { UsersService } from '../../../../services';
import {
  getDayNameFromDayId,
  createMomentWithDummyDate,
  momentTo24HourTime,
} from '../../../../utils/datetime';
import {
  generateDayIds,
  getDateEquivalence,
} from '../../../../utils/timesheets';

const Notifications = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [isUpdating, setIsUpdating] = useState(false);

  const dayIds = generateDayIds(
    timesheetTypes.WEEKLY,
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'start'),
    user.timesheetStartWeek
  );

  return (
    <div className={styles.Notifications}>
      <Text type={textTypes.HEADING.SM} className={styles.Notifications_title}>
        Notifications
      </Text>

      <Formik
        initialValues={{
          notifications: user.notifications,
        }}
        onSubmit={async (values) => {
          setIsUpdating(true);

          // Update the user
          const { data: updateResponse } =
            await UsersService.updateNotifications({
              employerId: user.id,
              notifications: values.notifications,
            });

          if (updateResponse.message === 'notifications_updated') {
            // Show an alert
            alert.success('Notifications updated!');

            // Update user state
            loginUpdate({ ...user, notifications: values.notifications });
          } else {
            alert.error('Oops, something went wrong.');
          }

          setIsUpdating(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            {dayIds.map((dayId) => {
              const scheduleForDay = values.notifications.find(
                (notification) => notification.day === dayId
              );

              return (
                <div className={styles.Notifications_inputGroup}>
                  <Text
                    type={textTypes.BODY.MD}
                    className={styles.Notifications_inputGroup_label}
                  >
                    {getDayNameFromDayId(dayId)}:
                  </Text>
                  <TimePicker
                    time={
                      scheduleForDay?.time
                        ? createMomentWithDummyDate(scheduleForDay?.time)
                        : null
                    }
                    onChange={(newTime) => {
                      
                      if (!newTime) {
                        const updatedNotifications =
                          values.notifications.filter(
                            (notification) => notification.day !== dayId
                          );

                        setFieldValue('notifications', updatedNotifications);
                      } else if (scheduleForDay) {
                        setFieldValue(
                          'notifications',
                          values.notifications.map((notification) =>
                            notification.day === dayId
                              ? {
                                  ...notification,
                                  time: momentTo24HourTime(newTime),
                                }
                              : notification
                          )
                        );
                      } else {
                        
                        setFieldValue('notifications', [
                          ...values.notifications,
                          {
                            time: momentTo24HourTime(newTime),
                            day: dayId,
                          },
                        ]);
                      }
                    }}
                    clearable
                  />
                </div>
              );
            })}

            <div className={styles.Notifications_buttonGroup}>
              <Button
                kind={buttonKinds.SUBMIT}
                disabled={isUpdating}
                onClick={() => {}}
              >
                <span className={styles.Notifications_buttonGroup_buttonText}>
                  Update
                  {isUpdating && (
                    <Spinner
                      size={spinnerSizes.XS}
                      colorName={colorNames.WHITE}
                      className={styles.Notifications_buttonGroup_spinner}
                    />
                  )}
                </span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Notifications;
