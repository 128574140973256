import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';

import styles from '../styles.module.scss';
import { Button, ControlledInput, Modal } from '../../../elements';
import {
  buttonTypes,
  inputTypes,
  modalPositions,
  modalSizes,
} from '../../../../globals';

import { ConfirmationCodesService } from '../../../../services';
import { useInterval } from '../../../../hooks';

const RESEND_VERIFICATION_COOLDOWN = 30;

const validate = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = 'This field is required.';
  }

  return errors;
};

const InputConfirmationCodeModal = ({
  isOpen,
  handleClose,
  handleSuccess,
  confirmationCode,
}) => {
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);

  const resendVerification = () => {
    setResendCooldown(RESEND_VERIFICATION_COOLDOWN);

    ConfirmationCodesService.resend({
      confirmationCodeId: confirmationCode.id,
      forEmailAddress: confirmationCode.forEmailAddress,
    });
  };

  useInterval(() => {
    if (resendCooldown === 0) {
      return;
    }

    setResendCooldown(resendCooldown - 1);
  }, 1000);

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Confirm your Email"
      className={styles.ForgotPasswordModals}
      hasCloseButton={false}
      actions={[
        {
          text: 'Submit',
          type: buttonTypes.PRIMARY.VIOLET,
          disabled: isSubmitting === true,
          onClick: () => {
            formRef.current.handleSubmit();
          },
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ code: '' }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          setIsSubmitting(true);

          // Use the confirmation code
          const { data: useConfirmationCodeResponse } =
            await ConfirmationCodesService.use({
              confirmationCodeId: confirmationCode.id,
              code: values.code,
            });

          if (
            useConfirmationCodeResponse.message === 'confirmation_code_valid'
          ) {
            // if the request was successful, call the success handler
            handleSuccess();
          } else {
            // if the request wasn't successful, show an error message
            setErrors({
              code: 'The code you inputted is an invalid code.',
            });
          }

          setIsSubmitting(false);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ControlledInput
              className={styles.ForgotPasswordModals_withMarginBottom}
              type={inputTypes.SLIM}
              label="We've sent a confirmation code to your email. Input the code to proceed."
              placeholder="Confirmation Code*"
              name="code"
              id="code"
              value={values.code}
              error={errors.code}
              onChange={(e) =>
                setFieldValue('code', e.target.value.toUpperCase())
              }
            />
          </form>
        )}
      </Formik>
      <Button
        type={buttonTypes.TEXT.VIOLET}
        onClick={resendVerification}
        disabled={resendCooldown > 0}
      >
        {resendCooldown > 0
          ? `Resend Verification (${resendCooldown}s)`
          : 'Resend Verification'}
      </Button>
    </Modal>
  );
};

InputConfirmationCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirmationCode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    forEmailAddress: PropTypes.string.isRequired,
  }).isRequired,
  // doesn't accept any parameter. This will just
  // proceed to the next modal
  handleSuccess: PropTypes.func.isRequired,
};

export default InputConfirmationCodeModal;
