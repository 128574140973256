import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// import { KeyboardTimePicker } from "@material-ui/pickers";
import styles from '../Input/input.module.scss';
import { colorClasses, colorHexCodes, inputTypes, textTypes } from '../../../globals'
import './styles.scss';
import Icon from '../Icon';
import Text from '../Text';
import { format } from 'prettier';
import { TimePicker } from '@mui/x-date-pickers';


const theme = createTheme({
  palette: {
    primary: {
      main: colorHexCodes.VIOLET[400],
    },
    secondary: {
      main: '#fff',
    },
  },
});

const HoursPickerWrapper = ({ampm,mask, time,name, label,maxTime,minTime,placeholder,views,format,onChange,disabled, clearable, icon,type, error, withStyles, inputClassName, className }) => (
  <div className={cn('hoursPicker',className, styles.Input_container)}>
    <ThemeProvider theme={theme}>
      <TimePicker
        className={cn(
          inputClassName,
          {
            [styles[`Input___${type}`]]: withStyles,
          },
          {
          [styles[`Input___${type}___icon`]]: icon !== null,
          [styles.Input___error]: error !== null,
          [styles.Input___disabled]: disabled,
          }
        )}
        label={label}
        value={time}
        icon={icon}
        name={name}
        ampm={ampm}
        minTime={minTime}
        maxTime={maxTime}
        skipDisabled
        mask={mask}
        placeholder={placeholder}
        views={views}
        format={format}
        onChange={onChange}
        disabled={disabled}
        clearable={clearable}
      />
     
    {icon && <Icon className={styles[`Input___${type}_icon`]} icon={icon} />}
    {error && (
          <Text type={textTypes.BODY.XS} colorClass={colorClasses.RED['400']}>
            {error}
          </Text>
        )}
    </ThemeProvider>
    
  </div>
);

HoursPickerWrapper.defaultProps = {
  time: null,
  label: null,
  disabled: false,
  clearable: false,
  type: inputTypes.FORM,
  icon: null,
  error: null,
  inputClassName: null,
  className: null,
  withStyles: true,
  ampm: true,
 };

 HoursPickerWrapper.propTypes = {
  label: PropTypes.string,
  inputClassName: PropTypes.string,
  time: PropTypes.object,
  type: PropTypes.oneOf([inputTypes.FORM, inputTypes.SLIM]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  mask: PropTypes.string,
  withStyles: PropTypes.bool,
  ampm: PropTypes.bool
 };

export default HoursPickerWrapper;
