import React, { useState, useContext } from 'react';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import { SweetAlert } from '../../../../components';
import { Button, ControlledInput, Text } from '../../../../components/elements';
import { buttonTypes, textTypes } from '../../../../globals';
import { UserContext } from '../../../../contexts';
import { UsersService } from '../../../../services';

const EmployerDetails = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [showLeaveAlert, setShowLeaveAlert] = useState(false);

  return (
    <div className={styles.EmployerDetails}>
      <Text
        type={textTypes.HEADING.SM}
        className={styles.EmployerDetails_title}
      >
        Employer Details
      </Text>

      <form>
        <ControlledInput
          className={styles.EmployerDetails_input}
          placeholder="Employer Full Name"
          name="fullName"
          icon="person"
          value={user.employerDetails.employerFullName}
          disabled
        />

        <ControlledInput
          className={styles.EmployerDetails_input}
          placeholder="Company Name"
          name="companyName"
          icon="domain"
          value={user.employerDetails.companyName}
          disabled
        />

        <ControlledInput
          className={styles.EmployerDetails_input}
          placeholder="Phone Number"
          name="phoneNumber"
          icon="phone"
          value={user.employerDetails.phoneNumber}
          disabled
        />
      </form>

      <Button
        type={buttonTypes.PRIMARY.RED}
        className={styles.EmployerDetails_leaveCompanyButton}
        onClick={() => setShowLeaveAlert(true)}
      >
        Leave Company
      </Button>

      <SweetAlert
        show={showLeaveAlert}
        title="Are you sure?"
        onConfirm={async () => {
          const { data: leaveCompanyResponse } =
            await UsersService.leaveCompany({
              employeeId: user.id,
              employeeFullName: user.fullName,
              employerId: user.employerDetails.employerId,
              employerEmailAddress: user.employerDetails.employerEmailAddress,
            });

          if (leaveCompanyResponse.message === 'company_left') {
            alert.success('Company Left! Redirecting...');

            setTimeout(() => {
              loginUpdate({ ...user, employerDetails: null });
            }, 3000);
          } else {
            alert.error('Oops, something went wrong.');
          }

          setShowLeaveAlert(false);
        }}
        onCancel={() => setShowLeaveAlert(false)}
      >
        Your employer will be notified about this.
      </SweetAlert>
    </div>
  );
};

export default EmployerDetails;
