const settingsTabs = {
  ACCOUNT_INFORMATION: {
    name: 'Account Information',
    value: 'account-information',
  },
  TIMESHEETS: {
    name: 'Timesheets',
    value: 'timesheets',
  },
  TABLET_VIEW: {
    name: 'Tablet View',
    value: 'tablet-view',
  },
  VEHICLES: {
    name: 'Vehicles',
    value: 'vehicles',
  },
  NOTIFICATIONS: {
    name: 'Notifications',
    value: 'notifications',
  },
  SUBSCRIPTION: {
    name: 'Subscription',
    value: 'subscription',
  },
};

export default settingsTabs;
