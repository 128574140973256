import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import Geocode from 'react-geocode';

import { leaveTypes, tabletViewRecordingTypes } from '../../../globals';
import { tabletViewTimesheetSteps } from './constants';
import { UserContext } from '../../../contexts';
import { useCreateTimesheet, useUpdateTimesheet } from '../../../hooks';
import { convertTimestampToTimeWithSuffix } from '../../../utils/datetime';

import InputPasswordModal from './InputPasswordModal';
import SignInOrOutSuccessModal from './SignInOrOutSuccessModal';
import SignInOrOutFailureModal from './SignInOrOutFailureModal';
import InputTimesheetModal from './InputTimesheetModal';
import TimesheetSuccessModal from './TimesheetSuccessModal';
import useGetEmployee from '../../../hooks/useGetEmployee';

Geocode.setApiKey('AIzaSyCIDrHZ66UwJavnYMd-2BatN-6eQtTarjM');

const TabletViewTimesheetModals = ({
  isOpen,
  handleClose,
  employeeId,
  fullName,
  emailAddress,
  activeTimesheet,
  updateActiveTimesheet,
  recordingType,
}) => {
  const { user } = useContext(UserContext);
  // const [step, setStep] = useState(tabletViewTimesheetSteps.INPUT_PASSWORD);
  const [step, setStep] = useState("");
  const [location, setLocation] = useState(null);
  const [successType, setSuccessType] = useState('start');

  const { createTimesheet } = useCreateTimesheet();
  const { updateTimesheet } = useUpdateTimesheet();

  const {isLoading: isUserLoading, employee: getEmployeeDetail} = useGetEmployee({
    emailAddress: user.emailAddress,
  });

  useEffect(() => {
    if(getEmployeeDetail){
        if(!getEmployeeDetail.isPasswordRequired){
          if(recordingType === tabletViewRecordingTypes.AUTOMATICALLY_RECORD){
            updateAutoTime();
          }else{
            setStep(tabletViewTimesheetSteps.INPUT_TIMESHEET);
          }
        }else{
          setStep(tabletViewTimesheetSteps.INPUT_PASSWORD);
        }
      }
  },[getEmployeeDetail]);

  const updateAutoTime = async () => {
    if (!activeTimesheet) {
      const newTimesheet = {
        employerId: user.id,
        userId: employeeId,
        date: dateFormat(new Date(), 'yyyy-mm-dd'),
        leaveType: leaveTypes.NONE,
        startTimeDetails: {
          startTime: convertTimestampToTimeWithSuffix(
            Math.floor(Date.now() / 1000)
          ),
          actualTime: convertTimestampToTimeWithSuffix(
            Math.floor(Date.now() / 1000)
          ),
          location: location || undefined,
        },
      };

      const { responseCode: createTimesheetResponseCode } =
        await createTimesheet(newTimesheet);

      const createTimesheetCallbacks = {
        created: () => {
          updateActiveTimesheet();
          setSuccessType('start');
        },
        invalidFields: () =>
          setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_FAILURE),
        internalError: () =>
          setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_FAILURE),
      };

      switch (createTimesheetResponseCode) {
        case 200:
          createTimesheetCallbacks.created();
          break;
        case 400:
          createTimesheetCallbacks.invalidFields();
          break;
        case 500:
          createTimesheetCallbacks.internalError();
          break;
        default:
          break;
      }
    } else {
      // We don't need to create a new timesheet object,
      // but rather, only add the needed properties to
      // our activeTimesheet
      activeTimesheet.endTimeDetails = {
        endTime: convertTimestampToTimeWithSuffix(
          Math.floor(Date.now() / 1000)
        ),
        actualTime: convertTimestampToTimeWithSuffix(
          Math.floor(Date.now() / 1000)
        ),
        location: location || undefined,
      };
      activeTimesheet.isLunchBreak = user.isLunchBreakByDefault;
      activeTimesheet.notes = '';

      // Update the timesheet
      const { message } = await updateTimesheet(activeTimesheet);

      if (message === 'timesheet_updated') {
        updateActiveTimesheet();
        setSuccessType('end');
      } else {
        setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_FAILURE);
      }
    }

    setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_SUCCESS);
  }

  // Get employee location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            const address = response.results[0].formatted_address;
            setLocation({
              latlong: `${position.coords.latitude}, ${position.coords.longitude}`,
              address,
            });
          },
          (error) => {
            console.error(error);
          }
        );
      });
    }
  }, []);

  return (
    <>
      {step === tabletViewTimesheetSteps.INPUT_PASSWORD && (
        <InputPasswordModal
          title={`Hi ${fullName}, please enter your password here`}
          isOpen={isOpen}
          handleClose={handleClose}
          emailAddress={emailAddress}
          handleSuccess={async () => {
            if (
              recordingType === tabletViewRecordingTypes.AUTOMATICALLY_RECORD
            ) {
              if (!activeTimesheet) {
                const newTimesheet = {
                  employerId: user.id,
                  userId: employeeId,
                  date: dateFormat(new Date(), 'yyyy-mm-dd'),
                  leaveType: leaveTypes.NONE,
                  startTimeDetails: {
                    startTime: convertTimestampToTimeWithSuffix(
                      Math.floor(Date.now() / 1000)
                    ),
                    actualTime: convertTimestampToTimeWithSuffix(
                      Math.floor(Date.now() / 1000)
                    ),
                    location: location || undefined,
                  },
                };

                const { responseCode: createTimesheetResponseCode } =
                  await createTimesheet(newTimesheet);

                const createTimesheetCallbacks = {
                  created: () => {
                    updateActiveTimesheet();
                    setSuccessType('start');
                  },
                  invalidFields: () =>
                    setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_FAILURE),
                  internalError: () =>
                    setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_FAILURE),
                };

                switch (createTimesheetResponseCode) {
                  case 200:
                    createTimesheetCallbacks.created();
                    break;
                  case 400:
                    createTimesheetCallbacks.invalidFields();
                    break;
                  case 500:
                    createTimesheetCallbacks.internalError();
                    break;
                  default:
                    break;
                }
              } else {
                // We don't need to create a new timesheet object,
                // but rather, only add the needed properties to
                // our activeTimesheet
                activeTimesheet.endTimeDetails = {
                  endTime: convertTimestampToTimeWithSuffix(
                    Math.floor(Date.now() / 1000)
                  ),
                  actualTime: convertTimestampToTimeWithSuffix(
                    Math.floor(Date.now() / 1000)
                  ),
                  location: location || undefined,
                };
                activeTimesheet.isLunchBreak = user.isLunchBreakByDefault;
                activeTimesheet.notes = '';

                // Update the timesheet
                const { message } = await updateTimesheet(activeTimesheet);

                if (message === 'timesheet_updated') {
                  updateActiveTimesheet();
                  setSuccessType('end');
                } else {
                  setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_FAILURE);
                }
              }

              setStep(tabletViewTimesheetSteps.SIGN_IN_OR_OUT_SUCCESS);
            } else if (
              recordingType === tabletViewRecordingTypes.MANUALLY_RECORD
            ) {
              setStep(tabletViewTimesheetSteps.INPUT_TIMESHEET);
            }
          }}
        />
      )}

      {step === tabletViewTimesheetSteps.SIGN_IN_OR_OUT_SUCCESS && (
        <SignInOrOutSuccessModal
          isOpen={isOpen}
          handleClose={handleClose}
          type={successType}
        />
      )}

      {step === tabletViewTimesheetSteps.SIGN_IN_OR_OUT_FAILURE && (
        <SignInOrOutFailureModal isOpen={isOpen} handleClose={handleClose} />
      )}

      {step === tabletViewTimesheetSteps.INPUT_TIMESHEET && (
        <InputTimesheetModal
          isOpen={isOpen}
          handleClose={handleClose}
          employeeId={employeeId}
          activeTimesheet={activeTimesheet}
          location={location}
          handleSuccess={({ successType: retrievedSuccessType }) => {
            updateActiveTimesheet();
            setSuccessType(retrievedSuccessType);
            setStep(tabletViewTimesheetSteps.TIMESHEET_SUCCESS);
          }}
        />
      )}

      {step === tabletViewTimesheetSteps.TIMESHEET_SUCCESS && (
        <TimesheetSuccessModal
          isOpen={isOpen}
          handleClose={handleClose}
          type={successType}
        />
      )}
    </>
  );
};

TabletViewTimesheetModals.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // The employeeId of the employee who will create a timesheet
  employeeId: PropTypes.string.isRequired,
  // The fullName of the employee who will create a timesheet
  fullName: PropTypes.string.isRequired,
  // The emailAddress of the employee who will create a timesheet
  emailAddress: PropTypes.string.isRequired,
  // The active timesheet of the employee
  activeTimesheet: PropTypes.object.isRequired,
  // Will be used to set the status of the active timesheet
  // retrieved of the employee
  updateActiveTimesheet: PropTypes.func.isRequired,
  // The type of recording set by the employer. Could be either
  // 'Automatically Record' or 'Manually Record'
  recordingType: PropTypes.string.isRequired,
};

export default TabletViewTimesheetModals;
