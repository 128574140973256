import { useState } from 'react';
import { useAlert } from 'react-alert';

import { ProjectsService } from '../services';

const useCreateProject = () => {
  const alert = useAlert();
  const [isCreating, setIsCreating] = useState(false);

  const createProject = async (project) => {
    setIsCreating(true);

    const { data: createProjectResponse } = await ProjectsService.create(
      project
    );

    setIsCreating(false);

    let message = null;

    if (createProjectResponse.message === 'project_created') {
      alert.success('Project created!');
      message = 'project_created';
    } else {
      alert.error('Oops, something went wrong.');
      message = 'project_not_created';
    }

    return { message };
  };

  return { isCreating, createProject };
};

export default useCreateProject;
