import { useState } from 'react';
import { useAlert } from 'react-alert';
import FileDownload from 'js-file-download';
import dateFormat from 'dateformat';

import { TimesheetsService } from '../services';

const useGenerateContractorTimesheetReport = () => {
  const alert = useAlert();
  const [isGenerating, setIsGenerating] = useState(false);

  // Only usable if timesheet is under a project
  const filterType = (projectType, timesheet) => {
    if (projectType === 'Complete') {
      return true;
    }

    if (projectType === 'Billable') {
      if (timesheet.isBillable) {
        return true;
      }

      return false;
    }

    if (projectType === 'Non-billable') {
      if (timesheet.isBillable) {
        return false;
      }

      return true;
    }

    return false;
  };

  const generateContractorReport = async ({
    viewType,
    startDate,
    endDate,
    emailAddress,
    timesheets,
    contractorFullName,
    contractorEmailAddress,
    reportType,
    isProject,
    projectType,
    hoursAndMinutesFormat,
  }) => {
    setIsGenerating(true);

    const fileName = `${isProject ? 'Project ' : ''}Timesheets ${
      isProject ? `${projectType} ` : ''
    }${viewType} Report___${dateFormat(
      new Date(startDate),
      'mmm d'
    )} - ${dateFormat(new Date(endDate), 'mmm d, yyyy')}`;

    // Filtered timesheets
    const filteredTimesheets = [];

    if (isProject) {
      // Filtering project timesheets for Complete/Billable/Non-billable
      const filteredTimesheet = timesheets.filter((ftimesheet) =>
        filterType(projectType, ftimesheet)
      );

      filteredTimesheets.push(...filteredTimesheet);
    }

    const { data: generateContractorReportResponse } =
      await TimesheetsService.generateContractorReport({
        contractorFullName:
          reportType === 'Send' || reportType === 'Send PDF' ? contractorFullName : undefined,
        contractorEmailAddress:
          reportType === 'Send' || reportType === 'Send PDF' ? contractorEmailAddress : undefined,
        emailAddress: reportType === 'Send' || reportType === 'Send PDF' ? emailAddress : undefined,
        fileName,
        timesheets: !isProject ? timesheets : filteredTimesheets,
        type: reportType,
        hoursAndMinutesFormat,
      });
    // Download the file if reportType is 'Download'
    if (reportType === 'Download') {
      FileDownload(generateContractorReportResponse, `${fileName}.xlsx`);
      alert.success('Timesheet report downloaded!');
    }

    // Send an alert if reportType is 'Send'
    if (reportType === 'Send PDF') {
      if (generateContractorReportResponse.message === 'report_sent') {
        alert.success('Timesheet report sent!');
      } else {
        alert.error('Oops, something went wrong.');
      }
    }
    if (reportType === 'Send') {
      if (generateContractorReportResponse.message === 'report_sent') {
        alert.success('Timesheet report sent!');
      } else {
        alert.error('Oops, something went wrong.');
      }
    }

    setIsGenerating(false);

    return { message: 'generate_report_successful' };
  };

  return { isGenerating, generateContractorReport };
};

export default useGenerateContractorTimesheetReport;
